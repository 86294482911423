export const actionTypes = {
  addTag: 'add_tag',
  removeTag: 'remove_tag',
  enrollInCourse: 'enroll_in_course',
  revokeAccessToCourse: 'revoke_access_to_course',
  subscribeToCampaign: 'subscribe_to_campaign',
  unsubscribeFromCampaign: 'unsubscribe_from_campaign',
  sendWebhook: 'send_webhook',
  sendEmail: 'send_email',
  sendEmailToSpecificAddress: 'send_email_to_specific_address',
  revokeAccessToCommunity: 'revoke_access_to_community',
  enrollInCourseBundle: 'enroll_in_course_bundle',
  revokeAccessToCourseBundle: 'revoke_access_to_course_bundle',
  addRowToGoogleSheet: 'send_to_google_sheets',
}

export const actionTypesObjects = {
  [actionTypes.addTag]: 'tag',
  [actionTypes.removeTag]: 'tag',
  [actionTypes.enrollInCourse]: 'course',
  [actionTypes.revokeAccessToCourse]: 'course',
  [actionTypes.subscribeToCampaign]: 'campaign',
  [actionTypes.unsubscribeFromCampaign]: 'campaign',
  [actionTypes.sendWebhook]: 'webhook',
  [actionTypes.sendEmail]: 'systemeEmail',
  [actionTypes.sendEmailToSpecificAddress]: 'emailMessageWithRecipient',
  // local - what we get and work with, api - creating
  [actionTypes.revokeAccessToCommunity]: {
    api: 'communityId',
    local: 'community',
  },
  [actionTypes.enrollInCourseBundle]: {
    api: 'course_bundle',
    local: 'courseBundle',
  },
  [actionTypes.revokeAccessToCourseBundle]: {
    api: 'course_bundle',
    local: 'courseBundle',
  },
  [actionTypes.addRowToGoogleSheet]: {
    api: 'google_sheet_mapping_id',
    local: 'mapping',
  },
}

export const actionTypesLabels = {
  [actionTypes.addTag]: 'workflow.steps.types.action.types.labels.apply_tag',
  [actionTypes.removeTag]:
    'workflow.steps.types.action.types.labels.remove_tag',
  [actionTypes.enrollInCourse]:
    'workflow.steps.types.action.types.labels.enroll_in_course',
  [actionTypes.revokeAccessToCourse]:
    'workflow.steps.types.action.types.labels.revoke_access_to_course',
  [actionTypes.subscribeToCampaign]:
    'workflow.steps.types.action.types.labels.subscribe_to_campaign',
  [actionTypes.unsubscribeFromCampaign]:
    'workflow.steps.types.action.types.labels.unsubscribe_from_campaign',
  [actionTypes.sendWebhook]:
    'workflow.steps.types.action.types.labels.send_webhook',
  [actionTypes.sendEmail]:
    'workflow.steps.types.action.types.labels.send_email',
  [actionTypes.sendEmailToSpecificAddress]:
    'workflow.steps.types.action.types.labels.send_email_to_specific_address',
  [actionTypes.revokeAccessToCommunity]:
    'workflow.steps.types.action.types.labels.revoke_access_to_community',
  [actionTypes.enrollInCourseBundle]:
    'workflow.steps.types.action.types.labels.enroll_in_course_bundle',
  [actionTypes.revokeAccessToCourseBundle]:
    'workflow.steps.types.action.types.labels.revoke_access_to_course_bundle',
  [actionTypes.addRowToGoogleSheet]:
    'workflow.steps.types.action.types.names.add_row_to_google_sheet',
}

export const actionTypesNames = {
  [actionTypes.addTag]: 'workflow.steps.types.action.types.names.apply_tag',
  [actionTypes.removeTag]: 'workflow.steps.types.action.types.names.remove_tag',
  [actionTypes.enrollInCourse]:
    'workflow.steps.types.action.types.names.enroll_in_course',
  [actionTypes.revokeAccessToCourse]:
    'workflow.steps.types.action.types.names.revoke_access_to_course',
  [actionTypes.subscribeToCampaign]:
    'workflow.steps.types.action.types.names.subscribe_to_campaign',
  [actionTypes.unsubscribeFromCampaign]:
    'workflow.steps.types.action.types.names.unsubscribe_from_campaign',
  [actionTypes.sendWebhook]:
    'workflow.steps.types.action.types.names.send_webhook',
  [actionTypes.sendEmail]: 'workflow.steps.types.action.types.names.send_email',
  [actionTypes.sendEmailToSpecificAddress]:
    'workflow.steps.types.action.types.names.send_email_to_specific_address',
  [actionTypes.revokeAccessToCommunity]:
    'workflow.steps.types.action.types.names.revoke_access_to_community',
  [actionTypes.enrollInCourseBundle]:
    'workflow.steps.types.action.types.names.enroll_in_course_bundle',
  [actionTypes.revokeAccessToCourseBundle]:
    'workflow.steps.types.action.types.names.revoke_access_to_course_bundle',
  [actionTypes.addRowToGoogleSheet]:
    'workflow.steps.types.action.types.names.add_row_to_google_sheet',
}

export const actionTypesDescriptions = {
  [actionTypes.addTag]: 'workflow.steps.types.action.description.apply_tag',
  [actionTypes.removeTag]: 'workflow.steps.types.action.description.remove_tag',
  [actionTypes.subscribeToCampaign]:
    'workflow.steps.types.action.description.subscribe_to_campaign',
  [actionTypes.unsubscribeFromCampaign]:
    'workflow.steps.types.action.description.unsubscribe_to_campaign',
  [actionTypes.sendWebhook]:
    'workflow.steps.types.action.description.send_webhook',
  [actionTypes.enrollInCourse]:
    'workflow.steps.types.action.description.enroll_in_course',
  [actionTypes.revokeAccessToCourse]:
    'workflow.steps.types.action.description.revoke_access_to_course',
  [actionTypes.sendEmail]:
    'workflow.steps.types.action.types.description.send_email',
  [actionTypes.sendEmailToSpecificAddress]:
    'workflow.steps.types.action.types.description.send_email_to_specific_address',
  [actionTypes.revokeAccessToCommunity]:
    'workflow.steps.types.action.description.revoke_access_to_community',
  [actionTypes.enrollInCourseBundle]:
    'workflow.steps.types.action.description.enroll_in_course_bundle',
  [actionTypes.revokeAccessToCourseBundle]:
    'workflow.steps.types.action.description.revoke_access_to_course_bundle',
  [actionTypes.addRowToGoogleSheet]:
    'workflow.steps.types.action.description.add_row_to_google_sheet',
}

export const actionTypeToDataDict = {
  [actionTypes.addTag]: 'tag',
  [actionTypes.removeTag]: 'tag',
  [actionTypes.enrollInCourse]: 'course',
  [actionTypes.revokeAccessToCourse]: 'course',
  [actionTypes.subscribeToCampaign]: 'campaign',
  [actionTypes.unsubscribeFromCampaign]: 'campaign',
  [actionTypes.sendWebhook]: 'webhook',
  [actionTypes.sendEmail]: 'systemeEmail',
  [actionTypes.sendEmailToSpecificAddress]: 'emailMessageWithRecipient',
  [actionTypes.revokeAccessToCommunity]: 'community',
  [actionTypes.enrollInCourseBundle]: 'courseBundle',
  [actionTypes.revokeAccessToCourseBundle]: 'courseBundle',
  [actionTypes.addRowToGoogleSheet]: 'mapping',
}

export const getStepReplacements = stepData => {
  const propertyName = actionTypeToDataDict[stepData.type]

  switch (stepData.type) {
    case actionTypes.sendWebhook:
      return {
        name: stepData[propertyName] ? stepData[propertyName].url : '',
      }
    case actionTypes.sendEmail:
      return {
        name: stepData[propertyName] ? stepData[propertyName].subject : '',
      }
    case actionTypes.sendEmailToSpecificAddress:
      return {
        name: stepData[propertyName] ? stepData[propertyName].subject : '',
        address: stepData[propertyName]
          ? stepData[propertyName].recipientAddress
          : '',
      }
    default:
      return { name: stepData[propertyName] ? stepData[propertyName].name : '' }
  }
}

export const actionTypesRestrictions = {
  // Used for temp changes mainly to restrict access to the new actions
  // for regular users in testing process
}

export function filterActionTypeByAccess(roles) {
  return actionType => {
    if (!roles || roles.length === 0) return true

    const roleRestrictions = actionTypesRestrictions[actionType]

    if (!roleRestrictions) return true

    return roleRestrictions.some(restriction => roles.includes(restriction))
  }
}
