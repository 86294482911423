import styled from 'styled-components'

export const DropdownUi = styled.ul`
  max-height: 10rem;
  overflow-y: auto;
  list-style-type: none;
  padding: 2px 0;
  border-radius: 4px;
  background-color: var(--field-bg-color);
  border: solid 1px var(--select-border-color);
  color: var(--field-color);
  font-size: var(--field-font-size);
  z-index: 1000;
  pointer-events: auto;
  min-width: 350px;
  width: 90%;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
`
