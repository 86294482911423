import React from 'react'
import { Modal } from 'modal/components'
import { useTranslation } from 'react-i18next'
import useFormSubmit from 'workflow/hooks/useFormSubmit'
import TriggerForm from './TriggerForm'
import { connect } from 'react-redux'
import { updateTrigger } from 'workflow/actions'

const TriggerEdit = ({ handleClose, trigger, updateTrigger, ...restProps }) => {
  const [t] = useTranslation()

  const handleUpdateStep = data => {
    updateTrigger({ ...trigger, data })
  }

  const [isLoading, errors, handleSubmit] = useFormSubmit(
    handleUpdateStep,
    handleClose,
  )

  return (
    <Modal
      onRequestClose={handleClose}
      header={t('workflow.steps.trigger.modal.edit.header.title')}
      {...restProps}
    >
      <TriggerForm
        stepData={trigger}
        handleSubmit={handleSubmit}
        submitText={t('workflow.steps.trigger.modal.edit.submit.label')}
        isLoading={isLoading}
        errors={errors}
      />
    </Modal>
  )
}

export default connect(null, { updateTrigger })(TriggerEdit)
