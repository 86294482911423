import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'
import useSelectOptionsData from 'workflow/hooks/useSelectOptionsData'
import { useEffect } from 'react'
import { conditionTypes } from './conditionTypes'

export default function useConditionNameData(conditionGroups) {
  const { options: tags, allowFetch: fetchTags } = useSelectOptionsData({
    dataType: workflowDataTypes.tags,
    selector: tag => tag,
    fetchImmediately: true,
  })

  const {
    options: contactFields,
    allowFetch: fetchContactFields,
  } = useSelectOptionsData({
    dataType: workflowDataTypes.contactFields,
    selector: field => field,
  })

  const { options: mailings, allowFetch: fetchMailings } = useSelectOptionsData(
    {
      dataType: workflowDataTypes.mailings,
      selector: mailing => mailing,
    },
  )

  useEffect(() => {
    if (!conditionGroups) return

    if (
      conditionGroups.some(group =>
        group.contactFilterConditionModels.some(
          model => model.conditionType === conditionTypes.tag,
        ),
      )
    ) {
      fetchTags()
    }

    if (
      conditionGroups.some(group =>
        group.contactFilterConditionModels.some(
          model => model.conditionType === conditionTypes.contactFields,
        ),
      )
    ) {
      fetchContactFields()
    }

    if (
      conditionGroups.some(group =>
        group.contactFilterConditionModels.some(
          model =>
            model.conditionType === conditionTypes.mailing ||
            model.conditionType === conditionTypes.emailOpened,
        ),
      )
    ) {
      fetchMailings()
    }
  }, [conditionGroups?.length])

  return { tags, contactFields, mailings }
}
