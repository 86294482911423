import React from 'react'
import HeaderSkeletonUi from '../ui/HeaderSkeletonUi'
import HeaderElementSkeleton from './HeaderElementSkeleton'

const COUNT_ELEMENTS = 2

const HeaderSkeleton = () => (
  <HeaderSkeletonUi>
    {Array.from({ length: COUNT_ELEMENTS }).map((_, i) => (
      <HeaderElementSkeleton key={i} width={`${(COUNT_ELEMENTS - i) * 4}rem`} />
    ))}
  </HeaderSkeletonUi>
)

export default HeaderSkeleton
