import React from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { delayUnitesLabels } from './delayTypes'

const options = Object.keys(delayUnitesLabels).map(unitKey => ({
  value: unitKey,
  label: delayUnitesLabels[unitKey],
}))

const DelayUnits = ({ onChange, selected }) => {
  const { t } = useTranslation()
  return (
    <Select
      options={options}
      onChange={onChange}
      formatOptionLabel={({ label }) => t(label)}
      value={options.find(option => option.value === selected)}
    />
  )
}

export default DelayUnits
