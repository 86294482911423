import React from 'react'
import Group from 'shared/components/Group'
import Communities from 'workflow/components/Communities'

const TriggerTypeCommunity = ({ change, data }) => {
  const handleChange = ({ value, label }) => {
    change({ ...data, id: value, name: label })
  }
  return (
    <Group>
      <Communities onChange={handleChange} selected={data} />
    </Group>
  )
}

export default TriggerTypeCommunity
