import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import useSelectOptionsData from 'workflow/hooks/useSelectOptionsData'
import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'
import React, { useEffect } from 'react'
import styled from 'styled-components'

const DrivesUi = styled.div`
  text-align: end;
  margin-top: 10px;
`

export default function Drives({ onChange, selected, disabled }) {
  const { t } = useTranslation()

  const { options, isLoading, allowFetch } = useSelectOptionsData({
    dataType: workflowDataTypes.drives,
    defaultOptions: selected?.name && [selected],
    filter: drive => !drive.disconnectedAt,
    selector: drive => ({
      value: drive.id,
      label: drive.name ?? `${drive.googleUserName} - ${drive.googleUserEmail}`,
    }),
    fetchImmediately: true,
  })

  useEffect(() => {
    if (selected && !selected.name && (!options || options.length === 0))
      allowFetch()
  }, [selected, options])

  const noOptions = !isLoading && options?.length == 0

  return (
    <>
      <Select
        id="workflow-drives-select"
        onChange={onChange}
        options={options}
        maxMenuHeight={200}
        value={options.find(option => option.value === selected?.id)}
        noOptionsMessage={() => t('workflow.selectors.no_options')}
        placeholder={t('workflow.drives.placeholder')}
        onFocus={allowFetch}
        isLoading={isLoading}
        isDisabled={disabled}
      />
      {noOptions && (
        <DrivesUi
          dangerouslySetInnerHTML={{
            __html: t('workflow.drives.no_options', {
              link: `${process.env.REACT_APP_API_HOST}/dashboard/profile/google-integration`,
            }),
          }}
        />
      )}
    </>
  )
}
