import React from 'react'
import LogoUi from './ui/LogoUi'
import logo from './logo.png'

const Logo = () => {
  return (
    <LogoUi
      src={logo}
      alt="systeme.io visual editor"
      height="30"
      data-test-element="editor-logo"
    />
  )
}

export default Logo
