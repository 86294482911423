import { ModalContext } from 'modal/context'
import React, { useContext } from 'react'
import { Modal } from 'modal/components'
import { useTranslation } from 'react-i18next'
import * as stepFactory from 'workflow/components/Step/factory'
import { stepChildTypes } from 'workflow/components/Step/stepChildTypes'
import {
  stepTypes,
  stepTypesDescriptions,
  stepTypesNames,
} from 'workflow/components/Step/stepTypes'
import StepTypeItem from './StepTypeItem'
import StepCreateUi from './ui/StepCreateUi'

const StepCreate = ({ parentStepId, handleClose, childType, addStep }) => {
  const [t] = useTranslation()
  const { showModal } = useContext(ModalContext)

  const handleSelectStepType = (type, parentStepId, childType, addStep) => {
    setTimeout(() => {
      showModal(stepFactory.createStepModal(type), {
        parentStepId,
        childType,
        addStep,
      })
    }, 200)
  }

  const selectStepType = type => () => {
    handleSelectStepType(type, parentStepId, childType, addStep)
    handleClose()
  }
  return (
    <Modal
      onRequestClose={handleClose}
      header={t('workflow.steps.modal.choose.header.title')}
      data-testid="modal-step-create"
    >
      <StepCreateUi>
        {Object.values(stepTypes)
          .filter(type => type !== stepTypes.trigger)
          .map(stepType => (
            <StepTypeItem
              handleClick={selectStepType(stepType)}
              key={stepType}
              name={t(stepTypesNames[stepType])}
              description={t(stepTypesDescriptions[stepType])}
            />
          ))}
      </StepCreateUi>
    </Modal>
  )
}

StepCreate.defaultProps = {
  parentStepId: null,
  childType: stepChildTypes.child,
}

export default StepCreate
