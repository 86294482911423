import React, { useEffect } from 'react'
import Group from 'shared/components/Group'
import Drives from 'workflow/components/Drives'
import GDriveFileSelect from 'workflow/components/GDriveFileSelect'
import WorksheetColumns from 'workflow/components/WorksheetColumns'
import Worksheets from 'workflow/components/Worksheets'
import useMapping from 'workflow/hooks/useMapping'

function modifyMapping(mappings, columnId, contents) {
  if (!mappings || mappings.length === 0) {
    return [{ column: columnId, value: contents }]
  }

  const exists = mappings.some(mapping => mapping.column === columnId)

  if (!exists && contents.length > 0)
    return [...mappings, { column: columnId, value: contents }]

  if (contents.length === 0) {
    return mappings.filter(mapping => mapping.column !== columnId)
  }

  return mappings.map(mapping =>
    mapping.column === columnId ? { ...mapping, value: contents } : mapping,
  )
}

export default function ActionTypeAddRowToGoogleSheet({ change, data }) {
  const { mapping } = useMapping({
    mappingId: data?.id,
  })

  const handleChange = property => ({ value, label }) =>
    change(prev => ({
      ...prev,
      [property + 'Id']: value,
      [property + 'Name']: label,
    }))

  const handleMappingChange = (columnId, contents) => {
    change(prev => {
      const modified = modifyMapping(prev?.mapping, columnId, contents)

      return {
        ...prev,
        mapping: modified,
      }
    })
  }

  useEffect(() => {
    if (mapping) {
      change(prev => ({
        ...prev,
        ...mapping,
      }))
    }
  }, [mapping])

  const drive = data?.integrationId && {
    id: data.integrationId,
    name: data.integrationName,
  }
  const spreadsheet = data?.spreadsheetId && {
    id: data.spreadsheetId,
    name: data.spreadsheetName,
  }
  const sheet = data?.sheetId && {
    id: data.sheetId,
    name: data.sheetName,
  }

  return (
    <>
      <Group>
        <Drives selected={drive} onChange={handleChange('integration')} />
      </Group>
      {drive?.id && (
        <Group>
          <GDriveFileSelect
            driveId={drive.id}
            value={spreadsheet}
            onChange={handleChange('spreadsheet')}
          />
        </Group>
      )}
      {drive?.id && spreadsheet?.id && (
        <>
          <Group>
            <Worksheets
              spreadsheetId={spreadsheet?.id}
              driveId={drive?.id}
              value={sheet}
              onChange={handleChange('sheet')}
              selected={sheet}
            />
          </Group>
          <WorksheetColumns
            driveId={drive?.id}
            spreadsheetId={spreadsheet?.id}
            sheetId={sheet?.id}
            mapping={data?.mapping ?? []}
            onChange={handleMappingChange}
          />
        </>
      )}
    </>
  )
}
