import React from 'react'
import { useTranslation } from 'react-i18next'
import Group from 'shared/components/Group'
import Input from 'shared/components/Input'

const ActionTypeSendWebhook = ({ change, data }) => {
  const { t } = useTranslation()
  const handleChange = e => {
    change({ ...data, webhookUrl: e.target.value })
  }

  return (
    <Group>
      <Input
        value={data.webhookUrl}
        placeholder={t('workflow.steps.types.action.webhook_url.placeholder')}
        onChange={handleChange}
      />
    </Group>
  )
}

ActionTypeSendWebhook.defaultProps = {
  data: {
    webhookUrl: '',
  },
}

export default ActionTypeSendWebhook
