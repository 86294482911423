import React, { useState } from 'react'
import Group from 'shared/components/Group'
import BlogPages from 'workflow/components/BlogPages'
import Blogs from 'workflow/components/Blogs'
import { useEffect } from 'react'

const TriggerTypeBlogFormSubscribed = ({ change, data }) => {
  const [blog, setBlog] = useState(data.blog)

  const handleChange = ({ value, label }) => {
    change({
      ...data,
      id: value,
      name: label,
      blog,
    })
  }

  useEffect(() => {
    data.blog && setBlog(data.blog)
  }, [data.blog?.id])

  return (
    <>
      <Group>
        <Blogs
          onChange={({ value, label }) => setBlog({ id: value, name: label })}
          selected={blog}
        />
      </Group>
      {blog?.id && (
        <Group>
          <BlogPages onChange={handleChange} selected={data} blogId={blog.id} />
        </Group>
      )}
    </>
  )
}

export default TriggerTypeBlogFormSubscribed
