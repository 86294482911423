export function appendScript(scriptSrc) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = scriptSrc
    const body = document.querySelector('body')
    body.appendChild(script)
    script.addEventListener('load', resolve)
    script.addEventListener('error', reject)
  })
}

export function appendScripts(scriptsSrc) {
  return scriptsSrc.map(appendScript)
}
