import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import NavBar from 'dashboard/components/NavBar'
import Logo from 'dashboard/components/Logo'
import HeaderUi from './ui/HeaderUi'
import WorkflowNameUi from './ui/WorkflowNameUi'
import WorkflowStateUi from './ui/WorkflowStateUi'
import HeaderSkeleton from './skeletons/HeaderSkeleton'
import Suspense from 'shared/components/Suspense/Suspense'
import ExitIcon from 'shared/icons/exit-icon'
import ExitButtonUi from './ui/ExitButtonUi'
import { getWorkflowState } from 'workflow/reducer'
import useWorkflow from 'workflow/hooks/useWorkflow'

const Header = () => {
  const { t } = useTranslation()
  const { name, active, isLoadingWorkflow } = useWorkflow(getWorkflowState)

  return (
    <HeaderUi>
      <Logo />
      <Suspense isLoading={isLoadingWorkflow} fallback={<HeaderSkeleton />}>
        {() => (
          <>
            <WorkflowNameUi>{name}</WorkflowNameUi>
            <WorkflowStateUi>
              {t(`workflow.header.status.${active ? 'active' : 'paused'}`)}
            </WorkflowStateUi>
          </>
        )}
      </Suspense>
      <NavBar>
        <ExitButtonUi
          onClick={() =>
            (window.location.href = `${process.env.REACT_APP_API_HOST}/dashboard/workflows`)
          }
        >
          <ExitIcon fill="#142d63" width="1rem" height="1rem" />
          {t('dashboard.header.exit')}
        </ExitButtonUi>
      </NavBar>
    </HeaderUi>
  )
}

export default memo(Header)
