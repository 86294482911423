import React, { useContext } from 'react'
import CircleGroupUi from './ui/CircleGroupUi'
import CircleUi from './ui/CircleUi'
import { TopLayerContext } from 'workflow/components/TopLayer'

const radius = 8

export const height = radius * 2

function Circle({ x, y, onClick }) {
  const { renderAtTopLayer } = useContext(TopLayerContext)

  return renderAtTopLayer(
    <CircleGroupUi
      transform={`translate(${x - radius}, ${y})`}
      onClick={onClick}
    >
      <CircleUi r="8" cx="8" cy="8" />
      <line stroke="#fff" strokeWidth="2px" x1="4" y1="8" x2="12" y2="8" />
      <line stroke="#fff" strokeWidth="2px" x1="8" y1="4" x2="8" y2="12" />
    </CircleGroupUi>,
  )
}

export default Circle
