import styled from 'styled-components'

export const NoOptionsUi = styled.div`
  padding: 8px 12px;
  font-size: 1rem;
  color: hsl(0, 0%, 60%);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`
