import React from 'react'
import Select from 'react-select'
import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'
import useSelectOptionsData from 'workflow/hooks/useSelectOptionsData'

const ContactFields = ({ onChange, selected }) => {
  const { options, isLoading, allowFetch } = useSelectOptionsData({
    dataType: workflowDataTypes.contactFields,
    defaultOptions: selected && [selected],
    selector: field => ({ value: field.id, label: field.fieldName }),
    fetchImmediately: true,
  })

  return (
    <Select
      onChange={onChange}
      options={options}
      maxMenuHeight={200}
      value={options.find(option => option.value === selected)}
      isLoading={isLoading}
      onFocus={allowFetch}
    />
  )
}

export default ContactFields
