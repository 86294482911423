import React, { memo } from 'react'
import MainUi from './ui/MainUi'
// import MainLoaderUi from './ui/MainLoaderUi'
import Workflow from 'workflow/Workflow'

// const Workflow = lazy(() => import('../Workflow'))

const Main = () => {
  return (
    <MainUi>
      <Workflow />
    </MainUi>
  )
}

export default memo(Main)
