import React from 'react'
import Campaigns from 'workflow/components/Campaigns'
import Group from 'shared/components/Group'

const ActionTypeSubscribeToCampaign = ({ change, data }) => {
  const handleChangeCampaign = ({ value, label }) => {
    change({ ...data, id: value, name: label })
  }

  return (
    <Group>
      <Campaigns onChange={handleChangeCampaign} selected={data} />
    </Group>
  )
}

export default ActionTypeSubscribeToCampaign
