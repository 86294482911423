import React from 'react'
import Select from 'react-select'
import { styles } from 'shared/components/ReactSelectEnhaced'
import { useTranslation } from 'react-i18next'
import useSelectOptionsData from 'workflow/hooks/useSelectOptionsData'
import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'

const WebinarRegistrationSteps = ({ onChange, selected, funnelId }) => {
  const { t } = useTranslation()

  const { options, isLoading, allowFetch } = useSelectOptionsData({
    dataType: workflowDataTypes.webinarRegistrationSteps,
    parentEntityId: funnelId,
    defaultOptions: selected && [selected],
  })

  return (
    <Select
      id="workflow-webinar-registrations-select"
      onChange={onChange}
      options={options}
      maxMenuHeight={200}
      value={options.find(option => option.value === selected?.id)}
      styles={styles}
      noOptionsMessage={() =>
        t('workflow.webinar_registration_steps.message.no_options')
      }
      placeholder={t('workflow.webinar_registration_steps.placeholder')}
      isLoading={isLoading}
      onFocus={allowFetch}
    />
  )
}

export default WebinarRegistrationSteps
