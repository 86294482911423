import React from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import countries from './countries'
import { useUser } from 'workflow/hooks/useUser'

const constructCountryObj = country => ({
  value: country.code,
  label: country.name,
})

export const getCountriesOptions = locale =>
  countries[locale] ? countries[locale].map(constructCountryObj) : []

export const getCountryValue = (locale, value) => {
  const country =
    countries[locale] &&
    countries[locale].find(country => country.code === value)
  return country ? constructCountryObj(country) : {}
}

const CountriesSelect = ({ selected, onChange }) => {
  const { t } = useTranslation()
  const { locale, isLoading } = useUser()

  return (
    <Select
      placeholder={t('workflow.steps.types.condition.countries.placeholder')}
      value={getCountryValue(locale, selected)}
      options={getCountriesOptions(locale)}
      onChange={onChange}
      isLoading={isLoading}
    />
  )
}

export default CountriesSelect
