import styled from 'styled-components/macro'

const Layout = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: 50px 1fr;
  grid-template-columns: 0 1fr;
  grid-template-areas:
    'header header'
    'sidebar main';
  background-color: #fff;
  //background-color: #05263f;
`

export default Layout
