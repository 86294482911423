import React from 'react'
import WorkflowElementSkeleton from './WorkflowElementSkeleton'
import styled from 'styled-components'
import { circle } from './skeleton.module.css'

const WorkflowSkeletonUi = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const WorkflowSkeleton = () => {
  return (
    <WorkflowSkeletonUi>
      <svg width="775" height="900" stroke="red" data-testid="workflow">
        <filter id="shadow" width="150%" height="150%">
          <feDropShadow
            dx="0"
            dy="2"
            stdDeviation="4"
            flood-color="rgba(0, 0, 0, .15)"
          ></feDropShadow>
        </filter>
        <g transform="translate(155, 50)">
          <WorkflowElementSkeleton />
          <path
            d="M102,40 C102,60 240,40 240,60"
            fill="transparent"
            stroke="#c0c0c0"
            stroke-dasharray="8 4"
          ></path>
          <WorkflowElementSkeleton width={256} x={224} />
          <path
            d="M352,40 C352,60 240,40 240,60"
            fill="transparent"
            stroke="#c0c0c0"
            stroke-dasharray="8 4"
          ></path>
        </g>
        <g>
          <g className="step-group" data-height="720">
            <g className="step-action" data-id="500253" data-height="96">
              <line
                x1="395"
                x2="395"
                y1="125"
                y2="145"
                stroke="#c0c0c0"
                stroke-dasharray="8 4"
              ></line>
              <WorkflowElementSkeleton
                width={212}
                x={291.5}
                y={145}
                color="#149efc"
              />
              <line
                x1="395"
                x2="395"
                y1="185"
                y2="205"
                stroke="#c0c0c0"
                stroke-dasharray="8 4"
              ></line>
            </g>
            <g className="step-condition" data-id="500255" data-height="432">
              <line
                x1="395"
                x2="395"
                y1="221"
                y2="241"
                stroke="#c0c0c0"
                stroke-dasharray="8 4"
              ></line>
              <WorkflowElementSkeleton
                x={210.5}
                y={241}
                width={374}
                color="#142d63"
              />
              <path
                d="M395,281 C395,371 169,281 169,371"
                fill="transparent"
                stroke="#c0c0c0"
                stroke-dasharray="8 4"
              ></path>
              <rect
                x="274.5"
                y="311"
                width="37"
                height="30"
                fill="#fff"
                stroke="#fff"
                rx="15"
                ry="15"
              ></rect>
              <text
                x="282"
                y="326"
                fill="#333"
                stroke="none"
                dominant-baseline="middle"
                font-size="14px"
              >
                Yes
              </text>
              <path
                d="M395,281 C395,371 621,281 621,371"
                fill="transparent"
                stroke="#c0c0c0"
                stroke-dasharray="8 4"
              ></path>
              <rect
                x="500.5"
                y="311"
                width="34"
                height="30"
                fill="#fff"
                stroke="#fff"
                rx="15"
                ry="15"
              ></rect>
              <text
                x="508"
                y="326"
                fill="#333"
                stroke="none"
                dominant-baseline="middle"
                font-size="14px"
              >
                No
              </text>
              <g className="step-group" data-height="96">
                <g className="delay-action" data-id="500259" data-height="96">
                  <line
                    x1="169"
                    x2="169"
                    y1="387"
                    y2="407"
                    stroke="#c0c0c0"
                    stroke-dasharray="8 4"
                  ></line>
                  <WorkflowElementSkeleton
                    x={103}
                    y={407}
                    width={132}
                    color="#fff"
                  />
                  <line
                    x1="169"
                    x2="169"
                    y1="447"
                    y2="467"
                    stroke="#c0c0c0"
                    stroke-dasharray="8 4"
                  ></line>
                </g>
              </g>
              <g className="step-group" data-height="192">
                <g className="step-action" data-id="500268" data-height="96">
                  <line
                    x1="621"
                    x2="621"
                    y1="387"
                    y2="407"
                    stroke="#c0c0c0"
                    stroke-dasharray="8 4"
                  ></line>
                  <WorkflowElementSkeleton
                    x={476}
                    y={407}
                    width={295}
                    color="#149efc"
                  />
                  <line
                    x1="621"
                    x2="621"
                    y1="447"
                    y2="467"
                    stroke="#c0c0c0"
                    stroke-dasharray="8 4"
                  ></line>
                </g>
                <g className="delay-action" data-id="500256" data-height="96">
                  <line
                    x1="621"
                    x2="621"
                    y1="483"
                    y2="503"
                    stroke="#c0c0c0"
                    stroke-dasharray="8 4"
                  ></line>
                  <WorkflowElementSkeleton
                    x={557}
                    y={503}
                    width={128}
                    color="#fff"
                  />
                  <line
                    x1="621"
                    x2="621"
                    y1="543"
                    y2="563"
                    stroke="#c0c0c0"
                    stroke-dasharray="8 4"
                  ></line>
                </g>
              </g>
              <path
                d="M169,467 C169,653 395,467 395,653"
                fill="transparent"
                stroke="#c0c0c0"
                stroke-dasharray="8 4"
              ></path>
              <path
                d="M621,563 C621,653 395,563 395,653"
                fill="transparent"
                stroke="#c0c0c0"
                stroke-dasharray="8 4"
              ></path>
            </g>
            <g className="step-action" data-id="500261" data-height="96">
              <line
                x1="395"
                x2="395"
                y1="653"
                y2="673"
                stroke="#c0c0c0"
                stroke-dasharray="8 4"
              ></line>
              <WorkflowElementSkeleton
                x={233.5}
                y={673}
                width={328}
                color="#149efc"
              />
              <line
                x1="395"
                x2="395"
                y1="713"
                y2="733"
                stroke="#c0c0c0"
                stroke-dasharray="8 4"
              ></line>
            </g>
            <g className="delay-action" data-id="500264" data-height="96">
              <line
                x1="395"
                x2="395"
                y1="749"
                y2="769"
                stroke="#c0c0c0"
                stroke-dasharray="8 4"
              ></line>
              <WorkflowElementSkeleton
                x={331}
                y={769}
                width={128}
                color="#fff"
              />
              <line
                x1="395"
                x2="395"
                y1="809"
                y2="829"
                stroke="#c0c0c0"
                stroke-dasharray="8 4"
              ></line>
            </g>
          </g>
        </g>
        <g id="top-layer-portal">
          <g
            transform="translate(387, 110)"
            className="CircleGroupUi-sc-18kbm7p-0 jKpvuj"
          >
            <circle r="8" cx="8" cy="8" className={circle}></circle>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="4"
              y1="8"
              x2="12"
              y2="8"
            ></line>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="8"
              y1="4"
              x2="8"
              y2="12"
            ></line>
          </g>
          <g
            transform="translate(387, 205)"
            className="CircleGroupUi-sc-18kbm7p-0 jKpvuj"
          >
            <circle r="8" cx="8" cy="8" className={circle}></circle>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="4"
              y1="8"
              x2="12"
              y2="8"
            ></line>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="8"
              y1="4"
              x2="8"
              y2="12"
            ></line>
          </g>
          <g
            transform="translate(161, 371)"
            className="CircleGroupUi-sc-18kbm7p-0 jKpvuj"
          >
            <circle r="8" cx="8" cy="8" className={circle}></circle>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="4"
              y1="8"
              x2="12"
              y2="8"
            ></line>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="8"
              y1="4"
              x2="8"
              y2="12"
            ></line>
          </g>
          <g
            transform="translate(613, 371)"
            className="CircleGroupUi-sc-18kbm7p-0 jKpvuj"
          >
            <circle r="8" cx="8" cy="8" className={circle}></circle>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="4"
              y1="8"
              x2="12"
              y2="8"
            ></line>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="8"
              y1="4"
              x2="8"
              y2="12"
            ></line>
          </g>
          <g
            transform="translate(161, 467)"
            className="CircleGroupUi-sc-18kbm7p-0 jKpvuj"
          >
            <circle r="8" cx="8" cy="8" className={circle}></circle>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="4"
              y1="8"
              x2="12"
              y2="8"
            ></line>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="8"
              y1="4"
              x2="8"
              y2="12"
            ></line>
          </g>
          <g
            transform="translate(613, 563)"
            className="CircleGroupUi-sc-18kbm7p-0 jKpvuj"
          >
            <circle r="8" cx="8" cy="8" className={circle}></circle>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="4"
              y1="8"
              x2="12"
              y2="8"
            ></line>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="8"
              y1="4"
              x2="8"
              y2="12"
            ></line>
          </g>
          <g
            transform="translate(387, 653)"
            className="CircleGroupUi-sc-18kbm7p-0 jKpvuj"
          >
            <circle r="8" cx="8" cy="8" className={circle}></circle>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="4"
              y1="8"
              x2="12"
              y2="8"
            ></line>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="8"
              y1="4"
              x2="8"
              y2="12"
            ></line>
          </g>
          <g
            transform="translate(387, 733)"
            className="CircleGroupUi-sc-18kbm7p-0 jKpvuj"
          >
            <circle r="8" cx="8" cy="8" className={circle}></circle>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="4"
              y1="8"
              x2="12"
              y2="8"
            ></line>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="8"
              y1="4"
              x2="8"
              y2="12"
            ></line>
          </g>
          <g
            transform="translate(387, 829)"
            className="CircleGroupUi-sc-18kbm7p-0 jKpvuj"
          >
            <circle r="8" cx="8" cy="8" className={circle}></circle>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="4"
              y1="8"
              x2="12"
              y2="8"
            ></line>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="8"
              y1="4"
              x2="8"
              y2="12"
            ></line>
          </g>
          <g
            transform="translate(647, 62)"
            className="CircleGroupUi-sc-18kbm7p-0 jKpvuj"
          >
            <circle r="8" cx="8" cy="8" className={circle}></circle>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="4"
              y1="8"
              x2="12"
              y2="8"
            ></line>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="8"
              y1="4"
              x2="8"
              y2="12"
            ></line>
          </g>
          <g
            transform="translate(613, 467)"
            className="CircleGroupUi-sc-18kbm7p-0 jKpvuj"
          >
            <circle r="8" cx="8" cy="8" className={circle}></circle>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="4"
              y1="8"
              x2="12"
              y2="8"
            ></line>
            <line
              stroke="#fff"
              stroke-width="2px"
              x1="8"
              y1="4"
              x2="8"
              y2="12"
            ></line>
          </g>
        </g>
      </svg>
    </WorkflowSkeletonUi>
  )
}

export default WorkflowSkeleton
