export const contactStates = {
  active: 'state_active',
  unsubscribed: 'state_unsubscribed',
  bounced: 'state_bounced',
}

export const contactStatesLabels = {
  [contactStates.all]: 'workflow.contact_states.labels.all',
  [contactStates.active]: 'workflow.contact_states.labels.active',
  [contactStates.unsubscribed]: 'workflow.contact_states.labels.unsubscribed',
  [contactStates.bounced]: 'workflow.contact_states.labels.bounced',
}

export const contactStatesNames = {
  [contactStates.all]: '',
  [contactStates.active]: 'workflow.contact_states.names.active',
  [contactStates.unsubscribed]: 'workflow.contact_states.names.unsubscribed',
  [contactStates.bounced]: 'workflow.contact_states.names.bounced',
}
