import React from 'react'
import StepTypeItemUi from './ui/StepTypeItemUi'
import StepItemNameUi from './ui/StepItemNameUi'
import StepItemDescriptionUi from './ui/StepItemDescriptionUi'

const StepTypeItem = ({ name, description, handleClick }) => {
  return (
    <StepTypeItemUi onClick={handleClick}>
      <div>
        <i />
      </div>
      <StepItemNameUi>{name}</StepItemNameUi>
      <StepItemDescriptionUi>{description}</StepItemDescriptionUi>
    </StepTypeItemUi>
  )
}

export default StepTypeItem
