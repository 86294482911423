import React from 'react'
import { useTranslation } from 'react-i18next'
import HorizontalGroup from 'shared/components/HorizontalGroup'
import FormRow from 'shared/components/FormRow'
import { compareOperations } from '../compareOperations'
import { conditionFields } from '../conditionFields'
import Mailings from '../../../../Mailings'

const ConditionTypeMailings = ({ change, model }) => {
  const { t } = useTranslation()

  const changeSearchValue = option => {
    change({
      ...model,
      [conditionFields.searchValue]: option.value,
    })
  }

  return (
    <HorizontalGroup
      styles={{
        gridTemplateColumns: '45% 45% ',
      }}
    >
      <FormRow data-testid="workflow-step-condition-operators">
        {t('workflow.steps.condition.email_is_clicked.label')}
      </FormRow>
      {model.compareOperation !== compareOperations.without && (
        <div data-testid="workflow-step-condition-operators">
          <Mailings
            onChange={changeSearchValue}
            selected={Number(model.searchValue)}
          />
        </div>
      )}
    </HorizontalGroup>
  )
}

export default ConditionTypeMailings
