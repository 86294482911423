import { keyframes, css } from 'styled-components'

export const pulse = keyframes`
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
`

export const animatePulse = css`
  animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
`
