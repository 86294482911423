import React, { memo, useState } from 'react'
import Step from 'workflow/components/Step'
import {
  addStepHeightGenerator,
  cleanUpStepHeightGenerator,
} from 'workflow/utils/stepHeightStateUtils'
import useHeight from 'workflow/hooks/useHeight'
import useWorkflow from 'workflow/hooks/useWorkflow'
import { getStepsByIds } from 'workflow/reducer'

const getIndexY = (height, index) =>
  height.slice(0, index).reduce((a, b) => a + b, 0)

const Steps = ({
  ids,
  level,
  centerX,
  startY,
  testProp,
  createAddStep,
  removeStep,
  heightSubscriber: parentHeightSubscriber,
  ...restProps
}) => {
  const [stepsHeights, setStepsHeights] = useState([])
  const steps = useWorkflow(getStepsByIds(ids))

  const [ref] = useHeight(parentHeightSubscriber, stepsHeights)

  const addHeight = addStepHeightGenerator(setStepsHeights)

  const cleanupHeight = cleanUpStepHeightGenerator(setStepsHeights)

  const handleRemoveStep = (index, stepId) => e => {
    e.preventDefault()
    e.stopPropagation()
    if (window.confirm('Do you really want to remove?')) {
      removeStep(stepId)
      cleanupHeight(index)
    }
  }

  return (
    <g
      className="step-group"
      ref={ref}
      data-height={stepsHeights.reduce((a, b) => a + b, 0)}
    >
      {steps.map((step, index) => (
        <Step
          id="workflow-steps-select"
          key={step.id}
          step={step}
          level={level + 1}
          centerX={centerX}
          y={startY + getIndexY(stepsHeights, index)}
          heightSubscriber={addHeight(index)}
          addStep={createAddStep(index + 1)}
          removeStep={handleRemoveStep(index, step.id)}
          {...restProps}
        />
      ))}
    </g>
  )
}

Steps.defaultProps = {
  level: 0,
}

export default memo(Steps)
