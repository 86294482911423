import styled from 'styled-components/macro'

const ConditionAddGroupUi = styled.div`
  border: 1px dashed #cccccc;
  margin-bottom: 30px;
  padding: 18px;
  border-radius: 3px;
`

export default ConditionAddGroupUi
