/* eslint-disable no-undef */
import { useState, useEffect, useRef } from 'react'
import { appendScript } from 'workflow/utils/appendScript'
import { useUser } from './useUser'

const REACT_APP_GDRIVE_DEVELOPER_KEY =
  process.env.REACT_APP_GDRIVE_DEVELOPER_KEY
const REACT_APP_GDRIVE_APP_ID = process.env.REACT_APP_GDRIVE_APP_ID

export default function useGDrivePicker({ accessToken, onSelect }) {
  const [pickerInited, setPickerInited] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const pickerRef = useRef()
  const { locale } = useUser()

  const initializePicker = async () => {
    await gapi.client.load(
      'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
    )
    setPickerInited(true)
    setIsLoading(false)
  }

  const gapiLoaded = () => {
    gapi.load('client:picker', initializePicker)
  }

  useEffect(() => {
    setIsLoading(true)
    appendScript('https://apis.google.com/js/api.js')
      .then(gapiLoaded)
      .catch(e => {
        window.Rollbar.error('Failed to load google picker', e)
        setIsLoading(false)
      })
  }, [])

  const pickerCallback = event => {
    if (
      event.action === google.picker.Action.PICKED &&
      typeof onSelect === 'function'
    ) {
      const pickedDocs = event.docs
      onSelect(pickedDocs)
    }
  }

  const showPicker = () => {
    if (!pickerRef.current) {
      const view = new google.picker.View(google.picker.ViewId.DOCS)
      view.setMimeTypes('application/vnd.google-apps.spreadsheet')
      pickerRef.current = new google.picker.PickerBuilder()
        .setOAuthToken(accessToken)
        .setDeveloperKey(REACT_APP_GDRIVE_DEVELOPER_KEY)
        .setCallback(pickerCallback)
        .setAppId(REACT_APP_GDRIVE_APP_ID)
        .addView(view)
        .setLocale(locale)
        .build()
    }

    pickerRef.current.setVisible(true)
  }

  return {
    initiated: pickerInited,
    showPicker,
    isLoading,
  }
}
