import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactSelectEnhanced from 'shared/components/ReactSelectEnhaced'
import TriggerType from 'workflow/components/Step/types/Trigger/TriggerType'
import {
  triggerTypes,
  triggerTypesDescriptions,
  triggerTypesLabels,
  triggerTypesObjects,
} from 'workflow/components/Step/types/Trigger/triggerTypes'
import Button from 'shared/components/Button'
import Group from 'shared/components/Group'
import FormErrors from 'shared/components/FormErrors'
import { getTriggerIcon } from 'workflow/components/Step/types/Trigger/utils/get-trigger-icon'

const options = Object.values(triggerTypes).map(stepValue => ({
  value: stepValue,
  label: triggerTypesLabels[stepValue],
}))

const TriggerForm = ({
  stepData,
  handleSubmit,
  submitText,
  isLoading,
  errors,
}) => {
  const [t] = useTranslation()
  const [data, setData] = useState(stepData)

  const handleChangeType = option => {
    const property = triggerTypesObjects[option.value]
    setData(prev => ({
      ...data,
      [property]: {
        ...(triggerTypesObjects[prev.type] === property ? prev[property] : {}),
      },
      type: option.value,
    }))
  }

  const handleChange = payload => {
    const property = triggerTypesObjects[data.type]
    setData({
      ...data,
      [property]: payload,
    })
  }

  const handleClick = () => {
    handleSubmit(data)
  }

  const submitDisabled = isLoading || !data.type

  return (
    <>
      <Group data-testid="workflow-step-trigger-type">
        <ReactSelectEnhanced
          placeholder={t('workflow.steps.types.trigger.types.placeholder')}
          selected={data.type}
          options={options}
          getValueIcon={getTriggerIcon}
          onChange={handleChangeType}
          valueDescriptions={triggerTypesDescriptions}
        />
      </Group>
      {data.type && (
        <TriggerType
          change={handleChange}
          type={data.type}
          data={data[triggerTypesObjects[data.type]] ?? {}}
        />
      )}
      {errors.length > 0 && (
        <Group>
          <FormErrors errors={errors} />
        </Group>
      )}
      <Group>
        <Button onClick={handleClick} disabled={submitDisabled} success>
          {submitText}
        </Button>
      </Group>
    </>
  )
}

TriggerForm.defaultProps = {
  errors: [],
}

export default TriggerForm
