import React from 'react'
import Select from 'react-select'
import { styles } from 'shared/components/ReactSelectEnhaced'
import { useTranslation } from 'react-i18next'
import useSelectOptionsData from 'workflow/hooks/useSelectOptionsData'
import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'

const BlogPages = ({ onChange, selected, blogId }) => {
  const { t } = useTranslation()

  const { options, allowFetch, isLoading } = useSelectOptionsData({
    dataType: workflowDataTypes.blogPages,
    parentEntityId: blogId,
    defaultOptions: Object.keys(selected ?? {}).length > 0 && [selected],
    selector: blogPage => ({
      label: blogPage.name,
      value: blogPage.page?.id ?? blogPage.id,
    }),
  })

  return (
    <Select
      id="workflow-blog-page-select"
      onChange={onChange}
      options={options}
      maxMenuHeight={200}
      value={options.find(option => option.value === selected?.id)}
      styles={styles}
      noOptionsMessage={() => t('workflow.selectors.no_options')}
      placeholder={t('workflow.selectors.placeholder')}
      onFocus={allowFetch}
      isLoading={isLoading}
    />
  )
}

export default BlogPages
