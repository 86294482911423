import React from 'react'

function EmailLinkClickedIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 512 512"
      width="512"
      height="512"
      fill="#CBD5E1"
      {...props}
    >
      <path d="M488.432,0H23.568A22.916,22.916,0,0,0,.679,22.89v7.667L220.3,181.894a63.26,63.26,0,0,0,71.4,0L511.321,30.557V22.89A22.916,22.916,0,0,0,488.432,0Z" />
      <path d="M256,208.962a78.932,78.932,0,0,1-44.805-13.85L.679,50.05V321.965a22.751,22.751,0,0,0,4.046,12.973l131.5-148.376a8.026,8.026,0,0,1,12.014,10.646L18,344.165a22.863,22.863,0,0,0,5.573.689H130.947L112.7,326.6a30.705,30.705,0,1,1,43.424-43.424l59.093,59.093a30.3,30.3,0,0,1,37.96-20.159,30.3,30.3,0,0,1,43.543-22.5,24.191,24.191,0,0,1,6.519-12.805,28.045,28.045,0,0,1,39.661,0l52.675,52.675q2.614,2.614,5.084,5.372h87.778a22.863,22.863,0,0,0,5.573-.689L363.761,197.208a8.026,8.026,0,0,1,12.014-10.646l131.5,148.376a22.751,22.751,0,0,0,4.046-12.973V50.05L300.805,195.112A78.932,78.932,0,0,1,256,208.962Z" />
      <path d="M414.5,399.466a127.192,127.192,0,0,0-30.284-48.634l-52.675-52.675a11.991,11.991,0,0,0-16.96,0c-1.848,1.848-2.649,5.1-2.2,8.91a19.38,19.38,0,0,0,4.338,10.123c9.063,9.05-4.709,20.519-12.429,10.36l-11.012-10.874a14.283,14.283,0,1,0-20.245,20.154l3.377,3.377a8.026,8.026,0,0,1-11.351,11.35l-10.67-10.5a14.283,14.283,0,1,0-20.264,20.135s3.824,3.862,4.024,4.117a8.022,8.022,0,0,1-.6,10.555c-.018.018-.032.037-.05.055a8.027,8.027,0,0,1-10.741.551c-.21-.171-13.918-13.859-13.918-13.859l-68.079-68.078a14.653,14.653,0,1,0-20.722,20.724L244.209,435.415a11.156,11.156,0,0,1-11.86,18.314l-52.89-20.153A23.034,23.034,0,0,0,155,438.807a3.758,3.758,0,0,0,.831,5.942l62.9,34.943A215.75,215.75,0,0,0,294.953,505l69.936,7,68.879-68.879L414.616,399.8Z" />
    </svg>
  )
}

export default EmailLinkClickedIcon
