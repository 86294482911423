import React from 'react'
import FormErrorUi from './ui/FormErrorUi'

const FormErrors = ({ errors = [] }) => (
  <>
    {errors.map(error => (
      <FormErrorUi key={error} dangerouslySetInnerHTML={{ __html: error }} />
    ))}
  </>
)

export default FormErrors
