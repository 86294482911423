import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react'
import { useRefWithSelector } from 'shared/hooks/useRefWithSelector'
import { escapeNaN } from 'shared/utils/escapeNaN'
import {
  addStepHeightGenerator,
  cleanUpStepHeightGenerator,
} from 'workflow/utils/stepHeightStateUtils'

const SCROLLBAR_OFFSET = 15
const TRIGGERS_EMPTY_SPACE_COEFFICIENT = 2.5
const ADD_TRIGGER_BUTTON_OFFSET = 16 * TRIGGERS_EMPTY_SPACE_COEFFICIENT
const VERTICAL_SCROLL_OFFSET_COEFFICIENT = 1.2
const DEFAULT_TOP_OFFSET = 50

export const WorkflowAreaSizeContext = createContext()

const WorkflowAreaSizeProvider = ({
  children,
  offset = DEFAULT_TOP_OFFSET,
}) => {
  const checkedOffset =
    typeof offset === 'number' ? offset * VERTICAL_SCROLL_OFFSET_COEFFICIENT : 0
  const [minHeight, setMinHeight] = useState(window.innerHeight - checkedOffset)
  const [minWidth, setMinWidth] = useState(window.innerWidth)
  const [stepsHeights, setStepsHeights] = useState([])
  const [_, setCountElements] = useState(0)

  const { ref: triggersRef, value: triggersHeight } = useRefWithSelector(
    triggers => triggers.getBBox().height,
  )

  const { ref: rootStepsRef, value: height } = useRefWithSelector(
    steps =>
      steps.getBBox().height +
      (triggersHeight ?? 0) * TRIGGERS_EMPTY_SPACE_COEFFICIENT +
      checkedOffset,
    0,
  )

  const {
    ref: workflowRef,
    value: workflowWidth,
    setValue: setWorkflowWidth,
  } = useRefWithSelector(workflow => workflow.getBoundingClientRect().width)

  const [workflowCenter, setWorkflowCenter] = useState(
    Math.round((workflowWidth ?? 0) / 2),
  )

  const handleResize = useCallback(
    e => {
      const [width, height] = [e.target.innerWidth, e.target.innerHeight]
      setMinWidth(width)
      setMinHeight(height - checkedOffset)
    },
    [checkedOffset],
  )

  const addStepHeight = addStepHeightGenerator(setStepsHeights)
  const cleanUpStepHeight = cleanUpStepHeightGenerator(setStepsHeights)

  useEffect(() => {
    if (minWidth || workflowWidth) {
      const widthForCenter = Math.max(minWidth ?? 0, workflowWidth ?? 0)
      setWorkflowCenter(Math.round(widthForCenter / 2))
    }
  }, [minWidth, workflowWidth])

  useEffect(() => {
    checkedOffset && setMinHeight(window.innerHeight - checkedOffset)
  }, [checkedOffset])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const calculatedWidth = Math.max(
    minWidth,
    triggersRef.current?.getBBox().width ?? 0 + ADD_TRIGGER_BUTTON_OFFSET,
    rootStepsRef.current?.getBBox().width ?? 0,
  )

  const finalWidth =
    minWidth >= calculatedWidth
      ? calculatedWidth - SCROLLBAR_OFFSET
      : calculatedWidth + SCROLLBAR_OFFSET * TRIGGERS_EMPTY_SPACE_COEFFICIENT

  const incrementElements = () => setCountElements(elems => elems + 1)

  const reduceElements = () =>
    setCountElements(elems => (elems > 0 ? elems - 1 : 0))

  return (
    <WorkflowAreaSizeContext.Provider
      value={{
        height: escapeNaN(Math.max(minHeight, height)),
        width: escapeNaN(finalWidth),
        workflowRef,
        workflowCenter,
        setWidth: setWorkflowWidth,
        rootStepsRef,
        stepsHeights,
        addStepHeight,
        cleanUpStepHeight,
        triggersRef,
        incrementElements,
        reduceElements,
      }}
    >
      {children}
    </WorkflowAreaSizeContext.Provider>
  )
}

export default WorkflowAreaSizeProvider
