import { apiUrlByDataType } from 'workflow/consts/workflowDataTypes'

export default function getApiUrlByDataType(dataType, parentEntityId) {
  const rawUrl = apiUrlByDataType[dataType]

  if (typeof rawUrl === 'function') {
    if (!parentEntityId) return null

    return rawUrl(parentEntityId)
  }

  return rawUrl
}
