import styled from 'styled-components/macro'

const ConditionGroupUi = styled.div`
  padding: 5px 15px;
  border: 1px solid var(--default-border-color);
  border-top: 3px solid var(--blue-border-color);
  border-radius: 3px;
  background: #f2f2f2;
  position: relative;
  margin-bottom: 60px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 24px;
    top: calc(100% + 6px);
    width: 0;
    height: 48px;
    border-left: 1px solid #cccccc;
  }
  &:after {
    background-color: #fff;
    width: 24px;
    padding: 4px 0;
    text-align: center;
    letter-spacing: 1px;
    color: #262626;
    font-weight: bold;
    font-size: 12px;
    content: attr(data-aftertext);
    display: block;
    position: absolute;
    left: 12px;
    top: calc(100% + 18px);
  }
`

export default ConditionGroupUi
