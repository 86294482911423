import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'shared/components/Button'
import Group from 'shared/components/Group'
import Input from 'shared/components/Input'
import { handleResponse } from 'shared/utils/handleResponse'
import FormErrors from 'shared/components/FormErrors'
import { getWorkflowId } from 'workflow/utils/getWorkflowId'

const CreateEmail = ({
  onCreate,
  redirectPlaceholder,
  createEmailUrlPlaceholder,
}) => {
  const { t } = useTranslation()
  const [subject, setSubject] = useState('')
  const [errors, setErrors] = useState([])
  const [isLoading, setLoading] = useState(false)
  const { id: workflowId } = getWorkflowId()
  const handleChange = e => {
    setSubject(e.target.value)
  }

  const createEmailAndExit = () => {
    setLoading(true)
    fetch(createEmailUrlPlaceholder.replace('%workflowId%', workflowId), {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        workflow_systeme_email: {
          subject,
        },
      }),
    })
      .then(handleResponse)
      .then(data => {
        onCreate(data.id)
          .then(() => {
            setLoading(false)
            window.location.href = redirectPlaceholder
              .replace('%workflowId%', workflowId)
              .replace('%emailId%', data.id)
          })
          .catch(e => {
            if (e.errors && e.errros.common) {
              setErrors(e.errros.common)
              setLoading(false)
            } else {
              window.Rollbar.error(e)
            }
          })
      })
      .catch(e => {
        if (e.errors && e.errors.common) {
          setErrors(e.errors.common)
          setLoading(false)
        } else {
          window.Rollbar.error(e)
        }
      })
  }

  return (
    <>
      <Group>
        <Input
          onChange={handleChange}
          value={subject}
          placeholder={t('workflow.email.placeholder.subject')}
        />
      </Group>
      {errors.length > 0 && (
        <Group>
          <FormErrors errors={errors} />
        </Group>
      )}
      <Group>
        <Button onClick={createEmailAndExit} disabled={isLoading} success>
          {t('workflow.email.create')}
        </Button>
      </Group>
    </>
  )
}

export default CreateEmail
