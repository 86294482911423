import React from 'react'
import { useTranslation } from 'react-i18next'
import { stepTypes } from 'workflow/components/Step/stepTypes'
import useFormSubmit from 'workflow/hooks/useFormSubmit'
import { Modal } from 'modal/components'
import DelayForm from './DelayForm'
import { connect } from 'react-redux'
import { updateStep } from 'workflow/actions'
import * as api from 'workflow/api'

const DelayEdit = ({ handleClose, step, updateStep }) => {
  const handleUpdateStep = async data => {
    await api.editStep({ ...step, data })
    updateStep({ ...step, data })
  }

  const [isLoading, errors, handleSubmit] = useFormSubmit(
    handleUpdateStep,
    handleClose,
  )

  const [t] = useTranslation()

  return (
    <Modal
      onRequestClose={handleClose}
      header={t('workflow.steps.delay.modal.edit.header.title')}
      data-testid="modal-delay-edit"
    >
      <DelayForm
        submitText={t('workflow.steps.modal.edit.submit.label')}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        errors={errors}
        stepData={step}
      />
    </Modal>
  )
}

DelayEdit.defaultProps = {
  step: {
    data: {},
    objectType: stepTypes.delay,
  },
}

export default connect(null, { updateStep })(DelayEdit)
