import { useEffect, useRef, useState } from 'react'

export const useRefWithSelector = (selector, defaultValue) => {
  const [value, setValue] = useState(defaultValue)
  const ref = useRef()

  useEffect(() => {
    if (!selector || typeof selector !== 'function') return

    let interval = setInterval(() => {
      if (ref.current) {
        setValue(selector?.(ref.current))
        clearInterval(interval)
      }
    }, 100)

    return () => clearInterval(interval)
  }, [selector])

  return { ref, value, setValue }
}
