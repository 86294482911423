import React, { useRef, useEffect, useCallback, useState } from 'react'
import ReactModal from 'react-modal'
import ModalClose from 'modal/components/ModalClose'
import ModalHeader from 'modal/components/ModalHeader'
import ModalBody from 'modal/components/ModalBody'

export const Modal = ({
  header,
  onRequestClose,
  children,
  width,
  height,
  showClose,
  overflowBody,
  ...otherProps
}) => {
  return (
    <ReactModal
      isOpen
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: width,
          minHeight: height,
          width: '100%',
          padding: 0,
          border: 'none',
          boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2)',
          overflow: 'visible',
        },
      }}
      {...otherProps}
    >
      <ModalHeader>{header}</ModalHeader>
      {showClose && <ModalClose onClick={onRequestClose} />}
      <ModalBody overflowBody={overflowBody}>{children}</ModalBody>
    </ReactModal>
  )
}

Modal.defaultProps = {
  width: '600px',
  height: '300px',
  showClose: true,
  overflowBody: false /* this is a hack just for condition modal, it can be huge. It can be applied on all modals because it hides the selector (tags) */,
}
