import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'modal/components'
import ActionForm from 'workflow/components/Step/types/Action/ActionForm'
import useFormSubmit from 'workflow/hooks/useFormSubmit'
import { connect } from 'react-redux'
import { updateStep } from 'workflow/actions'
import * as api from 'workflow/api'
import validateMapping from './utils/validate-mapping'

const EditAction = ({ handleClose, step, updateStep }) => {
  const handleUpdateStep = async data => {
    await api.editStep({ ...step, data })
    updateStep({ ...step, data })
  }
  const [isLoading, errors, handleSubmit] = useFormSubmit(
    handleUpdateStep,
    handleClose,
    validateMapping,
  )
  const [t] = useTranslation()

  return (
    <Modal
      onRequestClose={handleClose}
      header={t('workflow.steps.modal.edit.header.title')}
      data-testid="modal-action-edit"
      showClose={!isLoading}
      shouldCloseOnOverlayClick={!isLoading}
    >
      <ActionForm
        handleSubmit={handleSubmit}
        submitText={t('workflow.steps.modal.edit.submit.label')}
        isLoading={isLoading}
        errors={errors}
        stepData={step}
      />
    </Modal>
  )
}

export default connect(null, { updateStep })(EditAction)
