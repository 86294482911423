import React from 'react'
import { useTranslation } from 'react-i18next'
import useFormSubmit from 'workflow/hooks/useFormSubmit'
import { Modal } from 'modal/components'
import ConditionForm from './ConditionForm'
import { connect } from 'react-redux'
import { updateStep } from 'workflow/actions'
import useConditionValidation from './useConditionValidation'
import * as api from 'workflow/api'

const ConditionEdit = ({ handleClose, step, updateStep, ...restProps }) => {
  const { t } = useTranslation()

  const { validate } = useConditionValidation()

  const handleUpdateStep = async data => {
    await api.editStep({ ...step, data })
    updateStep({ ...step, data })
  }

  const [isLoading, errors, handleSubmit] = useFormSubmit(
    handleUpdateStep,
    handleClose,
    validate,
  )

  return (
    <Modal
      onRequestClose={handleClose}
      header={t('workflow.steps.condition.modal.edit.header.title')}
      data-testid="modal-condition-create"
      width={800}
      height={400}
      overflowBody
    >
      <ConditionForm
        handleSubmit={handleSubmit}
        submitText={t('workflow.steps.condition.edit.submit.label')}
        isLoading={isLoading}
        errors={errors}
        stepData={step}
        {...restProps}
      />
    </Modal>
  )
}

export default connect(null, { updateStep })(ConditionEdit)
