import React from 'react'
import Group from 'shared/components/Group'
import EmailLinks from 'workflow/components/EmailLinks'
import Mailings from 'workflow/components/Mailings'

const TriggerTypeEmailLinkClicked = ({
  change,
  data,
  canChooseLink = true,
}) => {
  const handleChangeLink = ({ value }) => change({ ...data, link: value })

  const handleChangeMailing = ({ value, label }) =>
    change({ ...data, id: value, name: label })

  return (
    <>
      <Group>
        <Mailings selected={data} onChange={handleChangeMailing} />
      </Group>
      {canChooseLink && (
        <Group>
          <EmailLinks
            onChange={handleChangeLink}
            selected={data?.link}
            mailingId={data?.id}
          />
        </Group>
      )}
    </>
  )
}

export default TriggerTypeEmailLinkClicked
