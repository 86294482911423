import React from 'react'
import { DropdownOptionUi } from './ui/dropdownOptionUi'

export default function DropdownOption({ option, selected, onSelect }) {
  return (
    <DropdownOptionUi
      role="option"
      active={selected}
      onClick={() => onSelect(option)}
    >
      {option.label}
    </DropdownOptionUi>
  )
}
