import styled from 'styled-components/macro'

const WorkflowStateUi = styled.div`
  padding: 0 20px;
  font-size: 16px;
  //background-color: #ebedf0;
  color: #ebedf0;
  border-radius: 3px;
  border: 1px dotted var(--default-border-color);
`

export default WorkflowStateUi
