export const LOAD_WORKFLOW = 'LOAD_WORKFLOW'
export const ADD_STEP = 'ADD_STEP'
export const ADD_TRUE_STEP = 'ADD_TRUE_STEP'
export const ADD_FALSE_STEP = 'ADD_FALSE_STEP'
export const ADD_TRIGGER = 'ADD_TRIGGER'
export const UPDATE_TRIGGER = 'UPDATE_TRIGGER'
export const UPDATE_STEP = 'UPDATE_STEP'
export const REMOVE_STEP = 'REMOVE_STEP'
export const REVERT_REMOVE_STEP = 'REVERT_REMOVE_STEP'
export const REMOVE_TRIGGER = 'REMOVE_TRIGGER'
export const REMOVE_ROOT_ID = 'REMOVE_ROOT_ID'
export const REMOVE_FALSE_STEP = 'REMOVE_FALSE_STEP'
export const REMOVE_TRUE_STEP = 'REMOVE_TRUE_STEP'
export const STEP_ERROR = 'STEP_ERROR'
export const ADD_ROOT_ID = 'ADD_ROOT_ID'
export const ADD_EMAIL = 'ADD_EMAIL'
export const START_WORKFLOW_LOAD = 'START_WORKFLOW_LOAD'
