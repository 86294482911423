import { useTranslation } from 'react-i18next'
import { conditionTypes } from './conditionTypes'
import { compareOperations } from './compareOperations'

const requiredConditionFields = ['conditionType', 'searchField', 'searchValue']

export default function useConditionValidation() {
  const { t } = useTranslation()

  const validateCondition = condition => {
    const errors = []

    const isValid = condition?.conditionGroups?.every(cg =>
      cg?.contactFilterConditionModels?.every(cfcm => {
        if (
          cfcm?.conditionType === conditionTypes.tag &&
          cfcm?.compareOperation === compareOperations.without
        )
          return true

        return requiredConditionFields.every(key => !!cfcm[key])
      }),
    )

    if (!isValid) {
      errors.push(t('workflow.steps.condition.form.validate_error'))
    }

    return { isValid, errors }
  }

  return { validate: validateCondition }
}
