import React, { useEffect } from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import useSelectOptionsData from 'workflow/hooks/useSelectOptionsData'
import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'

const EmailLinks = ({ mailingId, onChange, selected }) => {
  const { t } = useTranslation()

  const hasSelectedObject = typeof selected === 'object'

  const defaultOptions = hasSelectedObject && [selected]

  const { options, isLoading, allowFetch } = useSelectOptionsData({
    dataType: workflowDataTypes.emailLinks,
    parentEntityId: mailingId,
    defaultOptions,
    selector: link => ({ value: link.id, label: link.original_url }),
  })

  const selectedValue = hasSelectedObject ? selected.id : selected

  useEffect(() => {
    if (mailingId && !options?.length && selectedValue) {
      allowFetch()
    }
  }, [options, selectedValue, mailingId])

  return (
    <Select
      id="workflow-email-link-select"
      onChange={onChange}
      options={options}
      maxMenuHeight={200}
      value={options.find(option => option.value === selectedValue)}
      noOptionsMessage={() => t('workflow.courses.message.no_options')}
      placeholder={t('workflow.courses.placeholder')}
      isLoading={isLoading}
      onFocus={mailingId ? allowFetch : undefined}
    />
  )
}

export default EmailLinks
