import { css } from 'styled-components'
import styled from 'styled-components/macro'

// transition: all 0.3s ease;
// ${p =>
// p.hoverFill &&
// css`
//     &:hover {
//       fill: ${p.hoverFill};
// }
// `};

const RectUi = styled.rect`
  cursor: pointer;
  fill: ${p => p.fill};
`

RectUi.defaultProps = {
  fill: 'lightblue',
  hoverFill: false,
}

export default RectUi
