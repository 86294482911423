export const stepTypes = {
  action: 'action',
  trigger: 'trigger',
  condition: 'condition',
  delay: 'delay',
}

export const stepTypesNames = {
  [stepTypes.action]: 'workflow.steps.types.action.name',
  [stepTypes.trigger]: 'workflow.steps.types.trigger.name',
  [stepTypes.condition]: 'workflow.steps.types.condition.name',
  [stepTypes.delay]: 'workflow.steps.types.delay.name',
}

export const stepTypesDescriptions = {
  [stepTypes.action]: 'workflow.steps.types.action.create_description',
  [stepTypes.trigger]: 'workflow.steps.types.trigger.description',
  [stepTypes.condition]: 'workflow.steps.types.condition.description',
  [stepTypes.delay]: 'workflow.steps.types.delay.description',
}
