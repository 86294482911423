import { useEffect } from 'react'
import useControlledSWRImmutable from 'shared/hooks/useControlledSWRImmutable'

const MAPPING_API = `/api/dashboard/google-integration/spreadsheet-mappings`

export default function useMapping({ mappingId }) {
  const { data, allowFetch } = useControlledSWRImmutable(
    mappingId && `${MAPPING_API}/${mappingId}`,
  )

  useEffect(() => {
    if (mappingId) allowFetch()
  }, [mappingId])

  return { mapping: data }
}
