import React from 'react'

function SaleCanceledIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="98"
      viewBox="0 0 100 98"
      fill="#CBD5E1"
      {...props}
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="4.606"
          y1="-1.004"
          x2="4.606"
          y2="8.832"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#64748b" />
          <stop offset="1" stopColor="#003c68" />
        </linearGradient>
      </defs>
      <g id="cancel" transform="translate(-10 -12)">
        <path
          id="Tracé_22885"
          data-name="Tracé 22885"
          d="M101,12H19a5,5,0,0,0-5,5v1h92V17A5,5,0,0,0,101,12Z"
        />
        <path
          id="Tracé_22886"
          data-name="Tracé 22886"
          d="M54.122,41.294H58v6.5H54.122a2.908,2.908,0,0,1-2.33-.951,3.735,3.735,0,0,1,0-4.6A2.908,2.908,0,0,1,54.122,41.294Z"
        />
        <path
          id="Tracé_22887"
          data-name="Tracé 22887"
          d="M60,58a25.985,25.985,0,1,0,10.851,2.366A26.038,26.038,0,0,0,60,58ZM51.586,75.586a2,2,0,0,1,2.828,0L60,81.172l5.586-5.586a2,2,0,0,1,2.828,2.828L62.828,84l5.586,5.586a2,2,0,0,1-2.828,2.828L60,86.828l-5.586,5.586a2,2,0,0,1-2.828-2.828L57.172,84l-5.586-5.586A2,2,0,0,1,51.586,75.586Z"
          fillRule="evenodd"
        />
        <path
          id="Tracé_22888"
          data-name="Tracé 22888"
          d="M72.792,56.856A7.987,7.987,0,0,0,73,55.044a7.517,7.517,0,0,0-1.77-4.92,6.9,6.9,0,0,0-5.353-2.33H62v-6.5h6.8a2,2,0,0,0,0-4H62v-2a2,2,0,0,0-4,0v2H54.122a6.9,6.9,0,0,0-5.352,2.33,7.724,7.724,0,0,0,0,9.839,6.9,6.9,0,0,0,5.352,2.33H58v2.272A30.02,30.02,0,0,0,31.079,76h-6.11C24.475,68.132,18.174,62.455,10,62.026V35.972A15.513,15.513,0,0,0,24.888,22H95.112A15.513,15.513,0,0,0,110,35.972V62.026c-8.174.429-14.475,6.106-14.969,13.974h-6.11A30.074,30.074,0,0,0,72.792,56.856Z"
        />
        <path
          id="Tracé_22889"
          data-name="Tracé 22889"
          d="M62,54.066V51.794h3.878a2.909,2.909,0,0,1,2.33.951,3.59,3.59,0,0,1,.779,2.625A29.9,29.9,0,0,0,62,54.066Z"
        />
        <path
          id="Tracé_22890"
          data-name="Tracé 22890"
          d="M105,76H99.043c.472-5.44,4.791-9.565,10.957-9.968V71A5,5,0,0,1,105,76Z"
        />
        <path
          id="Tracé_22891"
          data-name="Tracé 22891"
          d="M10,66.032c6.166.4,10.485,4.528,10.957,9.968H15a5,5,0,0,1-5-5Z"
        />
        <path
          id="Tracé_22892"
          data-name="Tracé 22892"
          d="M20.854,22A11.489,11.489,0,0,1,10,31.964V27a5,5,0,0,1,5-5Z"
        />
        <path
          id="Tracé_22893"
          data-name="Tracé 22893"
          d="M110,31.964A11.488,11.488,0,0,1,99.146,22H105a5,5,0,0,1,5,5Z"
        />
      </g>
    </svg>
  )
}

export default SaleCanceledIcon
