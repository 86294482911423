import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'shared/components/Button'
import Group from 'shared/components/Group'
import HorizontalGroup from 'shared/components/HorizontalGroup'
import Input from 'shared/components/Input'
import FormErrors from 'shared/components/FormErrors'
import {
  delayType,
  delayUnitTypes,
} from 'workflow/components/Step/types/Delay/delayTypes'
import DelayUnits from './DelayUnits'
import { isExactDate } from 'workflow/components/Step/types/Delay/isExactDate'
import moment from 'moment'

function isValidDate(date) {
  if (!date) return false

  const dateParsed = new Date(date)
  const today = new Date()

  return dateParsed > today
}

const DelayForm = ({
  stepData,
  handleSubmit,
  submitText,
  isLoading,
  errors,
}) => {
  const [t] = useTranslation()
  const [data, setData] = useState(stepData)
  const handleChangeUnit = option => {
    setData(data =>
      isExactDate(option.value)
        ? {
            type: delayType.date,
            objectType: data.objectType,
            date: '',
          }
        : {
            type: delayType.fixed,
            objectType: data.objectType,
            duration: data.duration || 1,
            unit: option.value,
          },
    )
  }

  const handleChangeDuration = ({ target: { value } }) => {
    setData(data => ({
      ...data,
      duration: value ? parseInt(value) : 0,
    }))
  }

  const handleChangeDate = ({ target: { value } }) => {
    setData(data => ({
      ...data,
      date: value,
    }))
  }

  const handleClick = () => {
    handleSubmit(data)
  }

  const submitDisabled =
    isLoading || isExactDate(data.type)
      ? !isValidDate(data.date)
      : !data.duration || !data.unit

  return (
    <>
      <Group>
        <p>{t('workflow.steps.types.delay.modal.label.duration')}</p>
      </Group>
      <HorizontalGroup
        styles={{
          gridTemplateColumns: '40% 30%',
          marginLeft: 0,
        }}
      >
        {isExactDate(data.type) ? (
          <Input
            min={moment(new Date()).format('YYYY-MM-DDTHH:mm')}
            type={'datetime-local'}
            value={moment(data.date).format('YYYY-MM-DDTHH:mm')}
            onChange={handleChangeDate}
          />
        ) : (
          <Input
            type={'number'}
            value={data.duration}
            onChange={handleChangeDuration}
          />
        )}
        <DelayUnits
          onChange={handleChangeUnit}
          selected={isExactDate(data.type) ? delayUnitTypes.date : data.unit}
        />
      </HorizontalGroup>
      <Group />
      {errors.length > 0 && (
        <Group>
          <FormErrors errors={errors} />
        </Group>
      )}
      <Group>
        <Button onClick={handleClick} disabled={submitDisabled} success>
          {submitText}
        </Button>
      </Group>
    </>
  )
}

DelayForm.defaultProps = {
  errors: [],
}

export default DelayForm
