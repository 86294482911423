import { useState } from 'react'
import { BadRequest, NetworkError } from 'errors'
import { message as networkErrorMessage } from 'errors/NetworkError'
import { useTranslation } from 'react-i18next'

// separate dispatch from request
function useFormSubmit(
  handleSubmit,
  closeHandler = () => {},
  validate = () => ({ isValid: true }),
) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState([])

  const handleSubmitInternal = async (data, closeOnSubmit = true) => {
    setIsLoading(true)

    const { isValid, errors: validationErrors } = validate?.(data) ?? {
      isValid: true,
    }

    if (!isValid) {
      setIsLoading(false)
      setErrors(validationErrors)
      return
    }

    let promise
    try {
      promise = await handleSubmit(data)
      setIsLoading(false)
      if (closeOnSubmit) {
        closeHandler()
      }
    } catch (error) {
      let errorList
      if (error instanceof NetworkError) {
        errorList = [networkErrorMessage]
      } else if (error instanceof BadRequest) {
        errorList = error.response.data.errors.common
      } else {
        window.Rollbar.error(error)
        errorList = [t('workflow.something_went_wrong.error')]
      }

      setErrors(errorList)
      setIsLoading(false)
    }

    return promise
  }

  return [isLoading, errors, handleSubmitInternal]
}

export default useFormSubmit
