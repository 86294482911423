import styled from 'styled-components'
import Button from 'shared/components/Button'

export const DriveSelectButtonUi = styled(Button)`
  font-size: 1rem;
  border-color: var(--select-border-color);
  border-radius: 4px;
  color: var(--content-text-color);
  margin: 0;
`
