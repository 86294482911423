import React from 'react'
import Select from 'react-select'
import { styles } from 'shared/components/ReactSelectEnhaced'
import { useTranslation } from 'react-i18next'
import useSelectOptionsData from 'workflow/hooks/useSelectOptionsData'
import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'

const FunnelSteps = ({ onChange, selected, funnelId }) => {
  const { t } = useTranslation()

  const { options, isLoading, allowFetch } = useSelectOptionsData({
    dataType: workflowDataTypes.funnelSteps,
    parentEntityId: funnelId,
    defaultOptions: selected && [selected],
  })

  return (
    <Select
      id="workflow-funnel-steps-select"
      onChange={onChange}
      options={options}
      maxMenuHeight={200}
      value={options.find(option => option.value === selected?.id)}
      styles={styles}
      noOptionsMessage={() => t('workflow.funnel_steps.message.no_options')}
      placeholder={t('workflow.funnel_steps.placeholder')}
      onFocus={allowFetch}
      isLoading={isLoading}
    />
  )
}

export default FunnelSteps
