import React from 'react'

function NewSaleIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      width="512"
      height="512"
      fill="#CBD5E1"
      {...props}
    >
      <g>
        <path d="M347,182c-90.981,0-165,74.019-165,165s74.019,165,165,165s165-74.019,165-165S437.981,182,347,182z M347,332   c24.814,0,45,20.186,45,45c0,19.53-12.578,36.024-30,42.237V452h-30v-32.763c-17.422-6.213-30-22.707-30-42.237h30   c0,8.276,6.724,15,15,15s15-6.724,15-15s-6.724-15-15-15c-24.814,0-45-20.186-45-45c0-19.53,12.578-36.024,30-42.237V242h30v32.763   c17.422,6.213,30,22.707,30,42.237h-30c0-8.276-6.724-15-15-15s-15,6.724-15,15S338.724,332,347,332z" />
        <path d="M167,182c92.52,0,165-39.536,165-90S259.52,0,167,0S0,41.536,0,92S74.48,182,167,182z" />
        <path d="M0,337.559V362c0,50.464,74.48,90,167,90c5.248,0,10.333-0.483,15.5-0.736c-11.506-18.091-20.087-38.165-25.14-59.625   C90.455,389.792,32.362,369.233,0,337.559z" />
        <path d="M152.721,361.236C152.377,356.518,152,351.805,152,347c0-15.637,2.049-30.771,5.543-45.353   C90.555,299.832,32.391,279.262,0,247.559V272C0,319.787,67.262,357.342,152.721,361.236z" />
        <path d="M167,272c0.016,0,0.029-0.002,0.046-0.002c9.893-23.644,24.274-44.958,42.133-62.816C195.632,210.894,181.632,212,167,212   c-71.217,0-133.074-21.237-167-54.441V182C0,232.464,74.48,272,167,272z" />
      </g>
    </svg>
  )
}

export default NewSaleIcon
