import React from 'react'
import Courses from 'workflow/components/Courses'
import Group from 'shared/components/Group'

const ActionTypeRevokeAccessToCourse = ({ change, data }) => {
  const handleChangeCourse = ({ value, label }) => {
    change({ ...data, id: value, name: label })
  }

  return (
    <>
      <Group>
        <Courses onChange={handleChangeCourse} selected={data} />
      </Group>
    </>
  )
}

export default ActionTypeRevokeAccessToCourse
