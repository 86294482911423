import styled, { css } from 'styled-components'

const hoverAndActive = css`
  background: var(--field-border-color);
`

export const DropdownOptionUi = styled.li`
  padding: 0.6rem 0.5rem;
  border: 1px solid var(--field-border-color);
  border-left: 0;
  border-right: 0;
  cursor: pointer;
  transition: all 0.3s ease-in;
  padding: 0.5rem 1rem;

  &:hover {
    ${hoverAndActive}
  }

  ${({ active }) => active && hoverAndActive}
`
