import { ModalContext } from 'modal/context'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { stepChildTypes } from 'workflow/components/Step/stepChildTypes'
import StepCreate from 'workflow/components/Step/StepCreate'
import Circle from 'workflow/components/Step/shared/Circle'
import useHover from 'workflow/hooks/useHover'
import useWidth from 'workflow/hooks/useWidth'
import DelayEdit from './DelayEdit'
import Line, { height as lineHeight } from '../../shared/Line'
import Remove, { radius as removeDimension } from '../../shared/Remove'
import Rect, { height as rectHeight } from '../../shared/Rect'
import Text, { padding as textPadding } from '../../shared/Text'
import Group from '../../shared/Group'
import { getStepDataName } from './delayTypes'
import ClockIcon from 'shared/icons/clock-icon'
import { svgSize } from 'workflow/components/Step/shared/Svg'

const totalHeight = 96

const Delay = ({ centerX, y, step, onRemove, addStep, heightSubscriber }) => {
  heightSubscriber(totalHeight)
  const [t] = useTranslation()
  const unitName = getStepDataName(step)
  const date = step.date
  const name = `${t('workflow.steps.types.delay.common_name')} ${
    date
      ? new Date(date).toLocaleString()
      : t(unitName, {
          count: step.duration,
        })
  }`
  const { showModal } = useContext(ModalContext)
  const [ref, textWidth] = useWidth(name)
  const [hoverRef, isHovered] = useHover(false)
  const gap = 5
  const width = textWidth + textPadding * 2 + svgSize + gap

  const rectX = centerX - width / 2
  const rectY = y + lineHeight

  const iconX = centerX - width / 2 + textPadding
  const iconY = y + lineHeight / 2 + rectHeight / 2

  const textX = centerX - width / 2 + textPadding + svgSize + gap
  const textY = y + lineHeight + rectHeight / 2

  const removeX = centerX + width / 2 - removeDimension
  const removeY = y + lineHeight - removeDimension

  const startDownLineY = y + lineHeight + rectHeight

  const circleY = y + lineHeight + rectHeight + lineHeight

  return (
    <g
      // ref={stepRef}
      className="delay-action"
      data-id={step.id}
      data-height={totalHeight}
    >
      <Line startX={centerX} startY={y} />
      <Group
        ref={hoverRef}
        onClick={() =>
          showModal(DelayEdit, {
            step,
          })
        }
        visibility={textWidth > 0 ? 'visible' : 'hidden'}
      >
        <Rect
          x={rectX}
          y={rectY}
          width={width}
          fill="#fff"
          stroke="none"
          hoverFill="#fffc"
        />
        <ClockIcon
          x={iconX}
          y={iconY}
          fill="#333"
          width={svgSize}
          height={svgSize}
        />
        <Text
          x={textX}
          y={textY}
          ref={ref}
          name={name}
          data-testid="delay-name"
          fill="#333"
        />
        <Remove
          x={removeX}
          y={removeY}
          visibility={isHovered}
          onClick={onRemove}
          data-testid="delay-remove-button"
        />
      </Group>
      <Line startX={centerX} startY={startDownLineY} />
      <Circle
        x={centerX}
        y={circleY}
        onClick={() =>
          showModal(StepCreate, {
            parentStepId: step.id,
            childType: stepChildTypes.child,
            addStep,
          })
        }
      />
    </g>
  )
}

export default Delay
