import { AccessDenied, BadRequest, NetworkError } from 'errors'
import { message as networkErrorMessage } from 'errors/NetworkError'
import { normalize } from 'normalizr'
import { workflowSchema } from './schema'
import i18next from 'i18next'
import {
  ADD_STEP,
  ADD_TRIGGER,
  LOAD_WORKFLOW,
  REMOVE_STEP,
  STEP_ERROR,
  ADD_ROOT_ID,
  UPDATE_STEP,
  ADD_FALSE_STEP,
  ADD_TRUE_STEP,
  REMOVE_ROOT_ID,
  REMOVE_FALSE_STEP,
  REMOVE_TRUE_STEP,
  UPDATE_TRIGGER,
  REMOVE_TRIGGER,
  ADD_EMAIL,
  REVERT_REMOVE_STEP,
  START_WORKFLOW_LOAD,
} from 'workflow/actionTypes'
import * as api from 'workflow/api'
import { getStep } from 'workflow/reducer'
import { getWorkflowId } from './utils/getWorkflowId'

export const addTrigger = step => async dispatch => {
  try {
    const { id: workflowId } = getWorkflowId()
    const result = await api.addTrigger(workflowId, step)
    dispatch({
      type: ADD_TRIGGER,
      payload: {
        ...step.data,
        id: result.id,
      },
    })
    return result
  } catch (e) {
    if (e instanceof AccessDenied) {
      window.open(e.logingUrl)
    } else if (e instanceof NetworkError) {
      return Promise.reject([networkErrorMessage])
    } else if (e instanceof BadRequest) {
      return Promise.reject(e.response.data.errors.common)
    } else {
      window.Rollbar.error(e)
      return Promise.reject([i18next.t('workflow.something_went_wrong.error')])
    }
  }
}

export const updateTrigger = step => async dispatch => {
  try {
    const promise = await api.editTrigger(step)
    dispatch({
      type: UPDATE_TRIGGER,
      payload: step.data,
    })
    return promise
  } catch (e) {
    if (e instanceof NetworkError) {
      return Promise.reject([networkErrorMessage])
    }

    if (e.status !== 400) {
      window.Rollbar.error(e)
    }
    dispatch({
      type: STEP_ERROR,
      payload: e.statusText,
    })
    return Promise.reject(e.errors.common)
  }
}

export const addStep = step => dispatch => {
  dispatch({
    type: ADD_STEP,
    payload: { id: step.id, ...step.data },
  })
}

export const addRootId = (id, index) => dispatch => {
  dispatch({
    type: ADD_ROOT_ID,
    payload: { index, id },
  })
}

export const addFalseStep = (parentStepId, id, index) => dispatch => {
  dispatch({
    type: ADD_FALSE_STEP,
    payload: { parentStepId, index, id },
  })
}

export const addTrueStep = (parentStepId, id, index) => dispatch => {
  dispatch({
    type: ADD_TRUE_STEP,
    payload: { parentStepId, index, id },
  })
}

export const updateStep = step => dispatch => {
  dispatch({
    type: UPDATE_STEP,
    payload: step.data,
  })
}

export const removeStep = stepId => async (dispatch, getState) => {
  const backupStep = getStep(getState().workflow, stepId)
  dispatch({
    type: REMOVE_STEP,
    payload: stepId,
  })
  try {
    return await api.removeStep(stepId)
  } catch (e) {
    if (e instanceof NetworkError) {
      // do nothing, already alerted
    } else {
      window.Rollbar.error(e)
    }

    dispatch({
      type: STEP_ERROR,
      payload: e.statusText,
    })
    dispatch({
      type: REVERT_REMOVE_STEP,
      payload: backupStep,
    })
    return Promise.reject()
  }
}

export const removeTrigger = stepId => async dispatch => {
  try {
    const promise = await api.removeStep(stepId)
    dispatch({
      type: REMOVE_TRIGGER,
      payload: stepId,
    })
    return promise
  } catch (e) {
    if (e instanceof NetworkError) {
      alert(networkErrorMessage)
    } else {
      window.Rollbar.error(e)
    }
    dispatch({
      type: STEP_ERROR,
      payload: e.statusText,
    })
  }
}

export const removeRootId = stepId => dispatch => {
  dispatch({
    type: REMOVE_ROOT_ID,
    payload: { stepId },
  })
}

export const removeFalseStep = (fromStepId, stepId) => dispatch => {
  dispatch({
    type: REMOVE_FALSE_STEP,
    payload: { fromStepId, stepId },
  })
}

export const removeTrueStep = (fromStepId, stepId) => dispatch => {
  dispatch({
    type: REMOVE_TRUE_STEP,
    payload: { fromStepId, stepId },
  })
}

export const loadWorkflow = id => async dispatch => {
  dispatch({ type: START_WORKFLOW_LOAD })
  try {
    const responseData = await api.fetchWorkflow(id)
    // we can get undefined when redirecting to the login page
    if (responseData) {
      const workflow = normalize(responseData, workflowSchema).entities
      const rootIds = responseData.steps.map(step => step.id)
      dispatch({
        type: LOAD_WORKFLOW,
        payload: {
          rootIds,
          ...workflow,
          name: responseData.name,
          active: responseData.active,
        },
      })
      if (typeof window.Rollbar !== 'undefined') {
        window.Rollbar.configure({
          payload: {
            context: `workflowId#${responseData.id}`,
          },
        })
      }
    }
    return Promise.resolve()
  } catch (error) {
    if (error.status === 404) {
      window.location.href = '/dashboard/workflows'
      return Promise.resolve()
    } else {
      window.Rollbar.error(error)
    }
    return Promise.reject(error)
  }
}

export const addEmail = email => dispatch => {
  dispatch({
    type: ADD_EMAIL,
    payload: email,
  })
}
