import { produce } from 'immer'

export const addStepHeightGenerator = setStepsHeights => index => height => {
  setStepsHeights(prevHeight =>
    produce(prevHeight, draft => {
      draft[index] = height
    }),
  )
}

export const cleanUpStepHeightGenerator = setStepsHeights => index => {
  setStepsHeights(prevHeight => [
    ...prevHeight.slice(0, index),
    ...prevHeight.slice(index + 1),
  ])
}
