import React, { forwardRef } from 'react'

export const padding = 15

const Text = forwardRef(({ x, y, name, ...restProps }, ref) => {
  return (
    <text
      x={x}
      y={y}
      fill="#333"
      stroke="none"
      dominantBaseline="middle"
      fontSize="14px"
      ref={ref}
      {...restProps}
    >
      {name}
    </text>
  )
})

export default Text
