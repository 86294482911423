import { stepTypes } from 'workflow/components/Step/stepTypes'
import Action from 'workflow/components/Step/types/Action'
import ActionCreate from 'workflow/components/Step/types/Action/ActionCreate'
import ConditionCreate from 'workflow/components/Step/types/Condition/ConditionCreate'
import Condition from 'workflow/components/Step/types/Condition'
import Delay from 'workflow/components/Step/types/Delay'
import DelayCreate from 'workflow/components/Step/types/Delay/DelayCreate'

export function createStep(type) {
  switch (type) {
    case stepTypes.action:
      return Action
    case stepTypes.condition:
      return Condition
    case stepTypes.delay:
      return Delay
    default:
      throw new Error(`Unknown step type! ${type}`)
  }
}

export function createStepModal(type) {
  switch (type) {
    case stepTypes.action:
      return ActionCreate
    case stepTypes.condition:
      return ConditionCreate
    case stepTypes.delay:
      return DelayCreate
    default:
      throw new Error(`Unknown step type! ${type}`)
  }
}
