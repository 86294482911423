import {
  ADD_EMAIL,
  ADD_FALSE_STEP,
  ADD_ROOT_ID,
  ADD_STEP,
  ADD_TRIGGER,
  ADD_TRUE_STEP,
  LOAD_WORKFLOW,
  REMOVE_FALSE_STEP,
  REMOVE_ROOT_ID,
  REMOVE_STEP,
  REMOVE_TRIGGER,
  REMOVE_TRUE_STEP,
  REVERT_REMOVE_STEP,
  STEP_ERROR,
  UPDATE_STEP,
  UPDATE_TRIGGER,
  START_WORKFLOW_LOAD,
} from 'workflow/actionTypes'

const initState = {
  name: '',
  active: false,
  steps: {},
  triggers: {},
  rootIds: [],
  isLoadingWorkflow: false,
}

export default function(state = initState, action) {
  const { type, payload } = action
  switch (type) {
    case START_WORKFLOW_LOAD:
      return { ...state, isLoadingWorkflow: true }
    case LOAD_WORKFLOW:
      return {
        ...state,
        ...payload,
        isLoadingWorkflow: false,
      }
    case REVERT_REMOVE_STEP:
    case ADD_STEP:
      return {
        ...state,
        steps: {
          ...state.steps,
          [payload.id]: payload,
        },
      }
    case ADD_ROOT_ID:
      return {
        ...state,
        rootIds: [
          ...state.rootIds.slice(0, payload.index),
          payload.id,
          ...state.rootIds.slice(payload.index),
        ],
      }
    case ADD_FALSE_STEP: {
      const step = state.steps[payload.parentStepId]
      return {
        ...state,
        steps: {
          ...state.steps,
          [payload.parentStepId]: {
            ...step,
            falsePath: [
              ...step.falsePath.slice(0, payload.index),
              payload.id,
              ...step.falsePath.slice(payload.index),
            ],
          },
        },
      }
    }
    case ADD_TRUE_STEP: {
      const step = state.steps[payload.parentStepId]
      return {
        ...state,
        steps: {
          ...state.steps,
          [payload.parentStepId]: {
            ...step,
            truePath: [
              ...step.truePath.slice(0, payload.index),
              payload.id,
              ...step.truePath.slice(payload.index),
            ],
          },
        },
      }
    }
    case ADD_TRIGGER:
    case UPDATE_TRIGGER:
      return {
        ...state,
        triggers: {
          ...state.triggers,
          [payload.id]: payload,
        },
      }
    case REMOVE_TRIGGER: {
      const updatedTriggers = { ...state.triggers }
      delete updatedTriggers[payload]
      return {
        ...state,
        triggers: updatedTriggers,
      }
    }
    case UPDATE_STEP:
      return {
        ...state,
        steps: {
          ...state.steps,
          [payload.id]: payload,
        },
      }
    case REMOVE_STEP:
      let newSteps = { ...state.steps }
      delete newSteps[payload]
      return {
        ...state,
        steps: newSteps,
      }
    case REMOVE_ROOT_ID:
      return {
        ...state,
        rootIds: state.rootIds.filter(id => id !== payload.stepId),
      }
    case REMOVE_TRUE_STEP: {
      const step = state.steps[payload.fromStepId]
      return {
        ...state,
        steps: {
          ...state.steps,
          [payload.fromStepId]: {
            ...step,
            truePath: step.truePath.filter(id => id !== payload.stepId),
          },
        },
      }
    }
    case REMOVE_FALSE_STEP: {
      const step = state.steps[payload.fromStepId]
      return {
        ...state,
        steps: {
          ...state.steps,
          [payload.fromStepId]: {
            ...step,
            falsePath: step.falsePath.filter(id => id !== payload.stepId),
          },
        },
      }
    }
    case STEP_ERROR:
      return {
        ...state,
        stepErrors: payload,
      }
    case ADD_EMAIL:
      return {
        ...state,
        emails: {
          ...state.emails,
          [payload.id]: payload.subject,
        },
      }
    default:
      return state
  }
}

export const getRootIds = state => state.rootIds

export const getStepsByIds = ids => state =>
  ids?.map(id => state.steps[id]).filter(Boolean) ?? []

export const getStep = (state, id) => state.steps[id]

export const getTriggers = state =>
  Object.values(state.workflow?.triggers ?? {})

export const getContactFields = state => Object.values(state.contactFields)

export const getContactFieldsByIds = state => state.contactFields

export const getLocale = state => state.locale

export const getWorkflowName = state => state.name

export const getCommunities = state => Object.values(state.communities)

export const getCoursesByIds = state => state.courses

export const getOptInSteps = state => Object.values(state.optInSteps)

export const getBlogPages = state => Object.values(state.blogPages)

export const getOptInStepsByIds = state => state.optInSteps

export const getSaleSteps = state => Object.values(state.saleSteps)

export const getSaleStepsByIds = state => state.saleSteps

export const getWebinarRegistrationSteps = state =>
  Object.values(state.webinarRegistrationSteps)

export const getWebinarRegistrationStepsByIds = state =>
  state.webinarRegistrationSteps

export const getEmails = state => Object.values(state.emails)

export const getMailings = state => Object.values(state.mailings)

export const getFunnelSteps = state => Object.values(state.funnelSteps)

export const getEmailsMessagesWithRecipient = state =>
  Object.values(state.emailMessagesWithRecipient)

export const getWorkflowState = state => ({
  name: state.name,
  active: state.active,
  isLoadingWorkflow: state.isLoadingWorkflow,
})

export const getIsLoadingWorkflow = state => state.isLoadingWorkflow

const getDescendantIds = (allSteps, stepIds) => {
  return stepIds
    .map(stepId => allSteps[stepId])
    .filter(Boolean)
    .reduce((acc, step) => {
      return [
        ...acc,
        step,
        ...(step.falsePath ? getDescendantIds(allSteps, step.falsePath) : []),
        ...(step.truePath ? getDescendantIds(allSteps, step.truePath) : []),
      ]
    }, [])
}

export const getStepsCount = state => {
  const descendantIds = getDescendantIds(state.steps, state.rootIds)
  return descendantIds.length
}
