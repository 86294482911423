import React, { forwardRef } from 'react'
import { PseudoTextareaUi } from './ui/pseudoTextareaUi'

const PseudoTextarea = forwardRef(function PseudoTextarea(
  { children, disabled, ...restProps },
  ref,
) {
  return (
    <PseudoTextareaUi
      contentEditable={!disabled}
      role="textarea"
      ref={ref}
      tabIndex={0}
      disabled={disabled}
      {...restProps}
    >
      {children}
    </PseudoTextareaUi>
  )
})

export default PseudoTextarea
