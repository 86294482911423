import styled, { css } from 'styled-components'
import React from 'react'

const loadingDotAnimations = css`
  @keyframes loadingDotAnimations {
    0%,
    80%,
    100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
`

export const LoadingIndicatorUi = styled.div`
  display: flex;
  transition: color 150ms;
  align-self: center;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  gap: 5px;
`
const LoadingDotUi = styled.span`
  ${loadingDotAnimations}

  ${({ delay }) =>
    css`
      animation: loadingDotAnimations 1s ease-in-out ${delay}ms infinite;
    `}

    background-color: currentColor;
  border-radius: 1em;
  display: inline-block;
  vertical-align: top;
  height: 5px;
  width: 5px;
`

export default function Loader(props) {
  return (
    <LoadingIndicatorUi {...props}>
      <LoadingDotUi delay={0} />
      <LoadingDotUi delay={160} />
      <LoadingDotUi delay={320} />
    </LoadingIndicatorUi>
  )
}
