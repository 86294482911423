import React from 'react'
import { useTranslation } from 'react-i18next'
import { stepTypes } from 'workflow/components/Step/stepTypes'
import useFormSubmit from 'workflow/hooks/useFormSubmit'
import { Modal } from 'modal/components'
import ActionForm from 'workflow/components/Step/types/Action/ActionForm'
import validateMapping from './utils/validate-mapping'

const ActionCreate = ({
  handleClose,
  parentStepId,
  addStep,
  step,
  ...restProps
}) => {
  const handleAddStep = async data => {
    await addStep(parentStepId, { ...step, data }, restProps.childType)
  }

  const [isLoading, errors, handleSubmit] = useFormSubmit(
    handleAddStep,
    handleClose,
    validateMapping,
  )
  const [t] = useTranslation()

  return (
    <Modal
      onRequestClose={handleClose}
      header={t('workflow.steps.action.modal.create.header.title')}
      data-testid="modal-action-create"
      showClose={!isLoading}
      shouldCloseOnOverlayClick={!isLoading}
    >
      <ActionForm
        handleSubmit={handleSubmit}
        submitText={t('workflow.steps.modal.create.submit.label')}
        isLoading={isLoading}
        errors={errors}
        stepData={step}
        {...restProps}
      />
    </Modal>
  )
}

ActionCreate.defaultProps = {
  step: {
    objectType: stepTypes.action,
  },
}

export default ActionCreate
