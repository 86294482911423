import React from 'react'
import Group from 'shared/components/Group'
import Courses from 'workflow/components/Courses'

const TriggerTypeCourse = ({ change, data }) => {
  const handleChange = ({ value, label }) => {
    change({ ...data, id: value, name: label })
  }
  return (
    <Group>
      <Courses onChange={handleChange} selected={data} />
    </Group>
  )
}

export default TriggerTypeCourse
