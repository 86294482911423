import React from 'react'
import Tags from 'workflow/components/Tags'
import Group from 'shared/components/Group'

const ActionTypeAddRemoveTag = ({ change, data }) => {
  const handleChange = ({ value, label }) => {
    change({ ...data, id: value, name: label })
  }

  return (
    <Group data-testid="workflow-step-action-tag">
      <Tags onChange={handleChange} selected={data} />
    </Group>
  )
}

export default ActionTypeAddRemoveTag
