import styled from 'styled-components/macro'

const CircleUi = styled.circle`
  cursor: pointer;
  transition: all 0.3s ease;
  stroke: #ccc;
  fill: #cccccc;
  &:hover {
    fill: #24aef2;
    stroke: #24aef2;
  }
`

export default CircleUi
