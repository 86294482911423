import { produce } from 'immer'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import ReactTooltip from 'react-tooltip'
import Button from 'shared/components/Button'
import FormErrors from 'shared/components/FormErrors'
import Group from 'shared/components/Group'
import ConditionType from 'workflow/components/Step/types/Condition/ConditionType'
import ConditionRemove from './ConditionRemove'
import { conditionTypes, conditionTypesLabels } from './conditionTypes'
import { conditionFields } from './conditionFields'
import { compareOperations } from './compareOperations'
import ConditionAddGroupUi from './ui/ConditionAddGroupUi'
import ConditionGroupUi from './ui/ConditionGroupUi'
import ConditionSeparatorUi from './ui/ConditionSeparatorUi'
import ConditionUi from './ui/ConditionUi'
import ConditionSelectWrapperUi from './ui/ConditionSelectWrapperUi'
import WarningIcon from 'shared/icons/warning-icon'

const options = Object.values(conditionTypes).map(stepValue => ({
  value: stepValue,
  label: conditionTypesLabels[stepValue],
}))

export const defaultConditionData = {
  conditionType: '',
  searchField: '',
  compareOperation: '',
  searchValue: '',
}

const ConditionForm = ({
  stepData,
  isLoading,
  errors,
  handleSubmit,
  submitText,
}) => {
  const { t } = useTranslation()
  const [data, setData] = useState(stepData)

  const handleChangeType = (groupIndex, modelIndex) => option => {
    const nextData = produce(data, draftData => {
      const model = (draftData.conditionGroups[
        groupIndex
      ].contactFilterConditionModels[modelIndex] = {})

      model[conditionFields.conditionType] = option.value

      if (option.value !== conditionTypes.contactFields) {
        model[conditionFields.searchField] = option.value
      }

      if (option.value === conditionTypes.tag) {
        model[conditionFields.compareOperation] = compareOperations.inList
      }

      if (option.value === conditionTypes.createdAt) {
        model[conditionFields.compareOperation] = compareOperations.greaterThan
        model[conditionFields.searchValue] = moment().format('D/M/YYYY')
      }

      if (option.value === conditionTypes.lastActivity) {
        model[conditionFields.compareOperation] = compareOperations.lessThan
        model[conditionFields.searchValue] = moment().format('D/M/YYYY')
      }

      if (option.value === conditionTypes.mailing) {
        model[conditionFields.compareOperation] = compareOperations.equals
      }
    })
    setData(nextData)
  }

  const handleChange = (groupIndex, modelIndex) => model => {
    const nextData = produce(data, draftData => {
      draftData.conditionGroups[groupIndex].contactFilterConditionModels[
        modelIndex
      ] = model
    })
    setData(nextData)
  }

  const handleButtonClick = () => {
    handleSubmit(data)
  }

  const addNewCondition = groupIndex => () => {
    const nextData = produce(data, draft => {
      draft.conditionGroups[groupIndex].contactFilterConditionModels.push(
        defaultConditionData,
      )
    })
    setData(nextData)
  }

  const handleRemoveCondition = (groupIndex, modelIndex) => () => {
    let nextData
    if (groupIndex > 0 && modelIndex === 0) {
      nextData = produce(data, draft => {
        draft.conditionGroups.splice(groupIndex, 1)
      })
    } else {
      nextData = produce(data, draft => {
        draft.conditionGroups[groupIndex].contactFilterConditionModels.splice(
          modelIndex,
          1,
        )
      })
    }
    setData(nextData)
  }

  const addNewConditionsGroup = () => {
    const nextData = produce(data, draft => {
      draft.conditionGroups.push({
        contactFilterConditionModels: [defaultConditionData],
      })
    })
    setData(nextData)
  }
  const { conditionGroups } = data

  return (
    <>
      {conditionGroups.map((group, groupIndex) => (
        <ConditionGroupUi
          key={`condition-group-${groupIndex}`}
          data-aftertext={t('workflow.steps.types.condition.group_or')}
        >
          {group.contactFilterConditionModels.map((model, index, arr) => {
            const notFirst = index > 0
            const notLastAndLengthGreaterOne =
              arr.length > 1 && index !== arr.length - 1
            return (
              <ConditionUi
                showAnd={notLastAndLengthGreaterOne}
                data-aftertext={t('workflow.steps.types.condition.and')}
                key={`condition-${groupIndex}-${index}`}
              >
                <ConditionSelectWrapperUi data-testid="workflow-step-condition-type">
                  <Select
                    placeholder={t(
                      'workflow.steps.types.condition.types.placeholder',
                    )}
                    value={options.find(
                      opt => opt.value === model.conditionType,
                    )}
                    options={options}
                    formatOptionLabel={({ label }) => t(label)}
                    onChange={handleChangeType(groupIndex, index)}
                    styles={{
                      container: (provided, state) => ({
                        ...provided,
                        flexBasis: 200,
                      }),
                    }}
                  />
                  {(conditionTypes.mailing === model.conditionType ||
                    conditionTypes.emailOpened === model.conditionType) && (
                    <>
                      <WarningIcon
                        data-tip
                        data-for="registerTip"
                        width={20}
                        fill={'red'}
                        stroke={'red'}
                        strokeWidth={30}
                      />
                      <ReactTooltip id="registerTip" place="top" effect="solid">
                        {t(
                          'workflow.steps.types.condition.type.mailing.delay_warning',
                        )}
                      </ReactTooltip>
                    </>
                  )}
                </ConditionSelectWrapperUi>
                {model.conditionType && (
                  <ConditionType
                    model={model}
                    change={handleChange(groupIndex, index)}
                    type={model.conditionType}
                  />
                )}
                {(arr.length > 1 || groupIndex !== 0) && (
                  <ConditionRemove
                    onClick={handleRemoveCondition(groupIndex, index)}
                  />
                )}
                {notFirst && <ConditionSeparatorUi />}
              </ConditionUi>
            )
          })}
          <Group styles={{ clear: 'left', marginTop: '15px' }}>
            <Button onClick={addNewCondition(groupIndex)}>
              {t('workflow.steps.condition.add.label')}
            </Button>
          </Group>
        </ConditionGroupUi>
      ))}
      <ConditionAddGroupUi>
        <Button onClick={addNewConditionsGroup}>
          {t('workflow.steps.condition.add_group.label')}
        </Button>
      </ConditionAddGroupUi>
      {errors.length > 0 && (
        <Group>
          <FormErrors errors={errors} />
        </Group>
      )}
      <Group styles={{ clear: 'left' }}>
        <Button onClick={handleButtonClick} disabled={isLoading} success>
          {submitText}
        </Button>
      </Group>
    </>
  )
}

ConditionForm.defaultProps = {
  errors: [],
}

export default ConditionForm
