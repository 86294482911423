import Loader from '../Loader'
import ButtonUi from './ui/ButtonUi'
import React from 'react'

export default function Button({ children, isLoading, ...props }) {
  return (
    <ButtonUi {...props}>
      {children}
      {isLoading && <Loader />}
    </ButtonUi>
  )
}
