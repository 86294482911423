import { ModalContext } from 'modal/context'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  addFalseStep,
  addStep,
  addTrueStep,
  removeFalseStep,
  removeStep,
  removeTrueStep,
} from 'workflow/actions'
import Path from 'workflow/components/Step/shared/Path'
import PathLabel from 'workflow/components/Step/shared/PathLabel'
import { stepChildTypes } from 'workflow/components/Step/stepChildTypes'
import StepCreate from 'workflow/components/Step/StepCreate'
import ConditionEdit from 'workflow/components/Step/types/Condition/ConditionEdit'
import Steps from 'workflow/components/Steps'
import useHeight from 'workflow/hooks/useHeight'
import useHover from 'workflow/hooks/useHover'
import useWidth from 'workflow/hooks/useWidth'
import Group from '../../shared/Group'
import Circle, { height as circleHeight } from '../../shared/Circle'
import Line, { height as lineHeight } from '../../shared/Line'
import Rect, { height as rectHeight } from '../../shared/Rect'
import Remove, { radius as removeDimension } from '../../shared/Remove'
import Text, { padding as textPadding } from '../../shared/Text'
import { conditionTypes } from './conditionTypes'
import generateName from './conditionNameGenerator'
import QuestionMarkIcon from 'shared/icons/question-mark-icon'
import { svgSize } from 'workflow/components/Step/shared/Svg'
import { WorkflowAreaSizeContext } from 'workflow/components/WorkflowAreaSize'
import { connect } from 'react-redux'
import useConditionNameData from './useConditionaNameData'
import * as api from 'workflow/api'

const conditionPathHeight = 90

const getConditionPathWidth = level => 226 / level

const Condition = ({
  step,
  centerX,
  y,
  onRemove,
  level,
  addStep,
  heightSubscriber,
  addTrueStep,
  addFalseStep,
  addPathStep,
  removeStep,
  removeTrueStep,
  removeFalseStep,
}) => {
  const { t } = useTranslation()

  const { tags, mailings, contactFields } = useConditionNameData(
    step?.conditionGroups,
  )
  const getModelFieldName = (conditionType, model) => {
    switch (conditionType) {
      case conditionTypes.contactFields:
        const field =
          model.searchField &&
          contactFields?.find(
            contactField => contactField.id == model.searchField,
          )
        return field?.fieldName ?? ''
      case conditionTypes.tag:
        const tag =
          model.searchValue && tags?.find(tag => tag.id === model.searchValue)
        return tag?.name ?? ''
      case conditionTypes.mailing:
      case conditionTypes.emailOpened:
        const mailing = mailings.find(
          mailing => mailing.id == model.searchValue,
        )
        return mailing ? mailing.subject : ''
      default:
        return ''
    }
  }
  const name = generateName(step, getModelFieldName, t)
  const { showModal } = useContext(ModalContext)
  const { incrementElements, reduceElements } = useContext(
    WorkflowAreaSizeContext,
  )
  const [ref, textWidth] = useWidth(name)
  const [conditionRef, height] = useHeight(heightSubscriber)
  const [hoverRef, isHovered] = useHover(false)
  const [leftStepsHeight, setLeftStepsHeight] = useState(0)
  const [rightStepsHeight, setRightStepsHeight] = useState(0)

  const resultWidth = textWidth + textPadding * 2 + svgSize
  const levelCoefficient = level - 1 > 0 ? level - 1 : 1
  const maxWidth = (226 * 2) / levelCoefficient - removeDimension
  const width = resultWidth > maxWidth ? maxWidth : resultWidth

  let calculatedName = name
  if (textWidth > width) {
    const ellipsisValue = resultWidth / width
    calculatedName =
      name.slice(0, Math.floor((name.length / ellipsisValue) * 0.8)) + '...'
  }

  const addNewStep = async (parentStepId, step, childType = null) => {
    const result = await api.addStep(step, parentStepId, childType)
    addPathStep({ ...step, ...result })
  }

  const handleAddTrueStep = conditionId => index => async (
    parentStepId,
    step,
    childType,
  ) => {
    const stepId = await addNewStep(parentStepId, step, childType)
    addTrueStep(conditionId, stepId, index)
    incrementElements()
  }

  const handleAddFalseStep = conditionId => index => async (
    parentStepId,
    step,
    childType,
  ) => {
    const stepId = await addNewStep(parentStepId, step, childType)
    addFalseStep(conditionId, stepId, index)
    incrementElements()
  }

  const handleRemoveFalseStep = conditionId => stepId => {
    removeStep(stepId)
    removeFalseStep(conditionId, stepId)
    reduceElements()
  }

  const handleRemoveTrueStep = conditionId => stepId => {
    removeStep(stepId)
    removeTrueStep(conditionId, stepId)
    reduceElements()
  }

  const rectX = centerX - width / 2
  const rectY = y + lineHeight

  const gap = 5

  const iconX = centerX - width / 2 + textPadding
  const iconY = y + lineHeight / 2 + rectHeight / 2

  const textX = centerX - width / 2 + textPadding + svgSize + gap
  const textY = y + lineHeight + rectHeight / 2

  const removeX = centerX + width / 2 - removeDimension
  const removeY = y + lineHeight - removeDimension

  const leftPathX2 = centerX - getConditionPathWidth(level)
  const rightPathX2 = centerX + getConditionPathWidth(level)

  const pathTopY1 = y + lineHeight + rectHeight
  const pathTopY2 = y + lineHeight + rectHeight + conditionPathHeight

  const leftPathLabelX = centerX + (leftPathX2 - centerX) / 2
  const rightPathLabelX = centerX - (leftPathX2 - centerX) / 2
  const pathLabelY = pathTopY1 + (pathTopY2 - pathTopY1) / 2

  const stepsStartY =
    y + lineHeight + rectHeight + conditionPathHeight + circleHeight

  const leftDownPathY1 = stepsStartY + leftStepsHeight
  const rightDownPathY1 = stepsStartY + rightStepsHeight

  const downY =
    stepsStartY +
    conditionPathHeight +
    (rightStepsHeight > leftStepsHeight ? rightStepsHeight : leftStepsHeight)

  return (
    <g
      className="step-condition"
      data-id={step.id}
      data-height={height}
      ref={conditionRef}
    >
      <Line startX={centerX} startY={y} />
      <Group
        ref={hoverRef}
        onClick={() => {
          showModal(ConditionEdit, { step })
        }}
        visibility={textWidth > 0 ? 'visible' : 'hidden'}
      >
        <Rect
          x={rectX}
          y={rectY}
          width={width + gap}
          fill="#142d63"
          hoverFill="#3f669f"
        />
        <QuestionMarkIcon
          x={iconX}
          y={iconY}
          width={svgSize}
          height={svgSize}
          fill={'#fff'}
        />
        <Text
          x={textX}
          y={textY}
          ref={ref}
          name={calculatedName}
          fill="#fff"
          data-testid="condition-name"
        />
        <title>{name}</title>
        <Remove
          x={removeX}
          y={removeY}
          visibility={isHovered}
          onClick={onRemove}
          data-testid="condition-remove-button"
        />
      </Group>
      <Path x1={centerX} x2={leftPathX2} y1={pathTopY1} y2={pathTopY2} />
      <PathLabel
        x={leftPathLabelX}
        y={pathLabelY}
        name={t('workflow.steps.condition.true_path_label')}
      />
      <Circle
        x={leftPathX2}
        y={pathTopY2}
        onClick={() =>
          showModal(StepCreate, {
            parentStepId: step.id,
            childType: stepChildTypes.first,
            addStep: handleAddTrueStep(step.id)(0),
          })
        }
      />
      <Path x1={centerX} x2={rightPathX2} y1={pathTopY1} y2={pathTopY2} />
      <PathLabel
        x={rightPathLabelX}
        y={pathLabelY}
        name={t('workflow.steps.condition.false_path_label')}
      />
      <Circle
        x={rightPathX2}
        y={pathTopY2}
        onClick={() =>
          showModal(StepCreate, {
            parentStepId: step.id,
            childType: stepChildTypes.second,
            addStep: handleAddFalseStep(step.id)(0),
          })
        }
      />
      {step.truePath && (
        <Steps
          heightSubscriber={setLeftStepsHeight}
          ids={step.truePath}
          centerX={leftPathX2}
          startY={stepsStartY}
          level={level}
          removeStep={handleRemoveTrueStep(step.id)}
          createAddStep={handleAddTrueStep(step.id)}
        />
      )}
      {step.falsePath && (
        <Steps
          heightSubscriber={setRightStepsHeight}
          ids={step.falsePath}
          centerX={rightPathX2}
          startY={stepsStartY}
          level={level}
          removeStep={handleRemoveFalseStep(step.id)}
          createAddStep={handleAddFalseStep(step.id)}
        />
      )}
      <Path x1={leftPathX2} x2={centerX} y1={leftDownPathY1} y2={downY} />
      <Path x1={rightPathX2} x2={centerX} y1={rightDownPathY1} y2={downY} />
      <Circle
        x={centerX}
        y={downY}
        onClick={() =>
          showModal(StepCreate, {
            parentStepId: step.id,
            childType: stepChildTypes.child,
            addStep: addNewStep,
          })
        }
      />
    </g>
  )
}

const mapDispatchToProps = {
  addTrueStep,
  addFalseStep,
  addPathStep: addStep,
  removeStep,
  removeTrueStep,
  removeFalseStep,
}

export default connect(null, mapDispatchToProps)(Condition)
