import React from 'react'
import { useTranslation } from 'react-i18next'
import { stepTypes } from 'workflow/components/Step/stepTypes'
import useFormSubmit from 'workflow/hooks/useFormSubmit'
import { Modal } from 'modal/components'
import DelayForm from './DelayForm'
import { delayUnitTypes } from 'workflow/components/Step/types/Delay/delayTypes'

const DelayCreate = ({
  handleClose,
  parentStepId,
  addStep,
  step,
  ...restProps
}) => {
  const handleAddStep = async data => {
    await addStep(parentStepId, { ...step, data }, restProps.childType)
  }

  const [isLoading, errors, handleSubmit] = useFormSubmit(
    handleAddStep,
    handleClose,
  )
  const [t] = useTranslation()

  return (
    <Modal
      onRequestClose={handleClose}
      header={t('workflow.steps.delay.modal.create.header.title')}
      data-testid="modal-delay-create"
    >
      <DelayForm
        submitText={t('workflow.steps.modal.create.submit.label')}
        handleSubmit={handleSubmit}
        stepData={step}
        isLoading={isLoading}
        errors={errors}
      />
    </Modal>
  )
}

DelayCreate.defaultProps = {
  step: {
    data: {
      duration: 1,
      unit: delayUnitTypes.hour,
    },
    objectType: stepTypes.delay,
  },
}

export default DelayCreate
