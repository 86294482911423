import React, { forwardRef } from 'react'
import { DropdownUi } from './ui/dropdownUi'
import DropdownOption from './dropdownOption'
import { useTranslation } from 'react-i18next'
import { NoOptionsUi } from './ui/no-options-ui'
import Loader from '../Loader'

const Dropdown = forwardRef(function Dropdown(
  { isOpen, options, selected, onSelect, isLoading, ...restProps },
  ref,
) {
  const { t } = useTranslation()

  const isEmpty = !options || options.length === 0

  return (
    isOpen && (
      <DropdownUi
        role="listbox"
        aria-expanded={isOpen}
        isOpen={isOpen}
        className="customScroll"
        ref={ref}
        {...restProps}
      >
        {isEmpty ? (
          <NoOptionsUi>
            {isLoading ? <Loader /> : t('workflow.selectors.no_options')}
          </NoOptionsUi>
        ) : (
          options.map(option => (
            <DropdownOption
              key={option.value}
              option={option}
              selected={selected?.value === option.value}
              onSelect={onSelect}
            />
          ))
        )}
      </DropdownUi>
    )
  )
})

export default Dropdown
