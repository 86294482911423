import React from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import HorizontalGroup from 'shared/components/HorizontalGroup'
import { conditionFields } from 'workflow/components/Step/types/Condition/conditionFields'
import { contactStates, contactStatesLabels } from '../contactStateTypes'

const contactStatesOptions = Object.values(contactStates).map(value => ({
  value,
  label: contactStatesLabels[value],
}))

const ConditionTypeContactState = ({ change, model }) => {
  const { t } = useTranslation()

  const handleChange = type => option => {
    change({ ...model, [type]: option.value })
  }

  return (
    <HorizontalGroup
      styles={{
        gridTemplateColumns: '70%',
      }}
    >
      <div data-testid="workflow-step-condition-operators">
        <Select
          placeholder={t(
            'workflow.steps.types.condition.contact_state.compare.placeholder',
          )}
          value={contactStatesOptions.find(
            opt => opt.value === model.searchValue,
          )}
          options={contactStatesOptions}
          formatOptionLabel={({ label }) => t(label)}
          onChange={handleChange(conditionFields.searchValue)}
        />
      </div>
    </HorizontalGroup>
  )
}

export default ConditionTypeContactState
