import React from 'react'

function CampaignUnsubscribeIcon(props) {
  return (
    <svg
      id="Capa_1"
      enableBackground="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      fill={'#CBD5E1'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path d="m305.617 219.523-15.899-15.631-33.724 22.375-30.764-20.132-183.555 180.456 194.225.117c-2.664-11.357-4.078-23.191-4.078-35.349.001-55.673 29.555-104.567 73.795-131.836z" />
        <path d="m333.964 205.721c16.358-5.898 33.983-9.121 52.349-9.121 41.351 0 80.177 16.121 109.326 45.394 5.976 6.002 11.391 12.417 16.238 19.171l.123-204.927-197.083 130.757z" />
        <path d="m.196 58.92-.196 326.637 199.549-196.18z" />
        <path d="m489.696 35.165-471.371-.283 237.682 152.555z" />
        <path d="m386.582 225.601c-69.344 0-125.759 56.415-125.759 125.759s56.415 125.759 125.759 125.759c33.583 0 65.11-13.089 88.777-36.856 23.628-23.73 36.641-55.303 36.641-88.904 0-33.6-13.013-65.173-36.641-88.902-23.667-23.767-55.195-36.856-88.777-36.856zm53.931 140.748h-107.864v-29.98h107.865v29.98z" />
      </g>
    </svg>
  )
}

export default CampaignUnsubscribeIcon
