import React from 'react'

function CourseCompletedIcon(props) {
  return (
    <svg
      width="112"
      height="112"
      viewBox="0 0 112 112"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M47.3023 41.7056L28.0718 34.9731C28.0718 41.1191 28.0188 41.0759 28.213 41.6566C28.4798 42.4883 29.1115 43.1808 29.957 43.5045C50.9118 51.5651 49.4385 51.0884 50.2193 51.0884C51.0001 51.0884 49.5033 51.5729 70.4797 43.5045C71.6037 43.0729 72.3668 41.994 72.3668 40.7581V35.2948L53.0108 41.7488C51.1531 42.3687 49.1443 42.353 47.3003 41.7056H47.3023Z" />
      <path d="M84.188 43.7144C84.188 46.3274 81.01 47.6397 79.166 45.7958C78.6325 45.2641 78.3029 44.5265 78.3029 43.7144V27.1145C69.385 30.0865 62.2759 32.4562 51.1491 36.1658C50.5273 36.3718 49.8603 36.3679 49.2463 36.1501C17.3099 24.9724 19.6797 25.9022 18.9715 25.4334C17.0176 24.0366 17.4629 21.0353 19.7012 20.2525C49.7073 9.75357 49.4954 9.74768 50.2193 9.74768C50.902 9.74768 50.7607 9.76534 80.1881 19.5758C79.9605 20.692 79.8409 21.8474 79.8409 23.0303C79.8409 27.4068 81.4828 31.3989 84.186 34.4258C84.188 36.925 84.188 39.9793 84.188 43.7144Z" />
      <path d="M81.9204 23.9131C81.9204 23.3265 81.9204 22.738 81.9204 22.1515C81.9381 22.0475 81.9596 21.9435 81.9753 21.8395C82.0695 21.1922 82.1225 20.535 82.2617 19.8975C83.0464 16.286 84.9022 13.3258 87.8447 11.0993C91.5053 8.32741 95.6268 7.40737 100.121 8.34506C103.719 9.09443 106.644 10.9796 108.88 13.8888C111.295 17.0275 112.301 20.6037 111.923 24.5388C111.617 27.7266 110.379 30.5416 108.254 32.9447C105.926 35.5792 103.022 37.2192 99.556 37.8372C98.9891 37.9372 98.4163 37.9941 97.8454 38.0706H96.0838C96.0073 38.0549 95.9328 38.0274 95.8563 38.0216C93.5042 37.8548 91.3032 37.1819 89.2944 35.952C85.3985 33.5646 82.9994 30.1218 82.1578 25.6197C82.0519 25.0567 82.0008 24.4819 81.9243 23.9131H81.9204ZM94.693 25.4648C94.5635 25.3431 94.4929 25.2784 94.4242 25.2097C93.4473 24.2348 92.4724 23.2579 91.4954 22.2849C91.3777 22.1671 91.2561 22.0534 91.1266 21.9494C90.2635 21.2491 88.9335 21.3825 88.2214 22.2378C87.4622 23.15 87.5387 24.3329 88.4234 25.2215C89.9437 26.7457 91.468 28.268 92.9903 29.7883C94.0241 30.8221 95.2599 30.8221 96.2957 29.7883C99.3422 26.7438 102.387 23.6992 105.429 20.6547C105.561 20.5233 105.686 20.3859 105.8 20.2388C106.657 19.1206 106.106 17.4002 104.76 16.9804C103.862 16.7019 103.113 16.9569 102.463 17.6082C99.9386 20.1368 97.4119 22.6595 94.8872 25.1882C94.8127 25.2627 94.7616 25.3647 94.693 25.4667V25.4648Z" />
      <path d="M96.9586 40.148C94.5104 40.148 92.18 39.634 90.0751 38.7062V43.7144C90.0751 48.5833 86.1144 52.5439 81.2455 52.5439C78.1127 52.5439 75.3545 50.904 73.7871 48.4362C72.9769 48.8972 74.3227 48.3361 53.3914 56.3869C51.3846 57.1598 49.1286 57.1873 47.0493 56.3869L27.8443 48.9992C24.4329 47.6868 22.1848 44.4167 22.1848 40.7581V32.9172L17.7553 31.3635C14.2184 30.1277 11.8427 26.7791 11.8427 23.0303H2.9445C1.32022 23.0303 0 24.3505 0 25.9748V89.514C0 91.1403 1.32022 92.4585 2.9445 92.4585H29.549V98.3887H20.6723C19.048 98.3887 17.7278 99.7089 17.7278 101.333C17.7278 102.957 19.048 104.276 20.6723 104.276H79.7683C81.3926 104.276 82.7109 102.959 82.7109 101.333C82.7109 99.707 81.3926 98.3887 79.7683 98.3887H70.8917V92.4585H97.4961C99.1204 92.4585 100.439 91.1403 100.439 89.514V39.7949C99.3146 40.0264 98.1514 40.148 96.9586 40.148ZM19.2109 83.5818C14.3223 83.5818 10.3577 79.6173 10.3577 74.7287C10.3577 69.8402 14.3223 65.8756 19.2109 65.8756C24.0994 65.8756 28.064 69.8402 28.064 74.7287C28.064 79.6173 24.1014 83.5818 19.2109 83.5818ZM85.6789 83.5818H38.4001C36.7759 83.5818 35.4576 82.2636 35.4576 80.6373C35.4576 79.0111 36.7759 77.6948 38.4001 77.6948H85.6789C87.3032 77.6948 88.6214 79.0131 88.6214 80.6373C88.6214 82.2616 87.3032 83.5818 85.6789 83.5818ZM85.6789 71.7627H38.4001C36.7759 71.7627 35.4576 70.4444 35.4576 68.8201C35.4576 67.1958 36.7759 65.8756 38.4001 65.8756H85.6789C87.3032 65.8756 88.6214 67.1939 88.6214 68.8201C88.6214 70.4464 87.3032 71.7627 85.6789 71.7627Z" />
      <path d="M58.0916 72.6376V76.8179C58.0916 77.4712 58.7781 77.8949 59.3608 77.6026L63.5411 75.5114C64.1885 75.1878 64.1885 74.2658 63.5411 73.9421L59.3608 71.8509C58.7781 71.5586 58.0916 71.9843 58.0916 72.6356V72.6376Z" />
      <path d="M16.5823 72.6376V76.8179C16.5823 77.4712 17.2689 77.8949 17.8515 77.6026L22.0319 75.5114C22.6792 75.1878 22.6792 74.2658 22.0319 73.9421L17.8515 71.8509C17.2689 71.5586 16.5823 71.9843 16.5823 72.6356V72.6376Z" />
    </svg>
  )
}

export default CourseCompletedIcon
