import styled from 'styled-components/macro'

const CircleUi = styled.circle`
  cursor: pointer;
  fill: #ff0000;
  stroke-width: 1px;
  stroke: #ff0000;
  &:hover {
    fill: #f2231e;
    stroke: #f2231e;
    stroke-width: 2px;
  }
`

export default CircleUi
