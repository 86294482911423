import { animatePulse } from 'shared/styles/animatePulse'
import styled from 'styled-components'

const HeaderElementSkeleton = styled.div`
  min-height: 1.25rem;
  min-width: 4rem;
  background-color: rgb(155, 169, 179);
  border-radius: 0.25rem;
  margin-left: 1rem;

  ${({ width }) => `width: ${width}`}

  ${animatePulse}
`

export default HeaderElementSkeleton
