import React from 'react'
import InputRadioUi from './ui/InputRadioUi'
import InputRadioLabelUi from './ui/InputRadioLabelUi'

const InputRadio = ({ checked, onChange, label }) => {
  return (
    <InputRadioLabelUi>
      <InputRadioUi type="radio" checked={checked} onChange={onChange} />
      {label}
    </InputRadioLabelUi>
  )
}

export default InputRadio
