import { actionTypes } from '../actionTypes'
import i18next from 'i18next'

export default function validateMapping(data) {
  if (data.type !== actionTypes.addRowToGoogleSheet) {
    return { isValid: true }
  }

  if (data.mapping?.mapping?.filter(map => map.value?.length > 0).length == 0) {
    return {
      errors: [
        i18next.t(
          'workflow.steps.action.form.validation.empty_add_row_to_google_sheet',
        ),
      ],
      isValid: false,
    }
  }

  return { isValid: true }
}
