import React from 'react'

function WebinarIcon(props) {
  return (
    <svg
      width="45"
      height="38"
      viewBox="0 0 45 38"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={'none'}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8475 37.4094C30.4303 37.4094 30.0551 37.1763 29.8683 36.801L28.4211 33.8877H15.7716L14.3235 36.801C14.1367 37.1763 13.7612 37.4094 13.3434 37.4094C13.1745 37.4094 13.0058 37.3693 12.8556 37.2933C12.3162 37.0223 12.0966 36.3597 12.366 35.8164L15.075 30.3669H1.0935C0.49054 30.3669 0 29.8735 0 29.2671C0 28.6602 0.49054 28.1664 1.0935 28.1664H1.7496V2.2005H1.0935C0.49054 2.2005 0 1.70673 0 1.0998C0 0.49337 0.49054 0 1.0935 0H43.0983C43.7013 0 44.1918 0.49337 44.1918 1.0998C44.1918 1.70673 43.7013 2.2005 43.0983 2.2005H42.4422V28.1664H43.0983C43.7018 28.1664 44.1927 28.6602 44.1927 29.2671C44.1927 29.8735 43.7018 30.3669 43.0983 30.3669H29.1168L31.8249 35.8164C32.0951 36.3596 31.8759 37.0221 31.3362 37.2933C31.1843 37.3693 31.0153 37.4094 30.8475 37.4094ZM17.5212 30.3678V30.3678L16.8651 31.6881H27.3267L26.6697 30.3678L17.5212 30.3678L17.5212 30.3678ZM22.5441 6.759C18.4018 6.759 15.0318 10.1484 15.0318 14.3145C15.0318 18.4811 18.4018 21.8709 22.5441 21.8709C26.6859 21.8709 30.0555 18.4811 30.0555 14.3145C30.0555 10.1484 26.6859 6.759 22.5441 6.759ZM20.3662 18.216C19.9637 18.2159 19.6362 17.8873 19.6362 17.4834V11.1465C19.6362 10.7425 19.9636 10.4139 20.3661 10.4139C20.4866 10.4139 20.6079 10.446 20.7171 10.5066L26.0487 13.7664C26.2826 13.8982 26.4219 14.1383 26.4213 14.4089C26.4207 14.6781 26.2815 14.9163 26.0487 15.0462L20.7171 18.1233C20.6075 18.1848 20.4895 18.216 20.3662 18.216Z"
      />
    </svg>
  )
}

export default WebinarIcon
