import React from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import Input from 'shared/components/Input'
import HorizontalGroup from 'shared/components/HorizontalGroup'
import ContactFields from 'workflow/components/Step/types/Condition/ContactFields'
import CountriesSelect from 'workflow/components/Step/types/Condition/CountriesSelect'
import { conditionFields } from '../conditionFields'
import { compareOperations } from '../compareOperations'
import { getCompareOperationsOptionsByType } from '../compareOperations'
import useCountryFieldId from 'workflow/hooks/useCountryFieldId'

const ConditionTypeContactFields = ({ change, model }) => {
  const { t } = useTranslation()

  const countryFieldId = useCountryFieldId()

  const isCountryField = countryFieldId === Number(model.searchField)

  const handleChange = type => option => {
    change({ ...model, [type]: option.value })
  }

  const handleChangeCountry = option => {
    change({
      ...model,
      [conditionFields.compareOperation]: compareOperations.equals,
      [conditionFields.searchValue]: option.value,
    })
  }

  return (
    <HorizontalGroup
      styles={{
        gridTemplateColumns: isCountryField ? '40% 52%' : '30% 30% 28%',
      }}
    >
      <div data-testid="workflow-step-condition-contact-fields">
        <ContactFields
          onChange={handleChange(conditionFields.searchField)}
          selected={Number(model.searchField)}
        />
      </div>
      {isCountryField ? (
        <div data-testid="workflow-step-condition-countries">
          <CountriesSelect
            selected={model.searchValue}
            onChange={handleChangeCountry}
          />
        </div>
      ) : (
        <>
          <div data-testid="workflow-step-condition-operators">
            <Select
              placeholder={t(
                'workflow.steps.types.condition.operations.placeholder',
              )}
              value={getCompareOperationsOptionsByType('text').find(
                opt => opt.value === model.compareOperation,
              )}
              options={getCompareOperationsOptionsByType('text')}
              formatOptionLabel={({ label }) => t(label)}
              onChange={handleChange(conditionFields.compareOperation)}
            />
          </div>
          <div data-testid="workflow-step-condition-value">
            <Input
              value={model.searchValue}
              placeholder={t(
                'workflow.steps.types.condition.search_value.placeholder',
              )}
              onChange={e =>
                handleChange(conditionFields.searchValue)(e.target)
              }
            />
          </div>
        </>
      )}
    </HorizontalGroup>
  )
}

export default ConditionTypeContactFields
