import styled from 'styled-components/macro'

export const ConditionRemoveLinkUi = styled.a`
  position: absolute;
  display: block;
  cursor: pointer;
  height: 24px;
  width: 24px;
  padding: 5px;
  top: 25px;
  right: -5px;
`

export const ConditionRemoveIconUi = styled.i`
  font-size: 30px;
  font-style: normal;
  line-height: 16px;
  text-align: center;
  color: #000000;
  display: block;
  transition: color 150ms;
  ${ConditionRemoveLinkUi}:hover & {
    color: red;
  }
`
