import useSelectOptionsData from './useSelectOptionsData'
import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'

export default function useCountryFieldId() {
  const { options: contactFields } = useSelectOptionsData({
    dataType: workflowDataTypes.contactFields,
    selector: field => field,
    fetchImmediately: true,
  })

  const countryField = contactFields.find(field => field.slug === 'country')

  return countryField ? countryField.id : null
}
