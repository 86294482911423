export const conditionTypes = {
  contactFields: 'contact_fields',
  tag: 'tag',
  mailing: 'mailing',
  emailOpened: 'email_opened',
  createdAt: 'created_at',
  lastActivity: 'last_activity_date',
  contactState: 'state',
}

export const conditionTypesLabels = {
  [conditionTypes.contactFields]:
    'workflow.steps.types.condition.types.labels.contact_fields',
  [conditionTypes.tag]: 'workflow.steps.types.condition.types.labels.tag',
  [conditionTypes.mailing]:
    'workflow.steps.types.condition.types.labels.mailing',
  [conditionTypes.createdAt]:
    'workflow.steps.types.condition.types.labels.created_at',
  [conditionTypes.lastActivity]:
    'workflow.steps.types.condition.types.labels.last_activity',
  [conditionTypes.contactState]:
    'workflow.steps.types.condition.types.labels.contact_state',
  [conditionTypes.emailOpened]:
    'workflow.steps.types.condition.types.labels.email_opened',
}

export const detectConditionType = searchField => {
  if (Number.isInteger(Number(searchField))) {
    return conditionTypes.contactFields
  }

  return searchField
}
