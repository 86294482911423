import styled from 'styled-components'

const SelectOptionIconUi = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    width: 35px;
    height: 35px;
    fill: #cbd5e1;
  }
`

export default SelectOptionIconUi
