import React from 'react'
import { useTranslation } from 'react-i18next'
import useFormSubmit from 'workflow/hooks/useFormSubmit'
import { Modal } from 'modal/components'
import TriggerForm from './TriggerForm'
import { connect } from 'react-redux'
import { addTrigger } from 'workflow/actions'

const TriggerCreate = ({ handleClose, trigger, addTrigger, ...restProps }) => {
  const handleAddTrigger = data => addTrigger({ ...trigger, data })

  const [isLoading, errors, handleSubmit] = useFormSubmit(
    handleAddTrigger,
    handleClose,
  )
  const [t] = useTranslation()

  return (
    <Modal
      onRequestClose={handleClose}
      header={t('workflow.steps.trigger.modal.create.header.title')}
      {...restProps}
    >
      <TriggerForm
        handleSubmit={handleSubmit}
        submitText={t('workflow.steps.trigger.modal.create.submit.label')}
        isLoading={isLoading}
        errors={errors}
        stepData={trigger}
      />
    </Modal>
  )
}

TriggerCreate.defaultProps = {
  trigger: {},
}

export default connect(null, { addTrigger })(TriggerCreate)
