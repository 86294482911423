import React from 'react'
import CourseBundles from 'workflow/components/CourseBundles'
import Group from 'shared/components/Group'
import { useTranslation } from 'react-i18next'
import InputRadio from 'shared/components/InputRadio'

export const enrollmentAccessTypes = {
  fullAccess: 'full_access',
  drippingContent: 'dripping_content',
}

const ActionTypeEnrollInCourseBundle = ({ change, data }) => {
  const { t } = useTranslation()

  const handleChangeCourseBundle = ({ value, label }) => {
    change({ ...data, id: value, name: label })
  }

  const handleChangeAccessType = value => e => {
    const enrollmentAccessType = e.target.checked ? value : e.target.value
    change({ ...data, enrollmentAccessType })
  }

  return (
    <>
      <Group>
        <CourseBundles onChange={handleChangeCourseBundle} selected={data} />
      </Group>
      <InputRadio
        onChange={handleChangeAccessType(enrollmentAccessTypes.fullAccess)}
        checked={
          data?.enrollmentAccessType === enrollmentAccessTypes.fullAccess
        }
        label={t('workflow.steps.enrollments.access_type.full_access')}
      />
      <InputRadio
        onChange={handleChangeAccessType(enrollmentAccessTypes.drippingContent)}
        checked={
          data?.enrollmentAccessType === enrollmentAccessTypes.drippingContent
        }
        label={t('workflow.steps.enrollments.access_type.drip_content')}
      />
    </>
  )
}

export default ActionTypeEnrollInCourseBundle
