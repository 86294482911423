import React from 'react'
import RectUi from './ui/RectUi'

export const height = 40

const Rect = ({ x, y, width, fill, ...restProps }) => {
  return (
    <>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        filter="url(#shadow)"
        stroke="none"
        fill="#fff"
        rx={restProps.rx}
        ry={restProps.ry}
      />
      <RectUi
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        {...restProps}
      />
    </>
  )
}

Rect.defaultProps = {
  stroke: 'none',
  rx: '5',
  ry: '5',
}

export default Rect
