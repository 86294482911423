import React from 'react'
import useWidth from 'workflow/hooks/useWidth'
import useHover from 'workflow/hooks/useHover'
import Rect from '../../shared/Rect'
import Text, { padding as textPadding } from '../../shared/Text'
import Group from '../../shared/Group'
import Remove, { radius as removeDimension } from '../../shared/Remove'
import { svgSize } from 'workflow/components/Step/shared/Svg'

export const margin = 20

const gap = 5

const Trigger = ({ name, getIcon, x = 0, y, onInit, onRemove, onClick }) => {
  const getRectWidth = textWidth =>
    textWidth + textPadding * 2 + (getIcon ? svgSize + gap : 0)
  const [ref, textWidth] = useWidth(name, width => onInit(getRectWidth(width)))
  const width = getRectWidth(textWidth)
  const [hoverRef, isHovered] = useHover()
  return (
    <Group
      ref={hoverRef}
      onClick={onClick}
      visibility={textWidth > 0 ? 'visible' : 'hidden'}
    >
      <Rect x={x} y={y} width={width} fill="#53e293" hoverFill="#53e293cc" />
      {getIcon &&
        getIcon({
          x: x + textPadding,
          y: y + svgSize / 2,
          width: svgSize,
          height: svgSize,
          fill: '#fff',
          stroke: '#fff',
        })}
      <Text
        x={x + textPadding + (getIcon ? svgSize + gap : 0)}
        y={y + 20}
        ref={ref}
        name={name}
        fill="#fff"
      />
      {onRemove && (
        <Remove
          x={x + width - removeDimension}
          y={y - removeDimension}
          visibility={isHovered}
          onClick={onRemove}
        />
      )}
    </Group>
  )
}

export default Trigger
