import React, { useEffect, useRef } from 'react'
import Group from 'shared/components/Group'
import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'
import useSelectOptionsData from 'workflow/hooks/useSelectOptionsData'
import GoogleSheetRowMappings from './GoogleSheetRowMappings'
import FormErrors from 'shared/components/FormErrors'
import { useTranslation } from 'react-i18next'

export default function WorksheetColumns({
  spreadsheetId,
  sheetId,
  driveId,
  mapping,
  onChange,
  disabled,
}) {
  const { t } = useTranslation()

  const { options, allowFetch, isLoading } = useSelectOptionsData({
    dataType: workflowDataTypes.sheetColumns,
    parentEntityId: { driveId, spreadsheetId, sheetId },
    selector: column => ({ value: column.id, label: column.name }),
  })

  const containerRef = useRef()

  useEffect(() => {
    if (
      (!options || options.length === 0) &&
      driveId &&
      sheetId &&
      spreadsheetId
    ) {
      allowFetch()
    }
  }, [options, driveId, sheetId, spreadsheetId])

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        if (containerRef.current?.clientHeight > 350) {
          containerRef.current.style.overflowY = 'auto'
          containerRef.current.style.maxHeight = '350px'
        }
      })

      resizeObserver.observe(containerRef.current)

      return () => resizeObserver.disconnect()
    }
  }, [])

  const handleChange = ({ column, contents }) =>
    onChange(column.value, contents)

  const noColumns =
    driveId &&
    sheetId &&
    spreadsheetId &&
    !isLoading &&
    (!options || options?.length === 0)

  return (
    <div ref={containerRef} className="customScroll">
      {options?.map(opt => (
        <Group key={opt.value}>
          <GoogleSheetRowMappings
            column={opt}
            mapping={mapping?.find(map => map.column === opt.value)?.value}
            onChange={handleChange}
            disabled={disabled}
          />
        </Group>
      ))}
      {noColumns && (
        <FormErrors
          errors={[t('workflow.steps.action.form.validation.no_columns')]}
        />
      )}
    </div>
  )
}
