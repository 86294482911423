import React, { useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import HorizontalGroup from 'shared/components/HorizontalGroup'
import FormRow from 'shared/components/FormRow'
import { conditionFields } from '../conditionFields'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './calendar.css'

moment.locale('fr')

const format = 'D/M/YYYY'

const ConditionTypeLastActivity = ({ change, model }) => {
  const { t } = useTranslation()
  const [focused, setFocused] = useState(false)

  const handleChangeDate = date => {
    change({ ...model, [conditionFields.searchValue]: date.format(format) })
  }

  return (
    <HorizontalGroup
      styles={{
        gridTemplateColumns: '35% 45%',
      }}
    >
      <FormRow data-testid="workflow-step-condition-operators">
        {t('workflow.steps.condition.last_activity.label')}
      </FormRow>
      <div data-testid="workflow-step-condition-value">
        <SingleDatePicker
          date={
            model.searchValue ? moment(model.searchValue, format) : moment()
          }
          onDateChange={handleChangeDate}
          focused={focused}
          onFocusChange={({ focused }) => setFocused(focused)}
          numberOfMonths={1}
          hideKeyboardShortcutsPanel
          isOutsideRange={() => false}
        />
      </div>
    </HorizontalGroup>
  )
}

export default ConditionTypeLastActivity
