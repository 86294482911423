import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalContext } from 'modal/context'
import StepCreate from 'workflow/components/Step/StepCreate'
import {
  triggerTypesNames,
  triggerTypesObjects,
} from 'workflow/components/Step/types/Trigger/triggerTypes'
import Trigger, { margin as triggerMargin } from './Step/types/Trigger'
import Line, { height as lineHeight } from './Step/shared/Line'
import Path from 'workflow/components/Step/shared/Path'
import { height as rectHeight } from 'workflow/components/Step/shared/Rect'
import TriggerCreate from 'workflow/components/Step/types/Trigger/TriggerCreate'
import TriggerEdit from 'workflow/components/Step/types/Trigger/TriggerEdit'
import Circle, {
  height as circleHeight,
} from 'workflow/components/Step/shared/Circle'
import { getTriggerIcon } from 'workflow/components/Step/types/Trigger/utils/get-trigger-icon'
import { WorkflowAreaSizeContext } from './WorkflowAreaSize'
import { connect } from 'react-redux'
import * as actions from 'workflow/actions'
import { getTriggers } from 'workflow/reducer'

const height = 40

const circleYPadding = 10

const CREATE_TRIGGER_PLACEHOLDER = 'CREATE_TRIGGER_PLACEHOLDER'

const getMarginForIndex = (index, length) =>
  index !== length - 1 ? triggerMargin : 0

const getX = (dimensionsArray, targetIndex) => {
  return dimensionsArray
    .slice(0, targetIndex)
    .reduce((prev, width) => prev + width + triggerMargin, 0)
}

const getTriggersTotalWidth = dimensions =>
  Object.values(dimensions).reduce(
    (acc, width, index, arr) =>
      acc + width + getMarginForIndex(index, arr.length),
    0,
  )

const Triggers = ({ createAddStep, removeTrigger, triggers }) => {
  const [t] = useTranslation()
  const { showModal } = useContext(ModalContext)
  const [dimensions, setDimensions] = useState({})
  const { workflowCenter: centerPosition, triggersRef } = useContext(
    WorkflowAreaSizeContext,
  )

  useEffect(() => {
    setDimensions(prevDimensions =>
      Object.keys(prevDimensions).reduce((acc, dimensionKey) => {
        const triggerExist = triggers.find(
          trigger => trigger.id === Number(dimensionKey),
        )

        const isCreatePlaceholder =
          triggers.length === 0 && CREATE_TRIGGER_PLACEHOLDER === dimensionKey

        return {
          ...acc,
          ...(triggerExist || isCreatePlaceholder
            ? { [dimensionKey]: prevDimensions[dimensionKey] }
            : {}),
        }
      }, {}),
    )
  }, [triggers])

  const handleRemoveTrigger = triggerId => e => {
    e.stopPropagation()
    if (window.confirm('Do you really want to remove the trigger?')) {
      removeTrigger(triggerId)
    }
  }

  const retrieveWidth = id => width => {
    setDimensions(prevDimensions => ({ ...prevDimensions, [id]: width }))
  }
  const getTriggerWidth = triggerId => dimensions[triggerId] || 0
  const totalWidth = getTriggersTotalWidth(dimensions)
  const start = centerPosition - totalWidth / 2
  const isOverflowing = start < 0
  const startX = isOverflowing ? 0 : start
  const lineX2 = Math.round(
    (isOverflowing ? window.innerWidth : totalWidth) / 2,
  )
  const downLineX = getTriggerWidth(CREATE_TRIGGER_PLACEHOLDER)
    ? getTriggerWidth(CREATE_TRIGGER_PLACEHOLDER) / 2
    : 0

  const addActionsCirclePosition = {
    x: centerPosition,
    y: (height + lineHeight) * 2 - circleYPadding,
  }

  const addTriggersCirclePosition = {
    x: isOverflowing
      ? totalWidth + triggerMargin
      : window.innerWidth / 2 + totalWidth / 2 + triggerMargin,
    y: rectHeight - circleYPadding + circleHeight * 2,
  }

  return (
    <g ref={triggersRef} transform={`translate(${startX}, 50)`}>
      {triggers.map((trigger, index) => {
        const lineX1 =
          getX(Object.values(dimensions), index) +
          getTriggerWidth(trigger.id) / 2
        const lineY1 = height
        const lineY2 = height + lineHeight
        return [
          <Trigger
            key={`trigger-${trigger.id}`}
            x={getX(Object.values(dimensions), index)}
            y={0}
            getIcon={getTriggerIcon(trigger.type)}
            name={t(triggerTypesNames[trigger.type], {
              name: trigger[triggerTypesObjects[trigger.type]]?.name ?? '',
            })}
            onInit={retrieveWidth(trigger.id)}
            onClick={() => {
              showModal(TriggerEdit, {
                trigger,
              })
            }}
            onRemove={handleRemoveTrigger(trigger.id)}
          />,
          <Path
            key={`path-${trigger.id}`}
            x1={lineX1}
            x2={lineX2}
            y1={lineY1}
            y2={lineY2}
          />,
        ]
      })}
      {triggers.length > 0 ? (
        <Circle
          x={addTriggersCirclePosition.x}
          y={addTriggersCirclePosition.y}
          onClick={() => showModal(TriggerCreate)}
        />
      ) : (
        <>
          <Trigger
            x={getX(Object.values(dimensions), CREATE_TRIGGER_PLACEHOLDER)}
            y={0}
            name={t('workflow.steps.trigger.empty.placeholder')}
            onInit={retrieveWidth(CREATE_TRIGGER_PLACEHOLDER)}
            onClick={() => showModal(TriggerCreate)}
            key={`empty-trigger`}
          />
          <Line startX={downLineX} startY={height} />
        </>
      )}
      <Circle
        x={addActionsCirclePosition.x}
        y={addActionsCirclePosition.y}
        onClick={() =>
          showModal(StepCreate, {
            addStep: createAddStep(0),
          })
        }
      />
    </g>
  )
}

const mapStateToProps = state => ({
  triggers: getTriggers(state),
})

const mapDispatchToProps = { removeTrigger: actions.removeTrigger }

export default connect(mapStateToProps, mapDispatchToProps)(Triggers)
