import httpClient from 'shared/utils/httpClient'
import { useEffect, useState } from 'react'
import useSWR from 'swr'

export default function useControlledSWRImmutable(
  url,
  {
    controlledShouldFetch = false,
    revalidate = false,
    fetcher = httpClient.get,
  } = {},
) {
  const [shouldFetch, setShouldFetch] = useState(false)

  const allowFetch = () => setShouldFetch(true)

  const { data, isLoading } = useSWR(
    () => {
      if (shouldFetch && url) return url

      return null
    },
    fetcher,
    {
      revalidateIfStale: revalidate,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )

  useEffect(() => {
    if (controlledShouldFetch && !shouldFetch) setShouldFetch(true)
  }, [controlledShouldFetch])

  return { data, isLoading, allowFetch }
}
