import React, { useState } from 'react'
import Group from 'shared/components/Group'
import Funnels from 'workflow/components/Funnels'
import WebinarRegistrationSteps from 'workflow/components/WebinarRegistrationSteps'
import { useEffect } from 'react'

const TriggerTypeWebinarRegistration = ({ change, data }) => {
  const [funnel, setFunnel] = useState(data.funnel)

  const handleChange = ({ value, label }) => {
    change({ ...data, id: value, name: label, funnel })
  }

  useEffect(() => {
    data.funnel && setFunnel(data.funnel)
  }, [data.funnel?.id])

  return (
    <>
      <Group>
        <Funnels
          onChange={({ value, label }) => setFunnel({ id: value, name: label })}
          selected={funnel}
        />
      </Group>
      {funnel?.id && (
        <Group>
          <WebinarRegistrationSteps
            onChange={handleChange}
            selected={data}
            funnelId={funnel.id}
          />
        </Group>
      )}
    </>
  )
}

export default TriggerTypeWebinarRegistration
