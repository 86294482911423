import { conditionTypes } from 'workflow/components/Step/types/Condition/conditionTypes'

export const compareOperations = {
  contains: 'CONTAINS',
  begins: 'BEGINS',
  ends: 'ENDS',
  equals: 'EQUALS',
  greaterThan: 'GREATER_THAN',
  lessThan: 'LESS_THAN',
  inList: 'IN_LIST',
  notInList: 'NOT_IN_LIST',
  without: 'WITHOUT',
}

export const compareOperationsLabels = {
  [compareOperations.contains]:
    'workflow.steps.condition.operations.contains.label',
  [compareOperations.begins]:
    'workflow.steps.condition.operations.begins.label',
  [compareOperations.ends]: 'workflow.steps.condition.operations.ends.label',
  [compareOperations.equals]:
    'workflow.steps.condition.operations.equals.label',
  [compareOperations.greaterThan]:
    'workflow.steps.condition.operations.greater_than.label',
  [compareOperations.lessThan]:
    'workflow.steps.condition.operations.less_than.label',
  [compareOperations.inList]:
    'workflow.steps.condition.operations.in_list.label',
  [compareOperations.notInList]:
    'workflow.steps.condition.operations.not_in_list.label',
  [compareOperations.without]:
    'workflow.steps.condition.operations.without.label',
}

export const compareOperationsNames = {
  [compareOperations.contains]:
    'workflow.steps.condition.operations.contains.name',
  [compareOperations.begins]: 'workflow.steps.condition.operations.begins.name',
  [compareOperations.ends]: 'workflow.steps.condition.operations.ends.name',
  [compareOperations.equals]: 'workflow.steps.condition.operations.equals.name',
  [compareOperations.greaterThan]:
    'workflow.steps.condition.operations.greater_than.name',
  [compareOperations.lessThan]:
    'workflow.steps.condition.operations.names.less_than',
  [compareOperations.inList]:
    'workflow.steps.condition.operations.in_list.name',
  [compareOperations.notInList]:
    'workflow.steps.condition.operations.not_in_list.name',
  [compareOperations.without]:
    'workflow.steps.condition.operations.without.name',
}

const textOperations = [
  compareOperations.begins,
  compareOperations.contains,
  compareOperations.ends,
  compareOperations.equals,
]

export const getCompareOperationsByType = type => {
  switch (type) {
    case conditionTypes.createdAt:
      return [
        compareOperations.greaterThan,
        compareOperations.equals,
        compareOperations.lessThan,
      ]
    case conditionTypes.tag:
      return [
        compareOperations.inList,
        compareOperations.notInList,
        compareOperations.without,
      ]
    default:
      return textOperations
  }
}

export const getCompareOperationsOptionsByType = type =>
  getCompareOperationsByType(type).map(operator => ({
    value: operator,
    label: compareOperationsLabels[operator],
  }))
