export const delayUnitTypes = {
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  date: 'date',
}

export const delayType = {
  fixed: 'fixed',
  date: 'date',
}

export const delayUnitesLabels = {
  [delayUnitTypes.day]: 'workflow.steps.types.delay.unit.labels.day',
  [delayUnitTypes.hour]: 'workflow.steps.types.delay.unit.labels.hour',
  [delayUnitTypes.minute]: 'workflow.steps.types.delay.unit.labels.minute',
  [delayUnitTypes.date]: 'workflow.steps.types.delay.unit.labels.date',
}

export const delayUnitTypesNames = {
  [delayUnitTypes.day]: 'workflow.steps.types.delay.unit.names.dayCount',
  [delayUnitTypes.hour]: 'workflow.steps.types.delay.unit.names.hourCount',
  [delayUnitTypes.minute]: 'workflow.steps.types.delay.unit.names.minuteCount',
}

export const getStepDataName = stepData => {
  switch (stepData.unit) {
    case delayUnitTypes.day:
      return delayUnitTypesNames[delayUnitTypes.day]
    case delayUnitTypes.hour:
      return delayUnitTypesNames[delayUnitTypes.hour]
    case delayUnitTypes.minute:
      return delayUnitTypesNames[delayUnitTypes.minute]
    default:
      return ''
  }
}
