import styled from 'styled-components'

const StepTypeItemUi = styled.div`
  padding: 12px 18px 18px;
  border: 1px solid var(--default-border-color);
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    border-color: var(--blue-border-color);
  }
`

export default StepTypeItemUi
