import { useEffect, useRef, useState } from 'react'

function useWidth(invalidator, callback) {
  const [width, setWidth] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      const bBox = ref.current.getBBox()
      const width = Math.round(bBox.width)
      setWidth(width)
      if (callback) {
        callback(width)
      }
    }
  }, [invalidator])
  return [ref, width]
}

export default useWidth
