import React from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import useSelectOptionsData from 'workflow/hooks/useSelectOptionsData'
import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'

const Courses = ({ onChange, selected }) => {
  const { t } = useTranslation()

  const { options, isLoading, allowFetch } = useSelectOptionsData({
    dataType: workflowDataTypes.courses,
    defaultOptions: selected && [selected],
  })

  return (
    <Select
      id="workflow-courses-select"
      onChange={onChange}
      options={options}
      maxMenuHeight={200}
      value={options.find(option => option.value === selected?.id)}
      noOptionsMessage={() => t('workflow.courses.message.no_options')}
      placeholder={t('workflow.courses.placeholder')}
      onFocus={allowFetch}
      isLoading={isLoading}
    />
  )
}

export default Courses
