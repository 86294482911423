import React from 'react'
import CourseBundles from 'workflow/components/CourseBundles'
import Group from 'shared/components/Group'

const ActionTypeRevokeAccessToCourseBundle = ({ change, data }) => {
  const handleChangeCourseBundle = ({ value, label }) => {
    change({ ...data, id: value, name: label })
  }

  return (
    <>
      <Group>
        <CourseBundles onChange={handleChangeCourseBundle} selected={data} />
      </Group>
    </>
  )
}

export default ActionTypeRevokeAccessToCourseBundle
