import { createContext, Component } from 'react'
import React from 'react'

let ModalContext
const { Provider: Context, Consumer } = (ModalContext = createContext({
  component: null,
  props: {},
  showModal: () => {},
  hideModal: () => {},
}))

class ModalProvider extends Component {
  showModal = (component, props = {}) => {
    this.setState({
      component,
      props,
    })
  }

  hideModal = () =>
    this.setState({
      component: null,
      props: {},
    })

  state = {
    component: null,
    props: {},
    showModal: this.showModal,
    hideModal: this.hideModal,
  }

  render() {
    return <Context value={this.state}>{this.props.children}</Context>
  }
}

export { ModalProvider, Consumer as ModalConsumer, ModalContext }
