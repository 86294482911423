import axios from 'axios'
import {
  BadRequest,
  GatewayError,
  NetworkError,
  NotFound,
  ServiceUnavailable,
} from 'errors'

const request = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
})

request.interceptors.request.use(
  function(config) {
    return config
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

request.interceptors.response.use(
  response => response.data,
  error => {
    if (error.response) {
      if (error.response.status === 400) {
        throw new BadRequest(error.response)
      } else if (error.response.status === 404) {
        throw new NotFound()
      } else if (
        error.response.status === 403 ||
        error.response.status === 401
      ) {
        window.open(error.response.data.location)
      } else if (
        error.response.status === 502 ||
        error.response.status === 504
      ) {
        throw new GatewayError()
      } else if (error.response.status === 503) {
        throw new ServiceUnavailable()
      } else {
        return Promise.reject(error)
      }
    } else {
      throw new NetworkError()
    }
  },
)

export default request
