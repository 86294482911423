import { animatePulse } from 'shared/styles/animatePulse'
import styled from 'styled-components'
import React from 'react'

const WorkflowElementSkeletonWrapper = styled.g`
  visibility: visible;

  ${animatePulse}
`

const DEFAULT_WIDTH = 204
const DEFAULT_COLOR = '#53e293'

const WorkflowElementSkeleton = ({
  width = DEFAULT_WIDTH,
  color = DEFAULT_COLOR,
  x = 0,
  y = 0,
}) => {
  return (
    <WorkflowElementSkeletonWrapper>
      <g visibility="visible">
        <rect
          x={x}
          y={y}
          width={width}
          height="40"
          filter="url(#shadow)"
          stroke="none"
          fill="#fff"
          rx="5"
          ry="5"
        ></rect>
        <rect
          x={x}
          y={y}
          width={width}
          height="40"
          fill={color}
          stroke="none"
          rx="5"
          ry="5"
        ></rect>
      </g>
    </WorkflowElementSkeletonWrapper>
  )
}

export default WorkflowElementSkeleton
