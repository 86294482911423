import styled from 'styled-components'

const InputUi = styled.input`
  background-color: var(--field-bg-color);
  border: solid 1px var(--field-border-color);
  color: var(--field-color);
  font-size: var(--field-font-size);
  height: var(--field-height);
  max-width: 100%;
  width: 100%;
  border-radius: 4px;
  align-items: center;
  display: inline-flex;
  justify-content: flex-start;
  line-height: 1.5;
  padding: calc(0.375em - 1px) calc(0.625em - 1px);
  position: relative;
  vertical-align: top;
`

export default InputUi
