import moment from 'moment'
import { schema } from 'normalizr'
import { stepTypes } from 'workflow/components/Step/stepTypes'
import {
  conditionTypes,
  detectConditionType,
} from 'workflow/components/Step/types/Condition/conditionTypes'
import { triggerTypes } from './components/Step/types/Trigger/triggerTypes'
import { actionTypes } from './components/Step/types/Action/actionTypes'

const step = new schema.Entity(
  'steps',
  {},
  {
    processStrategy: value => {
      if (value.objectType === stepTypes.condition) {
        return {
          ...value,
          conditionGroups: prepareConditionData(value),
        }
      }

      if (
        value.objectType === stepTypes.action &&
        value.type === actionTypes.addRowToGoogleSheet
      ) {
        return {
          ...value,
          mapping: { id: value.googleSheetMappingId },
        }
      }

      return value
    },
  },
)

const trigger = new schema.Entity(
  'triggers',
  {},
  {
    processStrategy: value => {
      if (value.type === triggerTypes.emailLinkClicked) {
        return { ...value, mailing: { ...value.mailing, link: value.link?.id } }
      }

      return value
    },
  },
)

step.define({
  truePath: [step],
  falsePath: [step],
})

export const workflowSchema = {
  steps: [step],
  triggers: [trigger],
}

function prepareConditionModel(model) {
  const conditionType = detectConditionType(model.searchField)
  let searchValue
  switch (conditionType) {
    case conditionTypes.createdAt:
    case conditionTypes.lastActivity:
      searchValue = model.searchValue
        ? moment(model.searchValue).format('D/M/YYYY')
        : ''
      break
    case conditionTypes.tag:
      searchValue = model.searchValue ? Number(model.searchValue) : ''
      break
    default:
      searchValue = model.searchValue || ''
  }

  return {
    ...model,
    conditionType,
    searchValue,
  }
}

function prepareConditionData(data) {
  return data.conditionGroups.map(group => ({
    contactFilterConditionModels: group.contactFilterConditionModels.map(
      prepareConditionModel,
    ),
  }))
}
