import React from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import HorizontalGroup from 'shared/components/HorizontalGroup'
import Tags from 'workflow/components/Tags'
import { getCompareOperationsOptionsByType } from '../compareOperations'
import { compareOperations } from '../compareOperations'
import { conditionFields } from '../conditionFields'

const ConditionTypeTags = ({ change, model }) => {
  const { t } = useTranslation()

  const changeCompareOperation = option => {
    change({
      ...model,
      [conditionFields.compareOperation]: option.value,
      ...(option.value === compareOperations.without
        ? { [conditionFields.searchValue]: '' }
        : {}),
    })
  }

  const changeSearchValue = option => {
    change({
      ...model,
      [conditionFields.searchValue]: option.value,
    })
  }

  return (
    <HorizontalGroup
      styles={{
        gridTemplateColumns: '45% 45% ',
      }}
    >
      <div data-testid="workflow-step-condition-value">
        <Select
          placeholder={t(
            'workflow.steps.types.condition.tag.operations.placeholder',
          )}
          value={getCompareOperationsOptionsByType('tag').find(
            opt => opt.value === model.compareOperation,
          )}
          options={getCompareOperationsOptionsByType('tag')}
          formatOptionLabel={({ label }) => t(label)}
          onChange={changeCompareOperation}
        />
      </div>
      {model.compareOperation !== compareOperations.without && (
        <div data-testid="workflow-step-condition-operators">
          <Tags
            onChange={changeSearchValue}
            selected={Number(model.searchValue)}
          />
        </div>
      )}
    </HorizontalGroup>
  )
}

export default ConditionTypeTags
