import React from 'react'
// import CloseGroupUi from './ui/CloseGroupUi'
import CircleUi from './ui/CircleUi'

export const radius = 8

const Remove = ({ x, y, visibility, onClick, ...restProps }) => {
  return (
    <g
      transform={`translate(${x}, ${y})`}
      style={{ cursor: 'pointer' }}
      visibility={visibility ? 'visible' : 'hidden'}
      onClick={onClick}
      {...restProps}
    >
      <CircleUi r={radius} cx={radius} cy={radius} />
      <line x1="5" y1="5" x2="11" y2="11" stroke="#fff" strokeWidth="2px" />
      <line x1="11" y1="5" x2="5" y2="11" stroke="#fff" strokeWidth="2px" />
    </g>
  )
}

export default Remove
