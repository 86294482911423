import styled from 'styled-components/macro'
import { css } from 'styled-components'

const ModalBodyUi = styled.main`
  padding: 0 30px 24px;
  ${p =>
    p.overflowBody &&
    css`
      max-height: calc(100vh - 100px);
      overflow: auto;
    `}
`

export default ModalBodyUi
