import React from 'react'
import {
  ConditionRemoveLinkUi,
  ConditionRemoveIconUi,
} from './ui/ConditionRemoveUi'

const ConditionRemove = ({ onClick }) => {
  return (
    <ConditionRemoveLinkUi onClick={onClick}>
      <ConditionRemoveIconUi>&times;</ConditionRemoveIconUi>
    </ConditionRemoveLinkUi>
  )
}

export default ConditionRemove
