import React from 'react'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import useSelectOptionsData from 'workflow/hooks/useSelectOptionsData'
import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'

const Mailings = ({ onChange, selected }) => {
  const { t } = useTranslation()

  const { options, isLoading, allowFetch } = useSelectOptionsData({
    dataType: workflowDataTypes.mailings,
    selector: opt => ({ value: opt.id, label: opt.subject }),
    defaultOptions: selected && selected.id && [selected],
    fetchImmediately: !!selected,
  })

  const selectedOption =
    typeof selected === 'number' ? { id: selected } : selected

  return (
    <Select
      id="workflow-mailings-select"
      onChange={onChange}
      options={options}
      maxMenuHeight={200}
      value={options.find(option => option.value === selectedOption?.id)}
      noOptionsMessage={() => t('workflow.emails.message.no_options')}
      placeholder={t('workflow.emails.placeholder')}
      isLoading={isLoading}
      onFocus={allowFetch}
    />
  )
}

export default Mailings
