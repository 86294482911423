import useControlledSWRImmutable from 'shared/hooks/useControlledSWRImmutable'
import { USER_API_URL } from './consts'

export function useUser() {
  const { data, isLoading } = useControlledSWRImmutable(USER_API_URL, {
    controlledShouldFetch: true,
  })

  const locale = data?.dashboardLocale

  return { user: data, isLoading, locale }
}
