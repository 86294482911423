import { useEffect } from 'react'

export default function useClickOutside(refs, cb) {
  useEffect(() => {
    const handleClickOutside = evt => {
      const isOutside = !refs.some(ref => ref.current?.contains(evt.target))

      if (isOutside) cb && cb()
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [cb])
}
