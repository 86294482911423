import React, { useCallback, useEffect, useRef, useState } from 'react'
import Dropdown from '../Dropdown'
import useClickOutside from 'shared/hooks/useClickOutside'
import PseudoTextarea from '../PseudoTextarea'
import Handlebars from 'handlebars'
import { LabelUi } from './ui/labelUi'
import {
  autoPlacement,
  autoUpdate,
  offset,
  useFloating,
} from '@floating-ui/react-dom'

const defaultContent =
  '<span data-spacer="true" contenteditable="true">&nbsp;</span>'

const editorHandlebar = Handlebars.create()

editorHandlebar.registerHelper('rich', function(property) {
  const replacement = this.replacements?.find(repl => repl.value === property)
  return new Handlebars.SafeString(
    `${defaultContent}<span data-type="rich" data-replacement-type="${replacement?.value}" contenteditable="false">${replacement?.label}</span>${defaultContent}`,
  )
})

editorHandlebar.registerHelper('anchor', function(property) {
  const lookupElement = '<span'
  const propertyText = property.string
  const position = propertyText.lastIndexOf(lookupElement)
  const modifiedText = [
    propertyText.slice(0, position + lookupElement.length),
    ' data-anchor="true"',
    propertyText.slice(position + lookupElement.length),
  ].join('')
  return new Handlebars.SafeString(modifiedText)
})

function getFirstChildNode(element) {
  const child = element?.firstChild

  if (child?.nodeValue !== null) return child

  return getFirstChildNode(child)
}

function pasteNewOption(option, target, position) {
  target = target ?? ''
  return [
    target.slice(0, position),
    `{{anchor (rich '${option}')}}`,
    target.slice(position),
  ].join('')
}

export default function DropdownSelectInput({
  options,
  placeholder,
  onSave,
  contents,
  replacements,
  label,
  disabled,
  isLoading,
}) {
  const [localContents, setLocalContents] = useState(contents || defaultContent)
  const dropdownRef = useRef()
  const inputRef = useRef()
  const [inFocus, setInFocus] = useState(false)
  const [position, setPosition] = useState(0)

  const { refs, floatingStyles } = useFloating({
    placement: 'left',
    whileElementsMounted: autoUpdate,
    middleware: [autoPlacement(), offset(5)],
  })

  const isOpenDropdown = inFocus && !disabled

  const handleSave = useCallback(() => {
    if (!onSave || !isOpenDropdown) return

    const targetClone = inputRef.current?.cloneNode(true)
    targetClone &&
      targetClone
        .querySelectorAll('span[data-type="rich"]')
        .forEach(node =>
          node.replaceWith(
            `{{rich '${node.getAttribute('data-replacement-type')}'}}`,
          ),
        )
    onSave(targetClone?.innerText.trim() || '')
  }, [onSave, isOpenDropdown])

  const handleClickOutside = useCallback(() => {
    setInFocus(false)
    handleSave()
  }, [handleSave])

  useClickOutside([dropdownRef, inputRef], handleClickOutside)

  const handleSelect = option => {
    let anchor = position.anchor

    if (
      inputRef.current &&
      (!inputRef.current.contains(anchor) || inputRef.current === anchor) &&
      inputRef.current.innerText.trim().length === 0
    ) {
      inputRef.current.innerText = pasteNewOption(
        option.value,
        inputRef.current.innerText,
        position.position,
      )
    } else {
      anchor.nodeValue = pasteNewOption(
        option.value,
        anchor.nodeValue,
        position.position,
      )
    }

    inputRef.current
      .querySelectorAll('span[data-anchor="true"]')
      .forEach(el => el.removeAttribute('data-anchor'))

    setLocalContents(inputRef?.current.innerHTML ?? defaultContent)
  }

  const compileContents = () => {
    if (!replacements || Object.keys(replacements).length === 0)
      return defaultContent

    const contentsToCompile = localContents || defaultContent
    const template = editorHandlebar.compile(contentsToCompile)
    const result = template({ replacements })
    return result
  }

  const getCaretPosition = () => {
    const selection = document.getSelection()
    const pos = selection.getRangeAt(0).startOffset
    const anchor = selection.anchorNode
    return { anchor, position: pos }
  }

  const handlePositionChange = event => {
    let currentPosition
    if (event.target?.getAttribute('data-type') === 'rich') {
      const positionAnchorElement = event.target.nextSibling
      const positionAnchorNode = getFirstChildNode(positionAnchorElement)
      currentPosition = { anchor: positionAnchorNode, position: 0 }
    } else {
      currentPosition = getCaretPosition()
    }

    setPosition(currentPosition)
    setInFocus(true)
  }

  useEffect(() => {
    inputRef.current &&
      inputRef.current.addEventListener('click', handlePositionChange)
    inputRef.current &&
      inputRef.current.addEventListener('keydown', handlePositionChange)
    inputRef.current &&
      inputRef.current.addEventListener('keyup', handlePositionChange)

    return () => {
      inputRef.current &&
        inputRef.current.removeEventListener('click', handlePositionChange)
      inputRef.current &&
        inputRef.current.removeEventListener('keydown', handlePositionChange)
      inputRef.current &&
        inputRef.current.removeEventListener('keyup', handlePositionChange)
    }
  }, [])

  useEffect(() => {
    setLocalContents(contents || defaultContent)
  }, [contents])

  useEffect(() => {
    if (localContents && inputRef.current) {
      const anchorElement = inputRef.current.querySelector(
        'span[data-anchor="true"]',
      )

      if (!anchorElement) return

      const anchorNode = anchorElement.childNodes?.[0]

      if (!anchorNode) return

      setPosition({ anchor: anchorNode, position: 0 })
    }
  }, [localContents])

  return (
    <>
      <LabelUi>{label}</LabelUi>
      <PseudoTextarea
        ref={ref => {
          inputRef.current = ref
          refs.setReference(ref)
        }}
        onFocus={() => !disabled && setInFocus(true)}
        placeholder={placeholder}
        disabled={disabled}
        dangerouslySetInnerHTML={{ __html: compileContents() }}
      />
      <Dropdown
        ref={ref => {
          dropdownRef.current = ref
          refs.setFloating(ref)
        }}
        isOpen={isOpenDropdown}
        options={options}
        onSelect={handleSelect}
        style={floatingStyles}
        isLoading={isLoading}
      />
    </>
  )
}
