import React from 'react'

export default function AddRowToGoogleSheetIcon(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.7182 23C25.4179 23 22 26.3497 22 30.5643C22 34.7788 25.4179 38.1286 29.7182 38.1286C34.0186 38.1286 37.4365 34.7788 37.4365 30.5643C37.4365 26.3497 34.0186 23 29.7182 23ZM34.1286 31.6449H30.8209V34.8868H28.6156V31.6449H25.3078V29.4837H28.6156V26.2419H30.8209V29.4837H34.1286V31.6449Z"
        fill="#CBD5E1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.7531 36H8.70458C8.3155 36 8 35.6845 8 35.2954V4.70458C8 4.3155 8.3155 4 8.70458 4H20.8028V11.4742C20.8028 12.6496 21.7583 13.6058 22.9329 13.6058H31.0347V21.1233C30.5351 21.0422 30.0225 21 29.5 21C28.7826 21 28.0837 21.0795 27.4118 21.2302V17.7201H11.7986V30.2461H20.0033C20.0011 30.3304 20 30.4151 20 30.5C20 32.5497 20.6492 34.4479 21.7531 36ZM20.862 26.5404C20.5902 27.1322 20.3775 27.7568 20.2314 28.4065V26.5404H20.862ZM24.1066 22.6784C23.0978 23.3753 22.2304 24.2624 21.5562 25.2878H20.2314V22.6784H24.1066ZM18.9788 22.6784H13.0512V25.2878H18.9788V22.6784ZM18.9788 26.5404H13.0512V28.9935H18.9788V26.5404ZM26.159 18.9727H20.2314V21.4258H26.159V18.9727ZM18.9788 18.9727H13.0512V21.4258H18.9788V18.9727ZM22.3683 5.62774V11.4742C22.3683 11.7863 22.6215 12.0401 22.9329 12.0401H29.2268L22.3683 5.62774Z"
        fill="#CBD5E1"
      />
    </svg>
  )
}
