import { useEffect, useRef, useState } from 'react'

export default function useHeight(callback, invalidator) {
  const [height, setHeight] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      const bBox = ref.current.getBBox()
      const height = Math.ceil(bBox.height)

      setHeight(height)

      if (callback) {
        callback(height)
      }
    }
  }, [callback, invalidator])

  return [ref, height]
}
