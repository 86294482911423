import React from 'react'
import Text, { padding } from 'workflow/components/Step/shared/Text'
import useWidth from 'workflow/hooks/useWidth'

const height = 30

const PathLabel = ({ x, y, name }) => {
  const [ref, width] = useWidth(name)
  return (
    <>
      <rect
        x={x - padding / 2}
        y={y - height / 2}
        width={width + padding}
        height={height}
        fill="#fff"
        stroke="#fff"
        rx="15"
        ry="15"
      />
      <Text ref={ref} x={x} y={y} name={name} />
    </>
  )
}

export default PathLabel
