import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'modal/components'
import { stepTypes } from 'workflow/components/Step/stepTypes'
import useFormSubmit from 'workflow/hooks/useFormSubmit'
import ConditionForm, { defaultConditionData } from './ConditionForm'
import useConditionValidation from './useConditionValidation'

const ConditionCreate = ({
  handleClose,
  parentStepId,
  childType,
  addStep,
  step,
  ...restProps
}) => {
  const handleAddStep = async data =>
    await addStep(parentStepId, { ...step, data }, childType)

  const { validate } = useConditionValidation()

  const [isLoading, errors, handleSubmit] = useFormSubmit(
    handleAddStep,
    handleClose,
    validate,
  )
  const [t] = useTranslation()

  return (
    <Modal
      onRequestClose={handleClose}
      header={t('workflow.steps.condition.modal.create.header.title')}
      data-testid="modal-action-create"
      width={800}
      height={400}
    >
      <ConditionForm
        handleSubmit={handleSubmit}
        submitText={t('workflow.steps.condition.create.submit.label')}
        isLoading={isLoading}
        stepData={step}
        errors={errors}
        {...restProps}
      />
    </Modal>
  )
}

ConditionCreate.defaultProps = {
  step: {
    conditionGroups: [{ contactFilterConditionModels: [defaultConditionData] }],
    objectType: stepTypes.condition,
    falsePath: [],
    truePath: [],
  },
}

export default ConditionCreate
