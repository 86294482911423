import React from 'react'
import { useTranslation } from 'react-i18next'
import InputRadio from 'shared/components/InputRadio'
import Courses from 'workflow/components/Courses'
import Group from 'shared/components/Group'

export const courseAccessTypes = {
  fullAccess: 'full_access',
  drippingContent: 'dripping_content',
}

const ActionTypeEnrollInCourse = ({ change, data }) => {
  const { t } = useTranslation()

  const handleChangeCourse = ({ value, label }) => {
    change({ ...data, id: value, name: label })
  }

  const handleChangeAccessType = value => e => {
    const accessType = e.target.checked ? value : e.target.value
    change({ ...data, courseAccessType: accessType })
  }

  return (
    <>
      <Group>
        <Courses onChange={handleChangeCourse} selected={data} />
      </Group>
      <InputRadio
        onChange={handleChangeAccessType(courseAccessTypes.fullAccess)}
        checked={data?.courseAccessType === courseAccessTypes.fullAccess}
        label={t('workflow.steps.enrollments.access_type.full_access')}
      />
      <InputRadio
        onChange={handleChangeAccessType(courseAccessTypes.drippingContent)}
        checked={data?.courseAccessType === courseAccessTypes.drippingContent}
        label={t('workflow.steps.enrollments.access_type.drip_content')}
      />
    </>
  )
}

export default ActionTypeEnrollInCourse
