import { contactStatesNames } from 'workflow/components/Step/types/Condition/contactStateTypes'
import {
  compareOperations as compOps,
  compareOperationsNames as compNames,
} from './compareOperations'
import { conditionTypes, detectConditionType } from './conditionTypes'

const buildModelName = (model, getModelFieldName, t) => {
  const conditionType = detectConditionType(model.searchField)

  switch (conditionType) {
    case conditionTypes.contactFields:
      return `${t('workflow.steps.condition.names.has_a')} "${getModelFieldName(
        conditionTypes.contactFields,
        model,
      )}" ${t(compNames[model.compareOperation])} "${model.searchValue || ''}"`
    case conditionTypes.tag:
      return compOps.without === model.compareOperation
        ? `${t('workflow.steps.condition.names.has')} ${t(
            compNames[model.compareOperation],
          )}`
        : `${t('workflow.steps.condition.names.is')} ${t(
            compNames[model.compareOperation],
          )} "${getModelFieldName(conditionTypes.tag, model)}"`
    case conditionTypes.createdAt:
      return `${t('workflow.steps.condition.names.subscribed')} ${
        model.compareOperation !== compOps.equals
          ? t(compNames[model.compareOperation])
          : ''
      } ${model.searchValue ? model.searchValue : ''}`
    case conditionTypes.lastActivity:
      return `${t('workflow.steps.condition.names.last_activity')} ${
        model.compareOperation !== compOps.equals
          ? t(compNames[model.compareOperation])
          : ''
      } ${model.searchValue ? model.searchValue : ''}`
    case conditionTypes.contactState:
      return model.searchValue
        ? `${t('workflow.steps.condition.names.is')} ${t(
            contactStatesNames[model.searchValue],
          )}`
        : ''
    case conditionTypes.mailing:
      return `${t(
        'workflow.steps.condition.names.clicked_by_email_link',
      )} "${getModelFieldName(conditionTypes.mailing, model)}"`
    case conditionTypes.emailOpened:
      return `${t(
        'workflow.steps.condition.names.opened_email',
      )} "${getModelFieldName(conditionTypes.mailing, model)}"`
    default:
      return ''
  }
}

export default function(step, getModelFieldName, t) {
  return `${t(
    'workflow.steps.condition.names.initial',
  )} ${step.conditionGroups.reduce(
    (acc, group, groupIndex, groupArr) =>
      `${acc}${group.contactFilterConditionModels.reduce(
        (modelAcc, model, index, arr) =>
          `${modelAcc}${buildModelName(model, getModelFieldName, t)}${
            index + 1 < arr.length && arr[index + 1].searchValue
              ? ` ${t('workflow.steps.condition.names.and')} `
              : ''
          }`,
        '',
      )}${
        groupIndex + 1 < groupArr.length
          ? `${t('workflow.steps.condition.names.or')} `
          : ''
      }`,
    '',
  )} ?`
}
