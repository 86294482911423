import Button from 'shared/components/Button'
import styled from 'styled-components'

const ExitButtonUi = styled(Button)`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
`

export default ExitButtonUi
