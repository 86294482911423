import styled from 'styled-components/macro'
import { css } from 'styled-components'

const ConditionUi = styled.div`
  padding: 18px 0;
  position: relative;
  display: flex;
  ${p =>
    p.showAnd &&
    css`
      border-bottom: 1px solid #cccccc;
      &:after {
        font-weight: bold;
        font-size: 12px;
        content: attr(data-aftertext);
        display: block;
        position: absolute;
        left: 10px;
        bottom: 0;
        margin: 0 0 -12px;
        padding: 0 6px;
        text-align: center;
        letter-spacing: 1px;
        color: #262626;
        background-color: #f2f2f2;
      }
    `}
  
}
  
`

export default ConditionUi
