import * as moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/pt'
import 'moment/locale/ja'
import 'moment/locale/zh-cn'
import 'moment/locale/ar-dz'
import 'moment/locale/tr'
import 'moment/locale/sv'
import 'moment/locale/ro'
import 'moment/locale/cs'
import 'moment/locale/hu'
import 'moment/locale/sk'
import 'moment/locale/da'
import 'moment/locale/id'
import 'moment/locale/pl'
import 'moment/locale/el'
import 'moment/locale/sr'
import 'moment/locale/nb'
import 'moment/locale/th'
import 'moment/locale/sl'
import 'moment/locale/uk'
import 'moment/locale/sq'
import 'moment/locale/hi'
import React, { memo, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Steps from 'workflow/components/Steps'
import TriggerList from 'workflow/components/TriggerList'
import { TopLayerProvider } from './components/TopLayer'
import { WorkflowAreaSizeContext } from './components/WorkflowAreaSize'
import WorkflowSkeleton from './components/Skeleton'
import Suspense from 'shared/components/Suspense/Suspense'
import { useUser } from './hooks/useUser'
import useWorkflow from './hooks/useWorkflow'
import { getIsLoadingWorkflow, getRootIds } from './reducer'
import { useActions } from './hooks/useActions'
import * as actions from './actions'
import { getWorkflowId } from './utils/getWorkflowId'
import * as api from 'workflow/api'
import { actionTypes } from './components/Step/types/Action/actionTypes'

const startY = 125

const Workflow = () => {
  const { i18n, t } = useTranslation()
  const { locale, isLoading: isLoadingUser } = useUser()
  const ids = useWorkflow(getRootIds)
  const isLoadingWorkflow = useWorkflow(getIsLoadingWorkflow)
  const { id } = getWorkflowId()

  const [
    addRootId,
    addNewStep,
    removeStep,
    removeRootId,
    loadWorkflow,
  ] = useActions([
    actions.addRootId,
    actions.addStep,
    actions.removeStep,
    actions.removeRootId,
    actions.loadWorkflow,
  ])

  const addStep = async (parentStepId, step, childType = null) => {
    const result = await api.addStep(step, parentStepId, childType)
    addNewStep({ ...step, ...result })
    return result.id
  }

  const {
    width,
    height,
    workflowRef,
    workflowCenter,
    addStepHeight,
    rootStepsRef,
    incrementElements,
    reduceElements,
  } = useContext(WorkflowAreaSizeContext)

  useEffect(() => {
    i18n.changeLanguage(locale)
    moment.locale(locale)
  }, [locale])

  const createAddStep = index => async (parentStepId, step, childType) => {
    if (step.data.type === actionTypes.addRowToGoogleSheet) {
      const mapping = await api.createMapping(step.data.mapping)
      step.data.mapping.id = mapping?.id
    }

    const stepId = await addStep(parentStepId, step, childType)
    addRootId(stepId, index)
    incrementElements()
    return stepId
  }

  const handleRemoveStep = async stepId => {
    try {
      await removeStep(stepId)
      removeRootId(stepId)
      reduceElements()
    } catch {
      alert(t('workflow.something_went_wrong.error'))
    }
  }

  useEffect(() => {
    if (id) {
      loadWorkflow(id)
    }
  }, [id])

  const isLoading = isLoadingUser || isLoadingWorkflow

  return (
    <Suspense isLoading={isLoading} fallback={<WorkflowSkeleton />}>
      <svg
        width={width}
        height={height}
        stroke="red"
        ref={workflowRef}
        data-testid="workflow"
      >
        <TopLayerProvider>
          <filter id="shadow" width="150%" height="150%">
            <feDropShadow
              dx="0"
              dy="2"
              stdDeviation="4"
              floodColor="rgba(0, 0, 0, .15)"
            />
          </filter>
          <TriggerList createAddStep={createAddStep} />
          <g ref={rootStepsRef}>
            <Steps
              ids={ids}
              centerX={workflowCenter}
              startY={startY}
              createAddStep={createAddStep}
              removeStep={handleRemoveStep}
              heightSubscriber={addStepHeight}
            />
          </g>
        </TopLayerProvider>
      </svg>
    </Suspense>
  )
}

export default memo(Workflow)
