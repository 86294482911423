import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'modal/components'
import Group from 'shared/components/Group'
import Button from 'shared/components/Button'
import CreateEmail from 'workflow/components/CreateEmail'
import EmailsWithRecipient from 'workflow/components/EmailsWithRecipient'
import { getWorkflowId } from 'workflow/utils/getWorkflowId'

const ActionTypeSendEmail = ({
  change,
  save,
  data = {},
  isLoading,
  redirectPlaceholder,
  createEmailUrlPlaceholder,
}) => {
  const { t } = useTranslation()
  const [isCreateMode, setCreateMode] = useState(false)
  const { id: workflowId } = getWorkflowId()

  const handleChange = ({ value, label }) => {
    change({
      ...data,
      id: value,
      subject: label,
    })
  }

  const handleCreateClick = e => {
    setCreateMode(true)

    e.preventDefault()
  }

  const handleOnCreate = emailId => {
    change({ ...data, id: emailId })
    // bad, but no idea how to do better
    return save({ ...data, id: emailId }, false)
  }

  const saveAndEdit = () => {
    save(null, false).then(() => {
      if (data.id) {
        window.location.href = redirectPlaceholder
          .replace('%workflowId%', workflowId)
          .replace('%emailId%', data.id)
      }
    })
  }

  return (
    <>
      {isCreateMode ? (
        <Modal
          isOpen={true}
          onRequestClose={() => setCreateMode(false)}
          header={t(
            'workflow.email_with_specific_recipient.placeholder.subject',
          )}
        >
          <CreateEmail
            onCreate={handleOnCreate}
            workflowId={workflowId}
            redirectPlaceholder={redirectPlaceholder}
            createEmailUrlPlaceholder={createEmailUrlPlaceholder}
          />
        </Modal>
      ) : (
        <Group>
          <EmailsWithRecipient onChange={handleChange} selected={data} />
          <p>
            <sup>
              {t(
                'workflow.steps.types.action.types.send_email.create_email.description',
              )}{' '}
              <a onClick={handleCreateClick} href="#">
                {t(
                  'workflow.steps.types.action.types.send_email.create_email.link',
                )}
              </a>
            </sup>
          </p>
        </Group>
      )}
      <Group>
        <Button onClick={() => save()} disabled={isLoading || !data.id} success>
          {t('workflow.steps.types.action.types.send_email.choose')}
        </Button>
        <Button onClick={saveAndEdit} disabled={isLoading || !data.id} success>
          {t('workflow.steps.types.action.types.send_email.choose_and_edit')}
        </Button>
      </Group>
    </>
  )
}

ActionTypeSendEmail.defaultProps = {
  redirectPlaceholder: `${process.env.REACT_APP_API_HOST}/dashboard/workflows/%workflowId%/emails-with-recipient/%emailId%`,
  createEmailUrlPlaceholder: `${process.env.REACT_APP_API_HOST}/dashboard/workflow-builder/api/workflow/%workflowId%/email-with-specific-recipient/create`,
}

export default ActionTypeSendEmail
