import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import workflow from './workflow/reducer'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  combineReducers({ workflow }),
  {},
  composeEnhancers(applyMiddleware(thunkMiddleware)),
)

export default store
