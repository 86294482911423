import React from 'react'
import {
  ModalCloseLinkUi,
  ModalCloseIconUi,
} from 'modal/components/ui/ModalCloseUi'

const ModalClose = ({ onClick }) => {
  return (
    <ModalCloseLinkUi onClick={onClick}>
      <ModalCloseIconUi>&times;</ModalCloseIconUi>
    </ModalCloseLinkUi>
  )
}

export default ModalClose
