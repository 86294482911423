import React from 'react'

const Path = ({ x1, x2, y1, y2 }) => {
  // const shift = Math.round(Math.abs(x2 - x1) / 10)
  const shift = 0
  return (
    <path
      d={`M${x1},${y1} C${x1},${y2 - shift} ${x2 - shift},${y1} ${x2},${y2}`}
      fill="transparent"
      stroke="#c0c0c0"
      strokeDasharray="8 4"
    />
  )
}

export default Path
