export default function Suspense({ isLoading, fallback, children }) {
  if (isLoading) return fallback

  const renderChildren = () => {
    if (typeof children === 'function') return children()

    return children
  }

  return renderChildren()
}
