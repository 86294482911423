import React from 'react'
import ConditionTypeContactFields from 'workflow/components/Step/types/Condition/ConditionType/ConditionTypeContactFields'
import ConditionTypeContactState from 'workflow/components/Step/types/Condition/ConditionType/ConditionTypeContactState'
import ConditionTypeCreatedAt from 'workflow/components/Step/types/Condition/ConditionType/ConditionTypeCreatedAt'
import ConditionTypeLastActivity from 'workflow/components/Step/types/Condition/ConditionType/ConditionTypeLastActivity'
import ConditionTypeTags from 'workflow/components/Step/types/Condition/ConditionType/ConditionTypeTags'
import ConditionTypeMailings from 'workflow/components/Step/types/Condition/ConditionType/ConditionTypeMailings'
import { conditionTypes } from 'workflow/components/Step/types/Condition/conditionTypes'
import ConditionTypeEmailOpened from 'workflow/components/Step/types/Condition/ConditionType/ConditionTypeEmailOpened'

const ConditionType = ({ type, change, model }) => {
  let Component
  switch (type) {
    case conditionTypes.contactFields:
      Component = ConditionTypeContactFields
      break
    case conditionTypes.tag:
      Component = ConditionTypeTags
      break
    case conditionTypes.lastActivity:
      Component = ConditionTypeLastActivity
      break
    case conditionTypes.createdAt:
      Component = ConditionTypeCreatedAt
      break
    case conditionTypes.contactState:
      Component = ConditionTypeContactState
      break
    case conditionTypes.mailing:
      Component = ConditionTypeMailings
      break
    case conditionTypes.emailOpened:
      Component = ConditionTypeEmailOpened
      break
    default:
      throw new Error(`Unknown condition type ${type}`)
  }

  return <Component change={change} model={model} />
}

export default ConditionType
