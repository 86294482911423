import React from 'react'
import Group from 'shared/components/Group'
import CourseBundles from 'workflow/components/CourseBundles'

const TriggerTypeCourseBundle = ({ change, data }) => {
  const handleChange = ({ value, label }) => {
    change({ ...data, id: value, name: label })
  }

  return (
    <Group>
      <CourseBundles onChange={handleChange} selected={data} />
    </Group>
  )
}

export default TriggerTypeCourseBundle
