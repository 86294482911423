import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import useSelectOptionsData from 'workflow/hooks/useSelectOptionsData'
import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'
import React, { useEffect } from 'react'

export default function Worksheets({
  onChange,
  selected,
  spreadsheetId,
  driveId,
  disabled,
}) {
  const { t } = useTranslation()

  const { options, isLoading, allowFetch } = useSelectOptionsData({
    dataType: workflowDataTypes.worksheet,
    defaultOptions: selected && [selected],
    parentEntityId: { spreadsheetId, driveId },
  })

  useEffect(() => {
    if (selected && !selected.name && (!options || options.length === 0))
      allowFetch()
  }, [selected, options])

  return (
    <Select
      id="workflow-worksheet-select"
      onChange={onChange}
      options={options}
      maxMenuHeight={200}
      value={options.find(option => option.value === selected?.id)}
      noOptionsMessage={() => t('workflow.selectors.no_options')}
      placeholder={t('workflow.worksheets.placeholder')}
      onFocus={allowFetch}
      isLoading={isLoading}
      isDisabled={disabled}
    />
  )
}
