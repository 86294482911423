import styled from 'styled-components/macro'

const HorizontalGroupUi = styled.div`
  display: grid;
  grid-gap: 20px;
  margin-left: 20px;
  width: 100%;
  ${({ styles = {} }) => styles};
`

export default HorizontalGroupUi
