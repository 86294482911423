import React, { useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import moment from 'moment'
import HorizontalGroup from 'shared/components/HorizontalGroup'
import { getCompareOperationsOptionsByType } from '../compareOperations'
import { conditionFields } from '../conditionFields'
import { conditionTypes } from '../conditionTypes'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './calendar.css'

const format = 'D/M/YYYY'

const ConditionTypeCreatedAt = ({ change, model }) => {
  const { t } = useTranslation()
  const [focused, setFocused] = useState(false)

  const handleChange = type => option => {
    change({ ...model, [type]: option.value })
  }

  const handleChangeDate = date => {
    if (!date) {
      return
    }
    change({ ...model, [conditionFields.searchValue]: date.format(format) })
  }

  return (
    <HorizontalGroup
      styles={{
        gridTemplateColumns: '45% 45% ',
      }}
    >
      <div data-testid="workflow-step-condition-operators">
        <Select
          placeholder={t(
            'workflow.steps.types.condition.tag.operations.placeholder',
          )}
          value={getCompareOperationsOptionsByType(
            conditionTypes.createdAt,
          ).find(opt => opt.value === model.compareOperation)}
          options={getCompareOperationsOptionsByType(conditionTypes.createdAt)}
          formatOptionLabel={({ label }) => t(label)}
          onChange={handleChange(conditionFields.compareOperation)}
        />
      </div>
      <div data-testid="workflow-step-condition-value">
        <SingleDatePicker
          date={
            model.searchValue ? moment(model.searchValue, format) : moment()
          }
          onDateChange={handleChangeDate}
          focused={focused}
          onFocusChange={({ focused }) => setFocused(focused)}
          numberOfMonths={1}
          hideKeyboardShortcutsPanel
          isOutsideRange={() => false}
        />
      </div>
    </HorizontalGroup>
  )
}

export default ConditionTypeCreatedAt
