import CourseIcon from 'shared/icons/course-icon'
import AddTagIcon from 'shared/icons/add-tag-icon'
import RemoveTagIcon from 'shared/icons/remove-tag-icon'
import React from 'react'
import { actionTypes } from 'workflow/components/Step/types/Action/actionTypes'
import SendEmailIcon from 'shared/icons/send-email-icon'
import CampaignSubscribeIcon from 'shared/icons/campaign-subscribe-icon'
import CampaignUnsubscribeIcon from 'shared/icons/campaign-unsubscribe-icon'
import CourseBundleUnenrollIcon from 'shared/icons/course-bundle-unenroll-icon'
import CommunityUnenrollIcon from 'shared/icons/community-unenroll-icon'
import CourseUnenrollIcon from 'shared/icons/course-unenroll-icon'
import CourseBundleEnrollIcon from 'shared/icons/course-bundle-enroll-icon'
import AddRowToGoogleSheetIcon from 'shared/icons/add-row-to-google-sheet-icon'

export const getActionIcon = action => props => {
  switch (action) {
    case actionTypes.addTag:
      return <AddTagIcon {...props} />
    case actionTypes.enrollInCourse:
      return <CourseIcon {...props} />
    case actionTypes.removeTag:
      return <RemoveTagIcon {...props} />
    case actionTypes.sendEmail:
    case actionTypes.sendWebhook:
    case actionTypes.sendEmailToSpecificAddress:
      return <SendEmailIcon {...props} />
    case actionTypes.revokeAccessToCourseBundle:
      return <CourseBundleUnenrollIcon {...props} />
    case actionTypes.enrollInCourseBundle:
      return <CourseBundleEnrollIcon {...props} />
    case actionTypes.subscribeToCampaign:
      return <CampaignSubscribeIcon {...props} />
    case actionTypes.unsubscribeFromCampaign:
      return <CampaignUnsubscribeIcon {...props} />
    case actionTypes.revokeAccessToCourse:
      return <CourseUnenrollIcon {...props} />
    case actionTypes.revokeAccessToCommunity:
      return <CommunityUnenrollIcon {...props} />
    case actionTypes.addRowToGoogleSheet:
      return <AddRowToGoogleSheetIcon {...props} />
  }
}
