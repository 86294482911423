import React, { createContext, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

const TopLayerContext = createContext()

const TOP_LAYER_ID = 'top-layer-portal'

const TopLayerProvider = ({ children }) => {
  const queue = useRef([])
  const [hasQueue, setHasQueue] = useState(false)

  const renderAtTopLayer = component => {
    if (!component) return null

    const parent = document.getElementById(TOP_LAYER_ID)

    if (!parent) {
      queue.current.push(component)
      setHasQueue(true)
      return null
    }

    return createPortal(component, parent)
  }

  useEffect(() => {
    if (hasQueue) {
      const interval = setInterval(() => {
        if (queue.current.length > 0)
          return renderAtTopLayer(queue.current.shift())

        clearInterval(interval)
      }, 200)

      return () => clearInterval(interval)
    }
  }, [hasQueue])

  return (
    <TopLayerContext.Provider value={{ renderAtTopLayer }}>
      {children}
      <g id={TOP_LAYER_ID}></g>
    </TopLayerContext.Provider>
  )
}

export { TopLayerContext, TopLayerProvider }
