import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './translations/en'
import fr from './translations/fr'
import es from './translations/es'
import it from './translations/it'
import pt from './translations/pt'
import de from './translations/de'
import ru from './translations/ru'
import nl from './translations/nl'
import ja from './translations/ja'
import ar from './translations/ar'
import zh from './translations/zh'
import tr from './translations/tr'
import sv from './translations/sv'
import ro from './translations/ro'
import cs from './translations/cs'
import hu from './translations/hu'
import sk from './translations/sk'
import da from './translations/da'
import id from './translations/id'
import pl from './translations/pl'
import el from './translations/el'
import sr from './translations/sr'
import no from './translations/no'
import th from './translations/th'
import sl from './translations/sl'
import uk from './translations/uk'
import sq from './translations/sq'
import hi from './translations/hi'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
      es: {
        translation: es,
      },
      it: {
        translation: it,
      },
      pt: {
        translation: pt,
      },
      de: {
        translation: de,
      },
      nl: {
        translation: nl,
      },
      ru: {
        translation: ru,
      },
      ja: {
        translation: ja,
      },
      jp: {
        translation: ja,
      },
      ar: {
        translation: ar,
      },
      zh: {
        translation: zh,
      },
      tr: {
        translation: tr,
      },
      sv: {
        translation: sv,
      },
      ro: {
        translation: ro,
      },
      cs: {
        translation: cs,
      },
      hu: {
        translation: hu,
      },
      sk: {
        translation: sk,
      },
      da: {
        translation: da,
      },
      dk: {
        translation: da,
      },
      id: {
        translation: id,
      },
      pl: {
        translation: pl,
      },
      el: {
        translation: el,
      },
      sr: {
        translation: sr,
      },
      no: {
        translation: no,
      },
      th: {
        translation: th,
      },
      sl: {
        translation: sl,
      },
      uk: {
        translation: uk,
      },
      ua: {
        translation: uk,
      },
      sq: {
        translation: sq,
      },
      hi: {
        translation: hi,
      },
    },
    // lng: 'en',
    // fallbackLng: 'fr',
    parseMissingKeyHandler: () => '', // do not render text until we have received locale
    debug: process.env.NODE_ENV,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
