import React from 'react'
import { Layout, Header, Sidebar, Main } from 'dashboard'
import ModalRoot from 'modal/ModalRoot'
import { ModalProvider } from 'modal/context'
import WorkflowAreaSizeProvider from 'workflow/components/WorkflowAreaSize'
import 'index.css'
import { getWorkflowId } from 'workflow/utils/getWorkflowId'

function App() {
  const { matched } = getWorkflowId()

  if (!matched) {
    window.location.assign(
      `${process.env.REACT_APP_API_HOST}/dashboard/workflows`,
    )
    return null
  }

  return (
    <ModalProvider>
      <Layout>
        <ModalRoot />
        <Header />
        <WorkflowAreaSizeProvider>
          <Main />
        </WorkflowAreaSizeProvider>
        <Sidebar />
      </Layout>
    </ModalProvider>
  )
}

export default App
