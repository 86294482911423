import styled from 'styled-components/macro'

const HeaderUi = styled.header`
  grid-area: header;
  background: #142d63;
  color: rgb(238, 238, 238);
  box-shadow: 0 2px 2px 0 rgba(46, 81, 98, 0.18);
  display: flex;
  align-items: center;
  padding: 0 5px;
`

export default HeaderUi
