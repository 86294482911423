import React from 'react'
import * as stepFactory from './factory'

const Step = ({ removeStep, ...restProps }) => {
  const StepComponent = stepFactory.createStep(restProps.step.objectType)
  return <StepComponent onRemove={removeStep} {...restProps} />
}

Step.defaultProps = {
  level: 0,
}

export default Step
